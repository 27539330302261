import React from "react";
import { Container, Row, Col, Card, CardBody, Button, Input } from 'reactstrap';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileExcel, faEye, faStar } from "@fortawesome/free-solid-svg-icons";
import avatarImage from '../../images/user/01.jpg';
import { useNavigate, Navigate } from 'react-router-dom';
import DOMPurify from 'dompurify';

class Index extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            boards: [],
            navigateTo: null,
            searchQuery: "",
        };

        this.fetchTickets = this.fetchTickets.bind(this);
        this.onDragStart = this.onDragStart.bind(this);
        this.onDrop = this.onDrop.bind(this);
        this.handleSearchChange = this.handleSearchChange.bind(this);
        this.filteredItems = this.filteredItems.bind(this);
    }

    componentDidMount() {
        this.fetchTickets();
    }

    async fetchTickets() {
        const { currentUser } = this.props;
        const currentUserEmail = currentUser ? currentUser.email : "";

        try {
            const [ticketResponse, projectTaskResponse] = await Promise.all([
                fetch('/service_requests/fetch_assigned_tickets').then(res => res.json()),
                fetch('/projects/fetch_project_tasks').then(res => res.json())
            ]);

            const toDoTickets = ticketResponse.filter(ticket =>
                ticket.request_assignments.some(assignment =>
                    assignment.employee &&
                    assignment.employee.official_email === currentUserEmail &&
                    assignment.status === "Not Started" 
                )
            );

            const onGoingTickets = ticketResponse.filter(ticket =>
                ticket.request_assignments.some(assignment =>
                    assignment.employee &&
                    assignment.employee.official_email === currentUserEmail &&
                    assignment.status !== "Not Started" &&
                    assignment.status !== "Cancelled" &&
                    assignment.status !== "Completed"
                )
            );

            const doneTickets = ticketResponse.filter(ticket =>
                ticket.request_assignments.some(assignment =>
                    assignment.employee &&
                    assignment.employee.official_email === currentUserEmail &&
                    assignment.status === "Completed"
                )
            );

            const toDoProjectTasks = projectTaskResponse.filter(task =>
                task.assignees.some(assignee =>
                    assignee &&
                    assignee.official_email === currentUserEmail &&
                    task.status === "Not Started"
                )
            );

            const onGoingProjectTasks = projectTaskResponse.filter(task =>
                task.assignees.some(assignee =>
                    assignee &&
                    assignee.official_email === currentUserEmail &&
                    task.status !== "Not Started" &&
                    task.status !== "Cancelled" &&
                    task.status !== "Completed"
                )
            );

            const doneProjectTasks = projectTaskResponse.filter(task =>
                task.assignees.some(assignee =>
                    assignee &&
                    assignee.official_email === currentUserEmail &&
                    task.status === "Completed"
                )
            );

            const allToDoItems = [...toDoTickets, ...toDoProjectTasks];
            const allOnGoingItems = [...onGoingTickets, ...onGoingProjectTasks];
            const allDoneItems = [...doneTickets, ...doneProjectTasks];

            this.setState({
                boards: [
                    { id: 1, title: 'To Do', color: 'bg-primary', list: allToDoItems, count: allToDoItems.length },
                    { id: 2, title: 'On Going', color: 'bg-warning', list: allOnGoingItems, count: allOnGoingItems.length },
                    { id: 3, title: 'Done', color: 'bg-success', list: allDoneItems, count: allDoneItems.length }
                ]
            });

        } catch (error) {
            console.error('Error fetching tickets or project tasks:', error);
        }
    }

    handleSearchChange(e) {
        this.setState({ searchQuery: e.target.value });
    }

    filteredItems(items) {
        const { searchQuery } = this.state;
        return items.filter(item =>
            (item.ticket_number || item.task_name).toLowerCase().includes(searchQuery.toLowerCase()) ||
            (item.request_title || item.description).toLowerCase().includes(searchQuery.toLowerCase())
        );
    }

    onDragStart(e, fromList) {
        const dragInfo = {
            taskId: e.currentTarget.id,
            fromList
        };
        localStorage.setItem('dragInfo', JSON.stringify(dragInfo));
    }

    onDrop(e, listNum) {
        // Implement drop logic if necessary
    }

    render() {
        const { boards, navigateTo, searchQuery } = this.state;
        const { avatarUrl } = this.props;

        if (navigateTo) {
            return <Navigate to={navigateTo} />;
        }

        const iconStyle = { color: 'rgba(102, 51, 153, .28)', fontSize: '35px' };

        const renderStars = (count) => {
            return [...Array(5).keys()].map(index => (
                <FontAwesomeIcon
                    key={index}
                    icon={faStar}
                    style={{ color: index < count ? '#FFD700' : '#e4e5e9', fontSize: '24px' }}
                />
            ));
        };

        return (
            <div>
                <style>
                    {`
        .scrollable-list::-webkit-scrollbar-thumb {
            background-color: hsl(253deg, 61%, 55%);
        }
        .scrollable-list::-webkit-scrollbar-track {
            background-color: #f1f1f1;
        }
        .ongoing-list::-webkit-scrollbar-thumb {
            background-color: yellow;
        }
        .ongoing-list::-webkit-scrollbar-track {
            background-color: #f1f1f1;
        }
    `}
                </style>
                <Container fluid={true} className="my-4">
                    <Row className="align-items-center mb-1">
                        <Col md={3}>
                            <Input type="text" placeholder="Search..." className="mb-2" value={searchQuery} onChange={this.handleSearchChange} />
                        </Col>
                    </Row>
                    <Row>
                        <Col md={3}>
                            <Card className="text-center">
                                <CardBody>
                                    <img src={avatarUrl} alt="My Team Star" className="img-fluid rounded-circle mb-3"
                                         style={{ width: '60px', height: '60px' }} />
                                    <h5>My Team Star</h5>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col md={3}>
                            <Card className="text-center">
                                <CardBody>
                                    <i className="ri-user-follow-line mb-2" style={iconStyle}></i>
                                    <h6>My Reputation</h6>
                                    {renderStars(3)}
                                </CardBody>
                            </Card>
                        </Col>
                        <Col md={3}>
                            <Card className="text-center">
                                <CardBody>
                                    <i className="ri-group-line mb-2" style={iconStyle}></i>
                                    <h6>Team Reputation</h6>
                                    {renderStars(4)}
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>

                <Container fluid={true}>
                    <Row>
                        <Col md={12}>
                            <Row>
                                <Col md={12} className="track">
                                    {
                                        boards.map((item, index1) => (
                                            <Card className="bg-transparent shadow-none mr-3 w-25 iq-card" key={index1}>
                                                <div
                                                    className={"iq-card-header d-flex justify-content-center align-items-center " + item.color}
                                                    style={{ minHeight: '42px', paddingTop: '0px' }}>
                                                    <div className="iq-header-title text-center">
                                                        <h6 className="text-white mb-0">{item.title} ({item.count})</h6>
                                                    </div>
                                                </div>
                                                <CardBody className="card-body iq-card-body pro-bg-card scrollable-list" style={{ maxHeight: '600px', overflowY: 'auto' }}>
                                                    <div>
                                                        {
                                                            this.filteredItems(item.list).map((i, index) => {
                                                                const assignment = i.request_assignments && i.request_assignments.find(assignment => assignment.employee && assignment.employee.official_email === this.props.currentUser.email);
                                                                const plainDescription = DOMPurify.sanitize(i.description, {
                                                                    ALLOWED_TAGS: [],
                                                                    ALLOWED_ATTR: []
                                                                });

                                                                const cardStyle = {
                                                                    padding: '10px',
                                                                    margin: '0 10px'
                                                                };

                                                                const descriptionStyle = {
                                                                    display: '-webkit-box',
                                                                    WebkitLineClamp: 2, // Show only 2 lines
                                                                    WebkitBoxOrient: 'vertical',
                                                                    overflow: 'hidden',
                                                                    textOverflow: 'ellipsis',
                                                                    whiteSpace: 'normal'
                                                                };

                                                                const dateStyle = {
                                                                    display: 'flex',
                                                                    justifyContent: 'space-evenly',
                                                                    marginTop: '10px'
                                                                };

                                                                const spanStyle = {
                                                                    fontSize: '10px',
                                                                    color: 'red'
                                                                };

                                                                const progressBarContainerStyle = {
                                                                    marginTop: '10px',
                                                                    height: '4px'
                                                                };
                                                                const progressValue = assignment ? assignment.progress : i.progress;

                                                                const progressBarStyle = {
                                                                    width: `${progressValue || 0}%`,
                                                                    borderRadius: '10px',
                                                                    backgroundColor: 'green'
                                                                };

                                                                return (
                                                                    <Card className="iq-card custom-iq" draggable="true"
                                                                          key={index}
                                                                          onDragStart={(e) => this.onDragStart(e, index1)}
                                                                          id={[i.id]}>
                                                                        <div
                                                                            className="iq-card-header d-flex justify-content-between pro-task-bg-card border-bottom"
                                                                            style={{
                                                                                minHeight: '42px',
                                                                                paddingTop: '0px'
                                                                            }}>
                                                                            <div className="iq-header-title">
                                                                                <h6 className="card-title text-blue">{i.ticket_number || i.task_no}</h6>
                                                                                <p className="font-size-10">{i.reporter || "-"}</p>
                                                                            </div>
                                                                            <div>
                                                                                <FontAwesomeIcon icon={faEye} style={{
                                                                                    cursor: 'pointer',
                                                                                    float: 'right',
                                                                                    color: 'green'
                                                                                }}
                                                                                                 onClick={() => this.setState({ navigateTo: `/service_requests/${i.id}/details` })} />
                                                                            </div>
                                                                        </div>
                                                                        <CardBody
                                                                            className="card-body iq-card-body pro-task-bg-card"
                                                                            style={cardStyle}>
                                                                            <p className="font-size-12"
                                                                               style={descriptionStyle}>{i.request_title || i.description}</p>
                                                                            <div className="mt-2 progress"
                                                                                 style={progressBarContainerStyle}>
                                                                                <div role="progressbar"
                                                                                     aria-valuemin="0"
                                                                                     aria-valuemax="100"
                                                                                     aria-valuenow={i.progress || 0}
                                                                                     className="progress-bar iq-border-radius-10"
                                                                                     style={progressBarStyle}>
                                                                                    <span> </span>
                                                                                </div>
                                                                            </div>
                                                                            <div style={dateStyle}>
                                <span style={spanStyle}>
                                    Start-Date: {
                                    i.planned_start_date
                                        ? new Date(i.planned_start_date).toLocaleDateString()
                                        : (assignment && assignment.start_date ? new Date(assignment.start_date).toLocaleDateString() : "No start date")
                                }
                                </span>
                                                                                <span style={spanStyle}>
                                    End-Date: {
                                                                                    i.planned_due_date
                                                                                        ? new Date(i.planned_due_date).toLocaleDateString()
                                                                                        : (assignment && assignment.end_date ? new Date(assignment.end_date).toLocaleDateString() : "No end date")
                                                                                }
                                </span>
                                                                            </div>
                                                                        </CardBody>
                                                                    </Card>
                                                                );
                                                            })
                                                        }
                                                    </div>
                                                </CardBody>
                                            </Card>
                                        ))
                                    }
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </div>
        );
    }
}

export default Index;

