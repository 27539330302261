// SubtaskFormModal.jsx

import React, { useState, useEffect } from 'react';
import {
    Modal,
    Button,
    Form,
    Row,
    Col,
    OverlayTrigger,
    Tooltip,
} from 'react-bootstrap';
import Select from 'react-select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faTimes,
    faEye,
    faPlus,
    faTrash,
} from '@fortawesome/free-solid-svg-icons';
import ReactQuill from 'react-quill';
import { toast } from 'react-hot-toast';
import axios from 'axios';
import DOMPurify from 'dompurify';

const SubtaskFormModal = ({ show, onHide, taskId, currentUser, fetchTask }) => {
    // State variables
    const [locations, setLocations] = useState([]);
    const [departments, setDepartments] = useState([]);
    const [mainTypes, setMainTypes] = useState([]);
    const [ticketTypes, setTicketTypes] = useState([]);
    const [employees, setEmployees] = useState([]);
    const [selectedLocation, setSelectedLocation] = useState(null);
    const [selectedDepartment, setSelectedDepartment] = useState(null);
    const [selectedMainType, setSelectedMainType] = useState(null);
    const [selectedTicketType, setSelectedTicketType] = useState(null);
    const [toEmail, setToEmail] = useState('');
    const [selectedEmployee, setSelectedEmployee] = useState(null);
    const [discussion, setDiscussion] = useState('');
    const [request_title, setRequestTitle] = useState('');
    const [needByDate, setNeedByDate] = useState('');
    const [attachments, setAttachments] = useState([]);
    const [ccEmail, setCcEmail] = useState(null);
    const [profile, setProfile] = useState('');
    const [companyName, setCompanyName] = useState('');
    const [employeeCode, setEmployeeCode] = useState('');
    const [onBehalfOf, setOnBehalfOf] = useState(false);

    useEffect(() => {
        if (show) {
            // Fetch initial data when the modal is shown
            fetchLocations();
            fetchEmployeeId();
            resetFormState();
        }
    }, [show]);

    const fetchLocations = async () => {
        try {
            const response = await axios.get('/locations/fetch_location');
            setLocations(response.data);
        } catch (error) {
            console.error('Error fetching locations', error);
            toast.error('Error fetching locations');
        }
    };

    const fetchEmployeeId = async () => {
        try {
            const currentUserEmail = currentUser.email;
            const response = await axios.get('/employees/fetch_employee', {
                params: { official_email: currentUserEmail },
            });
            const employee = response.data[0];

            if (employee) {
                setCompanyName(employee.location.company.name);
                setEmployeeCode(employee.employee_code);
                setProfile(employee.profile_image_url || '');
            } else {
                toast.error('Current Employee record not found.');
            }
        } catch (error) {
            console.error('Error fetching employee details:', error);
            toast.error('Error fetching employee details.');
        }
    };

    const handleLocationChange = (selectedOption) => {
        setSelectedLocation(selectedOption);
        setDepartments([]);
        setMainTypes([]);
        setTicketTypes([]);
        setEmployees([]);
        setSelectedDepartment(null);
        setSelectedMainType(null);
        setSelectedTicketType(null);
        setToEmail('');
        if (selectedOption) {
            fetchDepartments(selectedOption.value);
        }
    };

    const fetchDepartments = async (locationId) => {
        try {
            const response = await axios.get('/departments/fetch_dept', {
                params: { location_id: locationId },
            });
            setDepartments(response.data);
        } catch (error) {
            console.error('Error fetching departments', error);
            toast.error('Error fetching departments');
        }
    };

    const handleDepartmentChange = (selectedOption) => {
        setSelectedDepartment(selectedOption);
        setMainTypes([]);
        setTicketTypes([]);
        setSelectedMainType(null);
        setSelectedTicketType(null);
        setToEmail('');
        if (selectedOption) {
            fetchMainTypes(selectedOption.value);
        }
    };

    const fetchMainTypes = async (departmentId) => {
        try {
            const response = await axios.get('/main_types/fetch_main_types', {
                params: { department_id: departmentId },
            });
            setMainTypes(response.data);
        } catch (error) {
            console.error('Error fetching main types', error);
            toast.error('Error fetching main types');
        }
    };

    const handleMainTypeChange = (selectedOption) => {
        setSelectedMainType(selectedOption);
        setTicketTypes([]);
        setSelectedTicketType(null);
        if (selectedOption) {
            fetchTicketTypes(selectedOption.value);
            const selectedMainType = mainTypes.find(
                (mainType) => mainType.id === selectedOption.value
            );
            setToEmail(selectedMainType ? selectedMainType.emails.join(', ') : '');
        } else {
            setToEmail('');
        }
    };

    const fetchTicketTypes = async (mainTypeId) => {
        try {
            const response = await axios.get('/ticket_types/fetch_ticket_types', {
                params: { main_type_id: mainTypeId },
            });
            setTicketTypes(response.data);
        } catch (error) {
            console.error('Error fetching ticket types', error);
            toast.error('Error fetching ticket types');
        }
    };

    const handleTicketTypeChange = (selectedOption) => {
        setSelectedTicketType(selectedOption);
    };

    const handleEmployeeSearch = (inputValue) => {
        if (inputValue && inputValue.trim().length > 0) {
            fetchEmployees(inputValue);
        } else {
            setEmployees([]);
        }
    };

    const fetchEmployees = async (query) => {
        try {
            const response = await axios.get('/employees/fetch_employee', {
                params: { official_email: query || '' },
            });
            const employeesData = response.data;

            const employeeOptions = employeesData.map((employee) => ({
                value: employee.official_email,
                label: `${employee.name} (${employee.official_email})`,
            }));

            setEmployees(employeeOptions);
        } catch (error) {
            console.error('Error fetching employees', error);
            toast.error('Error fetching employees');
        }
    };

    const handleOtherEmployeeChange = (selectedOption) => {
        setSelectedEmployee(selectedOption);
    };

    const handleCcEmailChange = (selectedOption) => {
        setCcEmail(selectedOption);
    };

    const handleAddAttachment = () => {
        setAttachments([...attachments, { id: null, name: '', file: null }]);
    };

    const handleFileChange = (event, index) => {
        const selectedFile = event.target.files[0];
        if (selectedFile) {
            const updatedFiles = [...attachments];
            updatedFiles[index] = {
                ...updatedFiles[index],
                file: selectedFile,
                name: selectedFile.name,
            };
            setAttachments(updatedFiles);
        }
    };

    const handleRemoveAttachment = (index) => {
        setAttachments((prevAttachments) => {
            const updatedFiles = [...prevAttachments];
            if (updatedFiles[index].id) {
                updatedFiles[index]._destroy = true;
            } else {
                updatedFiles.splice(index, 1);
            }
            return updatedFiles;
        });
    };

    const handleSubmit = async () => {
        try {
            const formData = new FormData();
            const ccEmailString =
                ccEmail && ccEmail.length > 0
                    ? ccEmail.map((option) => option.value).join(',')
                    : '';

            if (selectedLocation && selectedLocation.value) {
                formData.append('ticket[location_id]', selectedLocation.value);
            } else {
                throw new Error('Location is required');
            }

            if (selectedDepartment && selectedDepartment.value) {
                formData.append('ticket[department_id]', selectedDepartment.value);
            } else {
                throw new Error('Department is required');
            }

            if (selectedMainType && selectedMainType.value) {
                formData.append('ticket[main_type_id]', selectedMainType.value);
            } else {
                throw new Error('Main type is required');
            }

            if (selectedTicketType && selectedTicketType.value) {
                formData.append('ticket[ticket_type_id]', selectedTicketType.value);
            } else {
                throw new Error('SR type is required');
            }

            if (needByDate) {
                formData.append('ticket[need_by_date]', needByDate);
            } else {
                throw new Error('Need by Date is required');
            }

            formData.append('ticket[on_behalf_of]', selectedEmployee ? true : false);

            if (selectedEmployee && selectedEmployee.value) {
                formData.append('ticket[other_employee_code]', selectedEmployee.value);
            }

            if (toEmail) {
                formData.append('ticket[to_email]', toEmail);
            }

            formData.append('ticket[cc_email]', ccEmailString);

            if (request_title) {
                formData.append('ticket[request_title]', request_title);
            } else {
                throw new Error('Request title is required');
            }

            if (discussion) {
                formData.append('ticket[description]', discussion);
            }

            if (selectedEmployee && selectedEmployee.value) {
                formData.append('ticket[reporter_email]', selectedEmployee.value);
            } else {
                formData.append('ticket[reporter_email]', currentUser.email);
            }

            attachments.forEach((file, index) => {
                if (file.file) {
                    formData.append(
                        `ticket[attachments_attributes][${index}][file]`,
                        file.file
                    );
                }
                if (file.id) {
                    formData.append(
                        `ticket[attachments_attributes][${index}][id]`,
                        file.id
                    );
                }
                if (file._destroy) {
                    formData.append(
                        `ticket[attachments_attributes][${index}][_destroy]`,
                        '1'
                    );
                }
            });

            if (currentUser && currentUser.full_name) {
                formData.append('ticket[reporter]', currentUser.full_name);
            } else {
                throw new Error('Reporter information is missing');
            }

            formData.append('ticket[status]', 'New');
            formData.append('ticket[assignee]', 'Unassigned');

            // Include parent_ticket_id to link the subtask with the parent ticket
            formData.append('ticket[parent_ticket_id]', taskId);

            await toast.promise(
                axios.post('/service_requests', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                }),
                {
                    loading: 'Creating subtask...',
                    success: 'Subtask created successfully!',
                    error: 'Error creating subtask',
                },
                { position: 'top-center' }
            );

            // Reset form state
            resetFormState();

            // Close modal
            onHide();

            // Fetch task to refresh data
            fetchTask();
        } catch (error) {
            toast.error(`Error creating subtask: ${error.message}`);
            console.error('Error creating subtask', error);
        }
    };

    const resetFormState = () => {
        setSelectedLocation(null);
        setSelectedDepartment(null);
        setSelectedMainType(null);
        setSelectedTicketType(null);
        setSelectedEmployee(null);
        setDiscussion('');
        setToEmail('');
        setCcEmail(null);
        setRequestTitle('');
        setNeedByDate('');
        setAttachments([]);
        setEmployees([]);
        setOnBehalfOf(false);
    };

    const handleModalClose = () => {
        resetFormState();
        onHide();
    };

    const sanitizeContent = (htmlContent) => {
        return DOMPurify.sanitize(htmlContent, { FORBID_TAGS: ['img'] });
    };

    return (
        <Modal show={show} onHide={handleModalClose} size="lg">
            <Modal.Header>
                <Modal.Title>Create Subtask</Modal.Title>
                <Button
                    variant="link"
                    onClick={handleModalClose}
                    style={{ position: 'absolute', right: '15px', top: '15px' }}
                >
                    <FontAwesomeIcon icon={faTimes} size="lg" />
                </Button>
            </Modal.Header>
            <Modal.Body>
                {/* Include your EmployeeDetails component if needed */}
                {/* <EmployeeDetails ... /> */}
                <Form>
                    {/* Begin Form Content */}
                    <Row>
                        <Col>
                            <Form.Group controlId="formGridLocation">
                                <Form.Label>
                                    Location <span style={{ color: 'red' }}>*</span>
                                </Form.Label>
                                <Select
                                    options={locations.map((location) => ({
                                        value: location.id,
                                        label: location.name,
                                    }))}
                                    isClearable
                                    classNamePrefix="custom-height"
                                    onChange={handleLocationChange}
                                    value={selectedLocation}
                                />
                            </Form.Group>
                        </Col>

                        <Col>
                            <Form.Group controlId="formGridDepartment">
                                <Form.Label>
                                    Department <span style={{ color: 'red' }}>*</span>
                                </Form.Label>
                                <Select
                                    options={departments.map((department) => ({
                                        value: department.id,
                                        label: department.name,
                                    }))}
                                    isClearable
                                    classNamePrefix="custom-height"
                                    onChange={handleDepartmentChange}
                                    isDisabled={!selectedLocation}
                                    value={selectedDepartment}
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group controlId="formGridMainType">
                                <Form.Label>
                                    Sub-Dept <span style={{ color: 'red' }}>*</span>
                                </Form.Label>
                                <Select
                                    options={mainTypes.map((mainType) => ({
                                        value: mainType.id,
                                        label: mainType.name,
                                    }))}
                                    isClearable
                                    classNamePrefix="custom-height"
                                    onChange={handleMainTypeChange}
                                    isDisabled={!selectedDepartment}
                                    value={selectedMainType}
                                />
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group controlId="formGridTicketType">
                                <Form.Label>
                                    SR Type <span style={{ color: 'red' }}>*</span>
                                </Form.Label>
                                <Select
                                    options={ticketTypes.map((ticketType) => ({
                                        value: ticketType.id,
                                        label: ticketType.name,
                                    }))}
                                    isClearable
                                    classNamePrefix="custom-height"
                                    onChange={handleTicketTypeChange}
                                    isDisabled={!selectedMainType}
                                    value={selectedTicketType}
                                    required
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group controlId="formGridNeedByDate">
                                <Form.Label>
                                    Need By Date <span style={{ color: 'red' }}>*</span>
                                </Form.Label>
                                <Form.Control
                                    type="date"
                                    className="custom-height"
                                    value={needByDate}
                                    onChange={(e) => setNeedByDate(e.target.value)}
                                    min={new Date().toISOString().split('T')[0]}
                                    required
                                />
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group
                                controlId="formGridOnBehalfOf"
                                style={{ display: 'flex', alignItems: 'center', marginTop: '28px' }}
                            >
                                <Form.Check
                                    type="checkbox"
                                    label="On behalf of"
                                    checked={onBehalfOf}
                                    onChange={() => setOnBehalfOf(!onBehalfOf)}
                                    className="custom-height"
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                    {onBehalfOf && (
                        <Row>
                            <Col>
                                <Form.Group controlId="formGridOtherEmployee">
                                    <Form.Label>Other Employee</Form.Label>
                                    <Select
                                        options={employees}
                                        isClearable
                                        classNamePrefix="custom-height"
                                        onChange={handleOtherEmployeeChange}
                                        onInputChange={(value) => handleEmployeeSearch(value)}
                                        value={selectedEmployee}
                                        placeholder="Search and select employee"
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                    )}
                    <Form.Group controlId="formGridTo">
                        <Form.Label>To</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="To"
                            className="custom-height"
                            value={toEmail || ''}
                            readOnly
                        />
                    </Form.Group>
                    <Form.Group controlId="formGridCC">
                        <Form.Label>CC</Form.Label>
                        <Select
                            options={employees}
                            value={ccEmail}
                            onChange={handleCcEmailChange}
                            onInputChange={(value) => handleEmployeeSearch(value)}
                            isClearable
                            isMulti
                            placeholder="Search and select CC"
                        />
                    </Form.Group>
                    <Form.Group controlId="formGridRequestTitle">
                        <Form.Label>
                            Request Title <span style={{ color: 'red' }}>*</span>
                        </Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Request Title"
                            className="custom-height"
                            value={request_title}
                            onChange={(e) => setRequestTitle(e.target.value)}
                        />
                    </Form.Group>
                    <Form.Group controlId="formGridDiscussion">
                        <Form.Label
                            className={'d-flex justify-content-center align-items-center mt-2 label-color'}
                        >
                            Description
                        </Form.Label>
                        <ReactQuill
                            value={sanitizeContent(discussion)}
                            onChange={(value) => setDiscussion(value)}
                            theme="snow"
                            style={{ height: '150px' }}
                        />
                    </Form.Group>
                    <Form.Label
                        className={'d-flex justify-content-center align-items-center label-color'}
                        style={{ marginTop: '50px' }}
                    >
                        Attachments (Doc, JPEG, PNG, PDF, mp4, mkv, avi, mov)
                    </Form.Label>
                    <Form.Group controlId="formGridAttachment" className={'col-md-6'}>
                        {attachments.map(
                            (file, index) =>
                                !file._destroy && (
                                    <div key={index} className="d-flex align-items-center mb-2">
                                        <div className="custom-file" style={{ flexGrow: 1 }}>
                                            <input
                                                type="file"
                                                className="custom-file-input custom-height"
                                                id={`validatedCustomFileBack${index}`}
                                                name="avatar"
                                                accept=".jpg, .jpeg, .png, .webp,.pdf, .doc, .docx, .mp4, .mkv, .avi, .mov"
                                                onChange={(e) => handleFileChange(e, index)}
                                            />
                                            <label
                                                className="custom-file-label custom-height"
                                                htmlFor={`validatedCustomFileBack${index}`}
                                            >
                                                {file && file.name ? file.name : 'Choose file...'}
                                            </label>
                                        </div>
                                        <OverlayTrigger
                                            placement="top"
                                            overlay={<Tooltip id={`tooltip-view-attachment`}>View Attachment</Tooltip>}
                                        >
                                            <Button
                                                variant="info"
                                                size="sm"
                                                onClick={() => handleFetchAttachment(file.id)}
                                                className="ml-2 custom-height"
                                            >
                                                <FontAwesomeIcon icon={faEye} />
                                            </Button>
                                        </OverlayTrigger>
                                        <Button
                                            variant="danger"
                                            size="sm"
                                            onClick={() => handleRemoveAttachment(index)}
                                            className="ml-2 custom-height"
                                        >
                                            <FontAwesomeIcon icon={faTrash} />
                                        </Button>
                                    </div>
                                )
                        )}
                    </Form.Group>
                    <Button
                        variant="success"
                        size="sm"
                        onClick={handleAddAttachment}
                        className="custom-height d-flex align-items-center"
                    >
                        <FontAwesomeIcon icon={faPlus} />
                    </Button>
                    {/* End Form Content */}
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary custom-height" onClick={handleModalClose}>
                    Close
                </Button>
                <Button variant="primary custom-height" onClick={handleSubmit}>
                    Submit
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default SubtaskFormModal;
