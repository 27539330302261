import React, {useState, useEffect} from 'react';
import {Link, useLocation} from 'react-router-dom';
import TopNavbar from './TopNavbar';
import Footer from './Footer';
import logo1 from '../images/s.gif';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
    faBuildingUser,
    faSignature,
    faSitemap,
    faPercentage,
    faCodePullRequest, faUsers, faTicket, faDesktop, faCreditCard, faAddressCard
} from '@fortawesome/free-solid-svg-icons';
import {faServicestack} from "@fortawesome/free-brands-svg-icons";

const Sidebar = ({
                     currentUser,
                     menus,
                     dashboard_path,
                     pms_path,
                     bi_path,
                     road_map_path,
                     x_you_path,
                     discount_detail_path,
                     discounts_path,
                     service_request_path,
                     sr_dashboards_path,
                     team_dashboards_path,
                     projects_path,
                     request_path,
                     work_desk_path,
                     signature_path,
                     digital_profile_path,
                     user_path,
                     menu_path,
                     role_path,
                     company_path,
                     location_path,
                     employees_path,
                     department_path,
                     main_type_path,
                     user_profile_path,
                     activity_stream_path,
                     link_target_attribute
                 }) => {
    const location = useLocation();
    const [isOrgOpen, setIsOrgOpen] = useState(false);
    const [isEssOpen, setIsEssOpen] = useState(false);
    const [isPmsOpen, setIsPmsOpen] = useState(false);
    const [isSrmOpen, setIsSrmOpen] = useState(false);
    const [isAuthOpen, setIsAuthOpen] = useState(false);

    useEffect(() => {
    }, [currentUser, menus]);

    if (!menus) {
        console.error('Menus data is missing');
        return null;
    }

    const permissions = {};
    const user = currentUser.role;
    if (user && Array.isArray(user.permissions)) {
        user.permissions.forEach(permission => {
            const menu = menus[permission.menu_id];
            const slug = menu && menu.slug;
            if (slug) {
                permissions[slug] = permission;
            }
        });
    } else {
        console.error('Permissions data is missing:', user);
    }

    const toggleOrg = () => setIsOrgOpen(!isOrgOpen);
    const toggleEss = () => setIsEssOpen(!isEssOpen);
    const togglePms = () => setIsPmsOpen(!isPmsOpen);
    const toggleSrm = () => setIsSrmOpen(!isSrmOpen);
    const toggleAuth = () => setIsAuthOpen(!isAuthOpen);

    const hasPermission = (slug) => {
        return permissions[slug] && permissions[slug].is_index;
    };

    useEffect(() => {
        const orgPaths = [company_path, location_path, department_path, main_type_path];
        const authPaths = [user_path, menu_path, role_path, activity_stream_path];
        const essPaths = [discount_detail_path, service_request_path, employees_path, work_desk_path,discounts_path,digital_profile_path];
        const pmsPaths = [projects_path];
        const srmPaths = [request_path,sr_dashboards_path,team_dashboards_path];

        if (orgPaths.includes(location.pathname)) {
            setIsOrgOpen(true);
        } else {
            setIsOrgOpen(false);
        }

        if (essPaths.includes(location.pathname)) {
            setIsEssOpen(true);
        } else {
            setIsEssOpen(false);
        }

        if (pmsPaths.includes(location.pathname)) {
            setIsPmsOpen(true);
        } else {
            setIsPmsOpen(false);
        }

        if (srmPaths.includes(location.pathname)) {
            setIsSrmOpen(true);
        } else {
            setIsSrmOpen(false);
        }

        if (authPaths.includes(location.pathname)) {
            setIsAuthOpen(true);
        } else {
            setIsAuthOpen(false);
        }
    }, [location.pathname, company_path, user_path, menu_path, role_path, activity_stream_path]);

    const SidebarContent = () => (
        <div>
            <div className="iq-sidebar-logo d-flex justify-content-between">
                <Link to={dashboard_path}>
                    <div className="iq-light-logo">
                        <img src={logo1} className="img-fluid item img" alt=""/>
                    </div>
                    <div className="iq-dark-logo">
                        <img src={logo1} className="img-fluid item img" alt=""/>
                    </div>
                </Link>
                <div className="iq-menu-bt-sidebar">
                    <div className="iq-menu-bt align-self-center">
                        <div className="wrapper-menu">
                            <div className="main-circle"><i className="ri-arrow-left-s-line"></i></div>
                            <div className="hover-circle"><i className="ri-arrow-right-s-line"></i></div>
                        </div>
                    </div>
                </div>
            </div>

            <div id="sidebar-scrollbar">
                <nav className="iq-sidebar-menu">
                    <ul id="iq-sidebar-toggle" className="iq-menu">
                        {hasPermission("dashboard") && (
                            <div>
                                <li className="iq-menu-title">
                                    <i className="ri-subtract-line"></i>
                                    <span>Dashboards</span>
                                </li>
                                <li className={location.pathname === dashboard_path ? 'active' : ''}>
                                    <Link to={dashboard_path} className="iq-waves-effect">
                                        <i className="ri-home-4-line"></i>
                                        <span>Dashboard</span>
                                    </Link>
                                </li>
                            </div>
                        )}
                        <li className="iq-menu-title">
                            <i className="ri-subtract-line"></i>
                            <span>Apps</span>
                        </li>
                        {(hasPermission("employee_self_service")) && (

                            <li className={isEssOpen ? 'active' : ''}>
                                <a
                                    href="#ess"
                                    className={`iq-waves-effect ${isEssOpen ? '' : 'collapsed'}`}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        toggleEss();
                                    }}
                                    aria-expanded={isEssOpen}
                                >
                                    <i className="ri-wallet-2-line"></i>
                                    <span>Employee Self Service</span>
                                    <i className="ri-arrow-right-s-line iq-arrow-right"></i>
                                </a>
                                <ul
                                    id="ess"
                                    className={`iq-submenu collapse ${isEssOpen ? 'show' : ''}`}
                                >
                                    {hasPermission("my_work_desk") && (
                                        <li className={(location.pathname === work_desk_path)
                                        ? 'active' : ''}>
                                        <Link to={work_desk_path} className="iq-waves-effect">
                                            <i><FontAwesomeIcon icon={faDesktop}/></i>
                                            <span>My Work Desk</span>
                                        </Link>
                                    </li>
                                    )}
                                    {hasPermission("user_creation_request") && (
                                        <li className={(location.pathname === employees_path)
                                        ? 'active' : ''}>
                                        <Link to={employees_path} className="iq-waves-effect">
                                            <i><FontAwesomeIcon icon={faUsers}/></i>
                                            <span>User Creation Request</span>
                                        </Link>
                                    </li>
                                    )}
                                    {hasPermission("service_request") && (
                                        <li className={(location.pathname === service_request_path) ? 'active' : ''}>
                                        <Link to={service_request_path} className="iq-waves-effect">
                                            <i><FontAwesomeIcon icon={faCodePullRequest}/></i>
                                            <span>Service Request</span>
                                        </Link>
                                    </li>
                                    )}
                                    {hasPermission("discount_details") && (
                                        <li className={(location.pathname === discounts_path) ? 'active' : ''}>
                                        <Link to={discounts_path} className="iq-waves-effect">
                                            <i><FontAwesomeIcon icon={faPercentage}/></i>
                                            <span>Discount Details</span>
                                        </Link>
                                    </li>
                                    )}
                                    {hasPermission("my_discount_card") && (
                                        <li className={(location.pathname === discount_detail_path) ? 'active' : ''}>
                                        <Link to={discount_detail_path} className="iq-waves-effect">
                                            <i><FontAwesomeIcon icon={faCreditCard}/></i>
                                            <span>My Discount Card</span>
                                        </Link>
                                    </li>
                                    )}
                                    {hasPermission("my_digital_profile") && (
                                        <li className={(location.pathname === digital_profile_path) ? 'active' : ''}>
                                        <Link to={digital_profile_path} className="iq-waves-effect">
                                            <i><FontAwesomeIcon icon={faAddressCard}/></i>
                                            <span>My Digital Profile</span>
                                        </Link>
                                    </li>
                                    )}
                                </ul>
                            </li>
                        )}

                        {(hasPermission("sr_management")) && (

                            <li className={isSrmOpen ? 'active' : ''}>
                                <a
                                    href="#srm"
                                    className={`iq-waves-effect ${isSrmOpen ? '' : 'collapsed'}`}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        toggleSrm();
                                    }}
                                    aria-expanded={isSrmOpen}
                                >
                                    <i><FontAwesomeIcon icon={faServicestack}/></i>
                                    <span>SR Management</span>
                                    <i className="ri-arrow-right-s-line iq-arrow-right"></i>
                                </a>
                                <ul
                                    id="srm"
                                    className={`iq-submenu collapse ${isSrmOpen ? 'show' : ''}`}
                                >
                                    {hasPermission("sr_assignment") && (
                                        <li className={location.pathname === request_path ? 'active' : ''}>
                                        <Link to={request_path} className="iq-waves-effect">
                                            <i><FontAwesomeIcon icon={faTicket}/></i>
                                            <span>SR Assignment</span>
                                        </Link>
                                    </li>
                                    )}
                                    {hasPermission("sr_dashboard") && (
                                        <li className={location.pathname === sr_dashboards_path ? 'active' : ''}>
                                        <Link to={sr_dashboards_path} className="iq-waves-effect">
                                            <i className="ri-bar-chart-box-line"></i>
                                            <span>SR Dashboard</span>
                                        </Link>
                                    </li>
                                    )}
                                    {hasPermission("team_dashboard") && (
                                        <li className={location.pathname === team_dashboards_path ? 'active' : ''}>
                                        <Link to={team_dashboards_path} className="iq-waves-effect">
                                            <i className="ri-group-line"></i>
                                            <span>Team Dashboard</span>
                                        </Link>
                                    </li>
                                    )}


                                </ul>
                            </li>
                        )}

                        {(hasPermission("project_management")) && (

                            <li className={isPmsOpen ? 'active' : ''}>
                                <a
                                    href="#pms"
                                    className={`iq-waves-effect ${isPmsOpen ? '' : 'collapsed'}`}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        togglePms();
                                    }}
                                    aria-expanded={isPmsOpen}
                                >
                                    <i className="ri-terminal-window-line"></i>
                                    <span>Project Management</span>
                                    <i className="ri-arrow-right-s-line iq-arrow-right"></i>
                                </a>
                                <ul
                                    id="pms"
                                    className={`iq-submenu collapse ${isPmsOpen ? 'show' : ''}`}
                                >
                                    <li className={location.pathname === projects_path ? 'active' : ''}>
                                        <Link to={projects_path} className="iq-waves-effect">
                                            <i className="ri-list-check-2"></i>
                                            <span>Projects Dashboard</span>
                                        </Link>
                                    </li>
                                </ul>
                            </li>
                        )}


                        {/*{(hasPermission("project_management")) && (*/}
                        {/*    <li className={location.pathname === pms_path ? 'active' : ''}>*/}
                        {/*        <Link to={pms_path} className="iq-waves-effect">*/}
                        {/*            <i className="ri-terminal-window-line"></i>*/}
                        {/*            <span>Project Management</span>*/}
                        {/*        </Link>*/}
                        {/*    </li>*/}
                        {/*)}*/}
                        {(hasPermission("beirholm_bi")) && (
                            <li className={location.pathname === bi_path ? 'active' : ''}>
                                <Link to={bi_path} className="iq-waves-effect">
                                    <i className="ri-bar-chart-2-line"></i>
                                    <span>Beirholm Bi</span>
                                </Link>
                            </li>
                        )}
                        {(hasPermission("roadmap")) && (
                            <li className={location.pathname === road_map_path ? 'active' : ''}>
                                <Link to={road_map_path} className="iq-waves-effect">
                                    <i className="ri-road-map-line"></i>
                                    <span>Beirholm RoadMap</span>
                                </Link>
                            </li>
                        )}
                        {(hasPermission("sapphire_x_you")) && (
                            <li className={location.pathname === x_you_path ? 'active' : ''}>
                                <Link to={x_you_path} className="iq-waves-effect">
                                    <i className="ri-palette-line"></i>
                                    <span>Sapphire X You</span>
                                </Link>
                            </li>
                        )}

                        {(hasPermission("digital_signatures")) && (
                            <li className={location.pathname === signature_path ? 'active' : ''}>
                                <Link to={signature_path} className="iq-waves-effect">
                                    <i><FontAwesomeIcon icon={faSignature}/></i>
                                    <span>Digital Signatures</span>
                                </Link>
                            </li>
                        )}

                        {(hasPermission("organization")) && (
                            <div>
                                <li className="iq-menu-title">
                                    <i className="ri-subtract-line"></i>
                                    <span>Settings</span>
                                </li>
                                <li className={isOrgOpen ? 'active' : ''}>
                                    <a
                                        href="#org"
                                        className={`iq-waves-effect ${isOrgOpen ? '' : 'collapsed'}`}
                                        onClick={(e) => {
                                            e.preventDefault();
                                            toggleOrg();
                                        }}
                                        aria-expanded={isOrgOpen}
                                    >
                                        <i><FontAwesomeIcon icon={faSitemap}/></i>
                                        <span>Organization</span>
                                        <i className="ri-arrow-right-s-line iq-arrow-right"></i>
                                    </a>
                                    <ul
                                        id="org"
                                        className={`iq-submenu collapse ${isOrgOpen ? 'show' : ''}`}
                                    >
                                        <li className={(location.pathname === company_path) || (location.pathname === location_path)
                                        || (location.pathname === department_path) || (location.pathname === main_type_path)
                                        || (location.pathname === department_path) || (location.pathname === main_type_path)
                                            ? 'active' : ''}>
                                            <Link to={company_path} className="iq-waves-effect">
                                                <i><FontAwesomeIcon icon={faBuildingUser}/></i>
                                                <span>Company Setup</span>
                                            </Link>
                                        </li>
                                    </ul>
                                </li>
                            </div>
                        )}
                        {(hasPermission("users") || hasPermission("menus") || hasPermission("roles") || hasPermission("activity_streams")) && (
                            <div>
                                <li className={isAuthOpen ? 'active' : ''}>
                                    <a
                                        href="#authentication"
                                        className={`iq-waves-effect ${isAuthOpen ? '' : 'collapsed'}`}
                                        onClick={(e) => {
                                            e.preventDefault();
                                            toggleAuth();
                                        }}
                                        aria-expanded={isAuthOpen}
                                    >
                                        <i className="ri-pages-line"></i>
                                        <span>Authentication</span>
                                        <i className="ri-arrow-right-s-line iq-arrow-right"></i>
                                    </a>
                                    <ul
                                        id="authentication"
                                        className={`iq-submenu collapse ${isAuthOpen ? 'show' : ''}`}
                                    >
                                        {hasPermission("users") && (
                                            <li className={location.pathname === user_path ? 'active' : ''}>
                                                <Link to={user_path} className="iq-waves-effect">
                                                    <i className="ri-user-add-line"></i>
                                                    <span>User Add</span>
                                                </Link>
                                            </li>
                                        )}
                                        {hasPermission("menus") && (
                                            <li className={location.pathname === menu_path ? 'active' : ''}>
                                                <Link to={menu_path} className="iq-waves-effect">
                                                    <i className="ri-menu-2-fill"></i>
                                                    <span>Menus</span>
                                                </Link>
                                            </li>
                                        )}
                                        {hasPermission("roles") && (
                                            <li className={location.pathname === role_path ? 'active' : ''}>
                                                <Link to={role_path} className="iq-waves-effect">
                                                    <i className="ri-user-unfollow-fill"></i>
                                                    <span>Roles & Permissions</span>
                                                </Link>
                                            </li>
                                        )}
                                        {hasPermission("activity_streams") && (
                                            <li className={location.pathname === activity_stream_path ? 'active' : ''}>
                                                <Link to={activity_stream_path} className="iq-waves-effect">
                                                    <i className="ri-list-check-2"></i>
                                                    <span>Activity Streams</span>
                                                </Link>
                                            </li>
                                        )}
                                    </ul>
                                </li>
                            </div>
                        )}
                    </ul>
                </nav>
                <div className="p-3"></div>
            </div>
            <Footer/>
        </div>
    );

    return (
        <div>
            <SidebarContent/>
        </div>
    );
};

export default Sidebar;
