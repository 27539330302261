import React from 'react';

const TeamDashboard = () => {
    // Sample employee data for demonstration
    const employees = [
        { id: 1, name: 'Rana Hussain', status: 'Active', comments: 15, link: '#' },
    ];

    return (
        <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', gap: '20px', padding: '20px' }}>
            {employees.map((employee, index) => (
                <div
                    key={index}
                    style={{
                        width: '320px',
                        border: '1px solid #ddd',
                        borderRadius: '8px',
                        backgroundColor: '#fff',
                        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                        marginBottom: '20px',
                    }}
                >
                    {/* Card Header */}
                    <div
                        style={{
                            backgroundColor: '#f5f5f5',
                            padding: '10px',
                            borderTopLeftRadius: '8px',
                            borderTopRightRadius: '8px',
                            borderBottom: '1px solid #ddd',
                        }}
                    >
                        <h3 style={{ margin: 0, color: '#333', fontSize: '16px' }}>{employee.name}</h3>
                    </div>

                    {/* Card Content */}
                    <div style={{ padding: '15px' }}>
                        <table style={{ width: '100%', borderCollapse: 'collapse' }}>
                            <thead>
                            <tr style={{ backgroundColor: '#f0f0f0' }}>
                                <th style={{ padding: '8px', textAlign: 'center', borderBottom: '1px solid #ddd', whiteSpace: 'nowrap' }}>SR</th>
                                <th style={{ padding: '8px', textAlign: 'center', borderBottom: '1px solid #ddd', whiteSpace: 'nowrap' }}>Title</th>
                                <th style={{ padding: '8px', textAlign: 'center', borderBottom: '1px solid #ddd', whiteSpace: 'nowrap' }}>Status</th>
                                <th style={{ padding: '8px', textAlign: 'center', borderBottom: '1px solid #ddd', whiteSpace: 'nowrap' }}>Comments</th>
                                <th style={{ padding: '8px', textAlign: 'center', borderBottom: '1px solid #ddd', whiteSpace: 'nowrap' }}>View Link</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <td style={{ padding: '8px', textAlign: 'center', borderBottom: '1px solid #ddd', whiteSpace: 'nowrap' }}>{employee.id}</td>
                                <td style={{ padding: '8px', textAlign: 'center', borderBottom: '1px solid #ddd', whiteSpace: 'nowrap' }}>{employee.name.split(' ')[0]}</td>
                                <td style={{ padding: '8px', textAlign: 'center', borderBottom: '1px solid #ddd', whiteSpace: 'nowrap' }}>{employee.status}</td>
                                <td style={{ padding: '8px', textAlign: 'center', borderBottom: '1px solid #ddd', whiteSpace: 'nowrap' }}>{employee.comments}</td>
                                <td style={{ padding: '8px', textAlign: 'center', borderBottom: '1px solid #ddd', whiteSpace: 'nowrap' }}>
                                    <a href={employee.link} style={{ color: '#007bff', textDecoration: 'none', whiteSpace: 'nowrap' }}>
                                        View
                                    </a>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            ))}
        </div>
    );
};

export default TeamDashboard;
