import React, { useState, useEffect } from 'react';
import axios from 'axios';
import DataTable from 'react-data-table-component';
import { Button, InputGroup, FormControl, Modal, Form, Row, Col, OverlayTrigger, Tooltip } from 'react-bootstrap';
import Select from 'react-select';
import toast, { Toaster } from 'react-hot-toast';
import cable from '../../../services/cable';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faEye, faCheck, faPlus, faTrash, faShare } from '@fortawesome/free-solid-svg-icons';
import EmployeeDetails from "./EmployeeDetails";
import ReactQuill from "react-quill";
import { useLocation } from "react-router-dom";
import DOMPurify from "dompurify";
import CreatableSelect from 'react-select/creatable';

const customStyles = {
    headCells: {
        style: {
            backgroundColor: 'hsl(253deg 61% 55%)',
            color: '#fff',
            fontSize: '13px',
            fontWeight: 'bold',
        },
    },
    cells: {
        style: {
            padding: '10px',
        },
    },
};

const ServiceRequestList = ({ currentUser, avatarUrl }) => {
    const [serviceRequests, setServiceRequests] = useState([]);
    const [filterText, setFilterText] = useState('');
    const [showModal, setShowModal] = useState(false);
    const [currentRequest, setCurrentRequest] = useState(null);
    const [locations, setLocations] = useState([]);
    const [departments, setDepartments] = useState([]);
    const [mainTypes, setMainTypes] = useState([]);
    const [ticketTypes, setTicketTypes] = useState([]);
    const [employees, setEmployees] = useState([]);
    const [selectedLocation, setSelectedLocation] = useState(null);
    const [selectedDepartment, setSelectedDepartment] = useState(null);
    const [selectedMainType, setSelectedMainType] = useState(null);
    const [selectedTicketType, setSelectedTicketType] = useState(null);
    const [toEmail, setToEmail] = useState('');
    const [selectedEmployee, setSelectedEmployee] = useState(null);
    const [discussion, setDiscussion] = useState('');
    const [files, setFiles] = useState([]);
    const [request_title, setRequestTitle] = useState('');
    const [needByDate, setNeedByDate] = useState('');
    const [attachments, setAttachments] = useState([]);
    const [ccEmail, setCcEmail] = useState(null);
    const [showConfirmationModal, setShowConfirmationModal] = useState(false);
    const [requestToSubmit, setRequestToSubmit] = useState(null);
    const [companyName, setCompanyName] = useState('');
    const [ticketNumber, setTicketNumber] = useState('');
    const [reporter, setReporter] = useState('');
    const [profile, setProfile] = useState('');
    const [attachmentUrl, setAttachmentUrl] = useState('');
    const [employeeCode, setEmployeeCode] = useState('');

    const [pagination, setPagination] = useState({
        currentPage: 1,
        totalPages: 1,
        nextPage: null,
        prevPage: null
    });

    const formatDateTime = (date) => {
        const options = {
            year: 'numeric',
            day: '2-digit',
            month: '2-digit',
            hour: 'numeric',
            minute: 'numeric',
            hour12: true,
            timeZone: 'Asia/Karachi'
        };
        return new Date(date).toLocaleDateString('en-US', options).replace(',', '');
    };
    const dateTime = formatDateTime(new Date());

    const fetchServiceRequests = async (page = 1) => {
        try {
            const response = await axios.get('/service_requests/fetch_tickets', {
                params: { page }
            });
            const updatedRequests = response.data.tickets.map(request => ({
                ...request,
                assignees: request.request_assignments.map(assignment => assignment.employee.name).join(', '),
            }));
            setServiceRequests(updatedRequests);
            setPagination({
                currentPage: response.data.current_page,
                totalPages: response.data.total_pages,
                nextPage: response.data.next_page,
                prevPage: response.data.prev_page
            });
        } catch (error) {
            console.error('Error fetching service requests', error);
            toast.error('Error fetching service requests');
        }
    };

    const location = useLocation();
    const getQueryParams = (search) => {
        return new URLSearchParams(search);
    };

    useEffect(() => {
        const queryParams = getQueryParams(location.search);
        const isView = queryParams.get('is_view');
        const ticketNumber = queryParams.get('ticket_number');

        if (isView === 'true' && ticketNumber) {
            const ticket = serviceRequests.find(sr => sr.ticket_number === ticketNumber);
            if (ticket) {
                handleShowModal(ticket);
            }
        }
    }, [location.search, serviceRequests]);

    const fetchLocations = async () => {
        try {
            const response = await axios.get('/locations/fetch_location');
            setLocations(response.data);
        } catch (error) {
            console.error('Error fetching locations', error);
            toast.error('Error fetching locations');
        }
    };

    const fetchDepartments = async (locationId) => {
        try {
            const response = await axios.get('/departments/fetch_dept', { params: { location_id: locationId } });
            setDepartments(response.data);
        } catch (error) {
            console.error('Error fetching departments', error);
            toast.error('Error fetching departments');
        }
    };

    const fetchMainTypes = async (departmentId) => {
        try {
            const response = await axios.get('/main_types/fetch_main_types', { params: { department_id: departmentId } });
            setMainTypes(response.data);
        } catch (error) {
            console.error('Error fetching main types', error);
            toast.error('Error fetching main types');
        }
    };

    const fetchTicketTypes = async (mainTypeId) => {
        try {
            const response = await axios.get('/ticket_types/fetch_ticket_types', { params: { main_type_id: mainTypeId } });
            setTicketTypes(response.data);
        } catch (error) {
            console.error('Error fetching ticket types', error);
            toast.error('Error fetching ticket types');
        }
    };

    const fetchEmployees = async (query) => {
        try {
            const response = await axios.get('/employees/fetch_employee', { params: { official_email: query || '' } });
            const employeesData = response.data;

            const employeeOptions = employeesData.map(employee => ({
                value: employee.official_email,
                label: `${employee.name} (${employee.official_email})`,
            }));

            setEmployees(employeeOptions);
        } catch (error) {
            console.error('Error fetching employees', error);
            toast.error('Error fetching employees');
        }
    };

    const handleEmployeeSearch = (inputValue) => {
        if (inputValue && inputValue.trim().length > 0) {
            fetchEmployees(inputValue);
        } else {
            setEmployees([]);
        }
    };


    useEffect(() => {
        fetchServiceRequests();
        fetchLocations();

        const subscription = cable.subscriptions.create(
            { channel: 'ServiceRequestsChannel' },
            {
                received: (data) => {
                    setServiceRequests((prevRequests) => [data, ...prevRequests].sort((a, b) => new Date(b.created_at) - new Date(a.created_at)));
                    fetchServiceRequests();
                    toast.success(`${data}`, {
                        duration: 4000,
                        position: 'top-center',
                    });
                },
            }
        );
        return () => subscription.unsubscribe();
    }, []);

    const fetchEmployeeId = async () => {
        try {
            const currentUserEmail = currentUser.email;
            const response = await axios.get('/employees/fetch_employee', {
                params: { official_email: currentUserEmail }
            });
            const employee = response.data[0];

            if (employee) {
                setCompanyName(employee.location.company.name);
            } else {
                toast.error("Current Employee record not found.");
            }
        } catch (error) {
            console.error('Error fetching employee details:', error);
            toast.error("Error fetching employee details.");
        }
    };

    useEffect(() => {
        fetchEmployeeId();
    }, []);

    const handleCcEmailChange = (selectedOption) => {
        setCcEmail(selectedOption);
    };

    const handleOtherEmployeeChange = (selectedOption) => {
        setSelectedEmployee(selectedOption);
    };

    const handleInputChange = (inputValue) => {
        if (inputValue.includes('@') && inputValue.includes('.')) {
            // Add entered email as an option if it looks like an email
            setEmployees([{ value: inputValue, label: inputValue }]);
        } else {
            fetchEmployees(inputValue); // fetch existing employees by query
        }
    };

    const handleLocationChange = (selectedOption) => {
        setSelectedLocation(selectedOption);
        setDepartments([]);
        setMainTypes([]);
        setTicketTypes([]);
        setEmployees([]);
        setSelectedDepartment(null);
        setSelectedMainType(null);
        setSelectedTicketType(null);
        setToEmail('');
        if (selectedOption) {
            fetchDepartments(selectedOption.value);
        }
    };

    const handleDepartmentChange = (selectedOption) => {
        setSelectedDepartment(selectedOption);
        setMainTypes([]);
        setTicketTypes([]);
        setSelectedMainType(null);
        setSelectedTicketType(null);
        setToEmail('');
        if (selectedOption) {
            fetchMainTypes(selectedOption.value);
        }
    };

    const handleMainTypeChange = (selectedOption) => {
        setSelectedMainType(selectedOption);
        setTicketTypes([]);
        setSelectedTicketType(null);
        if (selectedOption) {
            fetchTicketTypes(selectedOption.value);
            const selectedMainType = mainTypes.find(mainType => mainType.id === selectedOption.value);
            setToEmail(selectedMainType ? selectedMainType.emails.join(", ") : '');
        } else {
            setToEmail('');
        }
    };

    const handleTicketTypeChange = (selectedOption) => {
        setSelectedTicketType(selectedOption);
    };

    const handleSave = async () => {
        await handleSubmit(false);
    };

    const generateTicketNumber = () => {
        if (!selectedLocation || !selectedDepartment || !selectedMainType || !selectedTicketType) {
            return 'TICKET-INVALID';
        }

        const getInitials = (str) => str ? str.substring(0, 2).toUpperCase() : '';

        const locationCode = getInitials(selectedLocation.label);
        const departmentCode = getInitials(selectedDepartment.label);
        const mainTypeCode = getInitials(selectedMainType.label);
        const date = new Date();
        const dateCode = `${String(date.getDate()).padStart(2, '0')}${String(date.getMonth() + 1).padStart(2, '0')}${String(date.getFullYear()).substring(2)}`;

        const datePrefix = `${locationCode}${departmentCode}${mainTypeCode}${dateCode}`;

        const existingNumbers = serviceRequests
            .filter(req => req.ticket_number.startsWith(datePrefix))
            .map(req => parseInt(req.ticket_number.replace(`${datePrefix}-`, ''), 10))
            .filter(num => !isNaN(num));

        const nextNumber = existingNumbers.length > 0 ? Math.max(...existingNumbers) + 1 : 1;
        const numberPrefix = String(nextNumber).padStart(3, '0');

        return `${datePrefix}-${numberPrefix}`;
    };

    const handleSubmit = async (isSubmit, isUpdatingSubmission = false) => {
        try {
            const formData = new FormData();
            const ccEmailString = ccEmail && ccEmail.length > 0 ? ccEmail.map(option => option.value).join(',') : '';

            if (!isUpdatingSubmission) {
                if (selectedLocation && selectedLocation.value) {
                    formData.append('ticket[location_id]', selectedLocation.value);
                } else {
                    throw new Error("Location is required");
                }

                if (selectedDepartment && selectedDepartment.value) {
                    formData.append('ticket[department_id]', selectedDepartment.value);
                } else {
                    throw new Error("Department is required");
                }

                if (selectedMainType && selectedMainType.value) {
                    formData.append('ticket[main_type_id]', selectedMainType.value);
                } else {
                    throw new Error("Main type is required");
                }

                if (selectedTicketType && selectedTicketType.value) {
                    formData.append('ticket[ticket_type_id]', selectedTicketType.value);
                } else {
                    throw new Error("SR type is required");
                }

                const needByDate = document.getElementById('formGridNeedByDate').value;
                if (needByDate) {
                    formData.append('ticket[need_by_date]', needByDate);
                } else {
                    throw new Error("Need by Date is required");
                }

                formData.append('ticket[on_behalf_of]', selectedEmployee ? true : false);

                if (selectedEmployee && selectedEmployee.value) {
                    formData.append('ticket[other_employee_code]', selectedEmployee.value);
                }

                if (toEmail) {
                    formData.append('ticket[to_email]', toEmail);
                }

                formData.append('ticket[cc_email]', ccEmailString);


                if (request_title) {
                    formData.append('ticket[request_title]', request_title);
                } else {
                    throw new Error("Request title is required");
                }

                if (discussion) {
                    formData.append('ticket[description]', discussion);
                }

                if (selectedEmployee && selectedEmployee.value) {
                    formData.append('ticket[reporter_email]', selectedEmployee.value);
                } else {
                    formData.append('ticket[reporter_email]', currentUser.email);
                }

                attachments.forEach((file, index) => {
                    if (file.file) {
                        formData.append(`ticket[attachments_attributes][${index}][file]`, file.file);
                    }
                    if (file.id) {
                        formData.append(`ticket[attachments_attributes][${index}][id]`, file.id);
                    }
                    if (file._destroy) {
                        formData.append(`ticket[attachments_attributes][${index}][_destroy]`, '1');
                    }
                });
            }

            formData.append('ticket[is_submitted]', isSubmit);

            if (currentUser && currentUser.full_name) {
                formData.append('ticket[reporter]', currentUser.full_name);
            } else {
                throw new Error("Reporter information is missing");
            }

            if (!currentRequest) {
                formData.append('ticket[status]', 'New');
                formData.append('ticket[assignee]', 'Unassigned');
            }

            const url = currentRequest && currentRequest.id
                ? `/service_requests/${currentRequest.id}`
                : '/service_requests';
            const method = currentRequest && currentRequest.id ? 'put' : 'post';

            await toast.promise(
                axios[method](url, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                }),
                {
                    loading: isSubmit ? 'Submitting...' : 'Saving...',
                    success: isSubmit ? 'Service request submitted successfully!' : 'Service request saved successfully!',
                    error: 'Error saving service request',
                },
                { position: 'top-center' }
            );

            setShowModal(false);
            fetchServiceRequests(pagination.currentPage);
        } catch (error) {
            toast.error(`Error saving service request: ${error.message}`);
            console.error('Error saving service request', error);
        }
    };

    const resetFormState = () => {
        setCurrentRequest(null);
        setSelectedLocation(null);
        setSelectedDepartment(null);
        setSelectedMainType(null);
        setSelectedTicketType(null);
        setSelectedEmployee(null);
        setDiscussion('');
        setToEmail('');
        setCcEmail(null);
        setRequestTitle('');
        setNeedByDate('');
        setTicketNumber('');
        setReporter('');
        setProfile('');
        setAttachments([]);
        setEmployees([]);
    };

    const handleNewRequest = () => {
        resetFormState();
        setShowModal(true);
    };

    const handleCloseModal = () => {
        resetFormState();
        setShowModal(false);
    };

    const handleShowModal = async (request = null) => {
        resetFormState();

        if (request) {
            setCurrentRequest(request);
            setSelectedLocation(request.location ? {
                value: request.location.id,
                label: request.location.name
            } : null);

            if (request.location) {
                await fetchDepartments(request.location.id);
            }

            if (request.cc_email) {
                // Assume cc_email is a comma-separated string of emails
                const ccEmails = request.cc_email.split(',').map(email => ({
                    value: email,
                    label: email,
                }));
                setCcEmail(ccEmails);
            }

            if (request.other_employee_code) {
                try {
                    const response = await axios.get('/employees/fetch_employee', {
                        params: { official_email: request.other_employee_code }
                    });
                    const employee = response.data[0];

                    setSelectedEmployee({
                        value: employee.employee_code,
                        label: `${employee.name} (${employee.employee_code})`,
                    });
                } catch (error) {
                    console.error('Error fetching employee details', error);
                    toast.error('Error fetching employee details');
                }
            }

            if (request.reporter_email) {
                try {
                    const response = await axios.get('/employees/fetch_employee', {
                        params: { official_email: request.reporter_email }
                    });
                    const employee = response.data[0];

                    if (employee) {
                        setEmployeeCode(employee.employee_code);
                    } else {
                        setEmployeeCode('N/A');
                        toast.error("Reporter Employee record not found.");
                    }
                } catch (error) {
                    console.error('Error fetching reporter employee code:', error);
                    toast.error("Error fetching reporter employee code.");
                }
            }

            setSelectedDepartment(request.main_type && request.main_type.department ? {
                value: request.main_type.department.id,
                label: request.main_type.department.name
            } : null);

            if (request.main_type && request.main_type.department) {
                await fetchMainTypes(request.main_type.department.id);
            }

            setSelectedMainType(request.main_type ? {
                value: request.main_type.id,
                label: request.main_type.name
            } : null);

            if (request.main_type) {
                await fetchTicketTypes(request.main_type.id);
            }

            setSelectedTicketType(request.ticket_type ? {
                value: request.ticket_type.id,
                label: request.ticket_type.name
            } : null);
            setDiscussion(request.description || '');
            setToEmail(request.to_email || '');
            setReporter(request.reporter || '');
            setProfile(request.profile_image_url || '');
            setTicketNumber(request.ticket_number || '');
            setRequestTitle(request.request_title || '');
            setNeedByDate(request.need_by_date ? request.need_by_date.split('T')[0] : '');
            const selectedCcEmails = request.cc_email ? request.cc_email.split(',').map(email => ({
                value: email,
                label: email,
            })) : null;
            setCcEmail(selectedCcEmails);
            const formattedAttachments = request.attachments ? request.attachments.map(att => ({
                id: att.id,
                name: att.file_file_name,
                file: null
            })) : [];
            setAttachments(formattedAttachments);
        }

        setShowModal(true);
    };

    const handleFileChange = (event, index) => {
        const selectedFile = event.target.files[0];
        if (selectedFile) {
            const updatedFiles = [...attachments];
            updatedFiles[index] = { ...updatedFiles[index], file: selectedFile, name: selectedFile.name };
            setAttachments(updatedFiles);
        }
    };

    const handleAddAttachment = () => {
        setAttachments([...attachments, { id: null, name: '', file: null }]);
    };

    const handleRemoveAttachment = (index) => {
        setAttachments((prevAttachments) => {
            const updatedFiles = [...prevAttachments];
            if (updatedFiles[index].id) {
                updatedFiles[index]._destroy = true;
            } else {
                updatedFiles.splice(index, 1);
            }
            return updatedFiles;
        });
    };

    const handleFetchAttachment = async (attachmentId) => {
        if (currentRequest && currentRequest.id) {
            try {
                const response = await axios.get(`/service_requests/${currentRequest.id}/fetch_attachment/${attachmentId}`);
                setAttachmentUrl(response.data.file_url);
                window.open(response.data.file_url, '_blank');
            } catch (error) {
                toast.error('Error fetching attachment');
                console.error('Error fetching attachment', error);
            }
        }
    };

    const handleSubmitRequest = (request) => {
        setRequestToSubmit(request);
        setShowConfirmationModal(true);
    };

    const handleConfirmSubmit = async () => {
        try {
            const formData = new FormData();
            formData.append('ticket[is_submitted]', true);

            await toast.promise(
                axios.put(`/service_requests/${requestToSubmit.id}`, formData),
                {
                    loading: 'Submitting...',
                    success: 'Service request submitted successfully!',
                    error: 'Error submitting service request',
                },
                { position: 'top-center' }
            );

            setShowConfirmationModal(false);
            setRequestToSubmit(null);
            fetchServiceRequests(pagination.currentPage);
        } catch (error) {
            toast.error(`Error submitting service request: mandatory fields must be filled ${error.message}`);
            console.error('Error submitting service request', error);
        }
    };

    const columns = [
        {
            name: 'SR #.',
            cell: row => <div>{row.ticket_number}</div>,
            sortable: true,
            wrap: true,
        },
        {
            name: 'SR Type',
            cell: row => <div>{row.ticket_type ? row.ticket_type.name : 'N/A'}</div>,
            sortable: true,
            wrap: true,
        },
        {
            name: 'Location',
            cell: row => <div>{row.location ? row.location.name : 'N/A'}</div>,
            sortable: true,
            wrap: true,
        },
        {
            name: 'Request Title',
            cell: row => <div>{row.request_title}</div>,
            sortable: true,
            wrap: true,
        },
        {
            name: 'Requester',
            cell: row => <div>{row.reporter}</div>,
            sortable: true,
            wrap: true,
        },
        {
            name: 'Assignee',
            cell: row => (
                <div>
                    {row.assignees ? row.assignees.split(', ').map((name, index) => (
                        <div key={index}>{name}</div>
                    )) : row.assignee}
                </div>
            ),
            sortable: true,
            wrap: true,
        },
        { name: 'Status', selector: row => row.status, sortable: true },
        { name: 'Created at', selector: row => new Date(row.created_at).toLocaleDateString(), sortable: true },
        {
            name: 'Action',
            cell: (row) => (
                <div>
                    <OverlayTrigger
                        placement="top"
                        overlay={<Tooltip id={`tooltip-top-view-${row.id}`}>View</Tooltip>}
                    >
                        <FontAwesomeIcon
                            icon={faEye}
                            style={{
                                cursor: 'pointer',
                                marginRight: '10px',
                                color: '#008f99',
                                fontSize: '20px',
                            }}
                            onClick={() => handleShowModal(row)}
                        />
                    </OverlayTrigger>
                    {row.is_submitted ? (
                        <OverlayTrigger
                            placement="top"
                            overlay={<Tooltip id={`tooltip-top-submitted-${row.id}`}>Submitted</Tooltip>}
                        >
                            <FontAwesomeIcon
                                icon={faCheck}
                                style={{
                                    cursor: 'pointer',
                                    marginRight: '10px',
                                    color: 'hsl(253deg 61% 55%)',
                                    fontSize: '20px',
                                }}
                            />
                        </OverlayTrigger>
                    ) : (
                        <OverlayTrigger
                            placement="top"
                            overlay={<Tooltip id={`tooltip-top-submit-${row.id}`}>Submit</Tooltip>}
                        >
                            <FontAwesomeIcon
                                icon={faShare}
                                style={{
                                    cursor: 'pointer',
                                    marginRight: '10px',
                                    color: 'hsl(253deg 61% 55%)',
                                    fontSize: '20px',
                                }}
                                onClick={() => handleSubmitRequest(row)}
                            />
                        </OverlayTrigger>
                    )}
                </div>
            ),
            ignoreRowClick: true,
        },
    ];

    const filteredItems = serviceRequests.filter(
        (item) =>
            (item.ticket_number && item.ticket_number.toLowerCase().includes(filterText.toLowerCase())) ||
            (item.ticket_type && item.ticket_type.name && item.ticket_type.name.toLowerCase().includes(filterText.toLowerCase())) ||
            (item.location && item.location.name && item.location.name.toLowerCase().includes(filterText.toLowerCase())) ||
            (item.request_title && item.request_title.toLowerCase().includes(filterText.toLowerCase())) ||
            (item.reporter && item.reporter.toLowerCase().includes(filterText.toLowerCase())) ||
            (item.assignee && item.assignee.toLowerCase().includes(filterText.toLowerCase())) ||
            (item.status && item.status.toLowerCase().includes(filterText.toLowerCase()))
    );

    const handlePageChange = (page) => {
        fetchServiceRequests(page);
    };
    const sanitizeContent = (htmlContent) => {
        return DOMPurify.sanitize(htmlContent, { FORBID_TAGS: ['img'] });
    };
    return (
        <div className="col-lg-12">
            <Toaster position="top-center" reverseOrder={false} />
            <div className="card">
                <div className="card-header d-flex justify-content-between align-items-center">
                    <h4 className="card-title">Service Request</h4>
                    <div>
                        <Button variant="primary custom-height" onClick={handleNewRequest}>
                            New
                        </Button>
                    </div>
                </div>
                <div className="card-body">
                    <InputGroup className="mb-3" style={{ maxWidth: '300px', marginBottom: '20px' }}>
                        <FormControl
                            className="custom-height"
                            placeholder="Search..."
                            aria-label="Search"
                            value={filterText}
                            onChange={(e) => setFilterText(e.target.value)}
                        />
                    </InputGroup>
                    <DataTable
                        columns={columns}
                        data={filteredItems}
                        pagination
                        paginationServer
                        paginationTotalRows={pagination.totalPages * 10}
                        onChangePage={handlePageChange}
                        highlightOnHover
                        responsive
                        striped
                        customStyles={customStyles}
                        noHeader
                    />
                </div>
            </div>

            <Modal show={showModal} onHide={handleCloseModal} size="lg">
                <Modal.Header>
                    <Modal.Title>Service Request</Modal.Title>
                    <Button
                        variant="link"
                        onClick={handleCloseModal}
                        style={{ position: 'absolute', right: '15px', top: '15px' }}
                    >
                        <FontAwesomeIcon icon={faTimes} size="lg" />
                    </Button>
                </Modal.Header>
                <Modal.Body>
                    <EmployeeDetails
                        employee={{
                            photoUrl: profile,
                            company_name: companyName,
                            name: reporter || currentUser.full_name,
                            employeeCode: employeeCode,
                            srNo: ticketNumber,
                            requestStatus: 'New',
                            dateCreated: dateTime,
                        }}
                    />
                    <Form>
                        <Row>
                            <Col>
                                <Form.Group controlId="formGridLocation">
                                    <Form.Label>
                                        Location <span style={{ color: 'red' }}>*</span>
                                    </Form.Label>
                                    <Select
                                        options={locations.map((location) => ({
                                            value: location.id,
                                            label: location.name,
                                        }))}
                                        isClearable
                                        classNamePrefix="custom-height"
                                        onChange={handleLocationChange}
                                        value={selectedLocation}
                                    />
                                </Form.Group>
                            </Col>

                            <Col>
                                <Form.Group controlId="formGridDepartment">
                                    <Form.Label>Department <span style={{ color: 'red' }}>*</span></Form.Label>
                                    <Select
                                        options={departments.map((department) => ({
                                            value: department.id,
                                            label: department.name,
                                        }))}
                                        isClearable
                                        classNamePrefix="custom-height"
                                        onChange={handleDepartmentChange}
                                        isDisabled={!selectedLocation}
                                        value={selectedDepartment}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Group controlId="formGridMainType">
                                    <Form.Label>Sub-Dept <span style={{ color: 'red' }}>*</span></Form.Label>
                                    <Select
                                        options={mainTypes.map((mainType) => ({
                                            value: mainType.id,
                                            label: mainType.name,
                                        }))}
                                        isClearable
                                        classNamePrefix="custom-height"
                                        onChange={handleMainTypeChange}
                                        isDisabled={!selectedDepartment}
                                        value={selectedMainType}
                                    />
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group controlId="formGridTicketType">
                                    <Form.Label>SR Type <span style={{ color: 'red' }}>*</span></Form.Label>
                                    <Select
                                        options={ticketTypes.map((ticketType) => ({
                                            value: ticketType.id,
                                            label: ticketType.name,
                                        }))}
                                        isClearable
                                        classNamePrefix="custom-height"
                                        onChange={handleTicketTypeChange}
                                        isDisabled={!selectedMainType}
                                        value={selectedTicketType}
                                        required
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Group controlId="formGridNeedByDate">
                                    <Form.Label>Need By Date <span style={{ color: 'red' }}>*</span></Form.Label>
                                    <Form.Control
                                        type="date"
                                        className="custom-height"
                                        value={needByDate}
                                        onChange={(e) => setNeedByDate(e.target.value)}
                                        min={new Date().toISOString().split('T')[0]}
                                        required
                                    />
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group
                                    controlId="formGridOnBehalfOf"
                                    style={{ display: 'flex', alignItems: 'center', marginTop: '28px' }}
                                >
                                    <Form.Check
                                        type="checkbox"
                                        label="On behalf of"
                                        onChange={() => setSelectedEmployee(!selectedEmployee)}
                                        className="custom-height"
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                        {selectedEmployee && (
                            <Row>
                                <Col>
                                    <Form.Group controlId="formGridOtherEmployee">
                                        <Form.Label>Other Employee</Form.Label>
                                        <CreatableSelect
                                            options={employees}
                                            isClearable
                                            classNamePrefix="custom-height"
                                            onChange={handleOtherEmployeeChange}
                                            onInputChange={handleInputChange}
                                            value={selectedEmployee}
                                            placeholder="Search and select or add employee email"
                                        />
                                    </Form.Group>
                                </Col>
                            </Row>
                        )}
                        <Form.Group controlId="formGridTo">
                            <Form.Label>To</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="To"
                                className="custom-height"
                                value={toEmail || ''}
                                readOnly
                            />
                        </Form.Group>
                        <Form.Group controlId="formGridCC">
                            <Form.Label>CC</Form.Label>
                            <Select
                                options={employees}
                                value={ccEmail}
                                onChange={handleCcEmailChange}
                                onInputChange={(value) => handleEmployeeSearch(value)}
                                isClearable
                                isMulti
                                placeholder="Search and select CC"
                            />
                        </Form.Group>
                        <Form.Group controlId="formGridRequestTitle">
                            <Form.Label>Request Title <span style={{ color: 'red' }}>*</span></Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Request Title"
                                className="custom-height"
                                value={request_title}
                                onChange={(e) => setRequestTitle(e.target.value)}
                            />
                        </Form.Group>
                        <Form.Group controlId="formGridDiscussion">
                            <Form.Label className={'d-flex justify-content-center align-items-center mt-2 label-color'}>
                                Description
                            </Form.Label>
                            <ReactQuill
                                value={sanitizeContent(discussion)}
                                onChange={(value) => setDiscussion(value)}
                                theme="snow"
                                style={{ height: '150px' }}
                            />
                        </Form.Group>
                        <Form.Label
                            className={'d-flex justify-content-center align-items-center label-color'}
                            style={{ marginTop: '50px' }}
                        >
                            Attachments (Doc, JPEG, PNG, PDF, mp4, mkv, avi, mov)
                        </Form.Label>
                        <Form.Group controlId="formGridAttachment" className={'col-md-6'}>
                            {attachments.map(
                                (file, index) =>
                                    !file._destroy && (
                                        <div key={index} className="d-flex align-items-center mb-2">
                                            <div className="custom-file" style={{ flexGrow: 1 }}>
                                                <input
                                                    type="file"
                                                    className="custom-file-input custom-height"
                                                    id={`validatedCustomFileBack${index}`}
                                                    name="avatar"
                                                    accept=".jpg, .jpeg, .png, .webp,.pdf, .doc, .docx, .mp4, .mkv, .avi, .mov"
                                                    onChange={(e) => handleFileChange(e, index)}
                                                />
                                                <label
                                                    className="custom-file-label custom-height"
                                                    htmlFor={`validatedCustomFileBack${index}`}
                                                >
                                                    {file && file.name ? file.name : 'Choose file...'}
                                                </label>
                                            </div>
                                            <OverlayTrigger
                                                placement="top"
                                                overlay={<Tooltip id={`tooltip-view-attachment`}>View Attachment</Tooltip>}
                                            >
                                                <Button
                                                    variant="info"
                                                    size="sm"
                                                    onClick={() => handleFetchAttachment(file.id)}
                                                    className="ml-2 custom-height"
                                                >
                                                    <FontAwesomeIcon icon={faEye} />
                                                </Button>
                                            </OverlayTrigger>
                                            <Button
                                                variant="danger"
                                                size="sm"
                                                onClick={() => handleRemoveAttachment(index)}
                                                className="ml-2 custom-height"
                                            >
                                                <FontAwesomeIcon icon={faTrash} />
                                            </Button>
                                        </div>
                                    )
                            )}
                        </Form.Group>
                        <Button
                            variant="success"
                            size="sm"
                            onClick={handleAddAttachment}
                            className="custom-height d-flex align-items-center"
                        >
                            <FontAwesomeIcon icon={faPlus} />
                        </Button>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary custom-height" onClick={handleCloseModal}>
                        Close
                    </Button>
                    <Button
                        variant="primary custom-height"
                        onClick={handleSave}
                        disabled={currentRequest && currentRequest.is_submitted}
                        style={{
                            cursor: currentRequest && currentRequest.is_submitted ? 'not-allowed' : 'pointer',
                        }}
                    >
                        Save
                    </Button>
                    <Button
                        variant="success custom-height"
                        onClick={() => handleSubmit(true)}
                        disabled={currentRequest && currentRequest.is_submitted}
                        style={{
                            cursor: currentRequest && currentRequest.is_submitted ? 'not-allowed' : 'pointer',
                        }}
                    >
                        Submit
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={showConfirmationModal} onHide={() => setShowConfirmationModal(false)}>
                <Modal.Header style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Modal.Title>Confirm Submission</Modal.Title>
                    <Button
                        variant="link"
                        onClick={() => setShowConfirmationModal(false)}
                        style={{ color: 'black', padding: '0' }}
                    >
                        <FontAwesomeIcon icon={faTimes} />
                    </Button>
                </Modal.Header>
                <Modal.Body>Are you sure you want to submit this request?</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowConfirmationModal(false)}>
                        Cancel
                    </Button>
                    <Button variant="primary" onClick={handleConfirmSubmit}>
                        Yes, Submit
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default ServiceRequestList;
