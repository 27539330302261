import React, {useState, useEffect, forwardRef, useImperativeHandle, useCallback} from 'react';
import { Modal, Button, Form, Table } from 'react-bootstrap';
import Select from 'react-select';
import ReactQuill from 'react-quill';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faPlus, faTimes } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import { toast } from 'react-hot-toast';
import TaskView from "./TaskView";
import DOMPurify from "dompurify";

const AssignmentFormModal = forwardRef(({ show, handleClose, ticket, onTaskUpdated, avatar }, ref) => {
    const [showTeam, setShowTeam] = useState(false);
    const [description, setDescription] = useState('');
    const [title, setTitle] = useState('');
    const [attachments, setAttachments] = useState([]);
    const [employees, setEmployees] = useState([]);
    const [selectedEmployees, setSelectedEmployees] = useState([]);
    const [assignmentDates, setAssignmentDates] = useState({ start_date: '', end_date: '' });
    const [needByDate, setNeedByDate] = useState('');
    const [srTypes, setSrTypes] = useState([]);
    const [selectedSrType, setSelectedSrType] = useState(null);
    const [searchQuery, setSearchQuery] = useState('');
    const [locations, setLocations] = useState([]);
    const [selectedLocation, setSelectedLocation] = useState(null);
    const [loading, setLoading] = useState(false);



    const fetchLocations = useCallback(async () => {
        setLoading(true);
        try {
            const response = await axios.get("/locations/fetch_location");
            setLocations(response.data);
        } catch (error) {
            console.error("Error fetching locations:", error);
        } finally {
            setLoading(false);
        }
    }, []);

    useEffect(() => {
        fetchLocations();
    }, [fetchLocations]);

    const formatDate = (dateString) => {
        const options = { day: 'numeric', month: 'long', year: 'numeric' };
        const date = new Date(dateString);
        return date.toLocaleDateString('en-GB', options);
    };

    const handleStartDateChange = (e) => {
        const newStartDate = e.target.value;
        if (!assignmentDates.end_date || newStartDate <= assignmentDates.end_date) {
            setAssignmentDates({ ...assignmentDates, start_date: newStartDate });
        }
    };

    const handleEndDateChange = (e) => {
        const newEndDate = e.target.value;
        if (!assignmentDates.start_date || newEndDate >= assignmentDates.start_date) {
            setAssignmentDates({ ...assignmentDates, end_date: newEndDate });
        }
    };

    const handleNeedByDateChange = (e) => {
        const newNeedByDate = e.target.value;
        setNeedByDate(newNeedByDate);
        setAssignmentDates({ ...assignmentDates, end_date: newNeedByDate });
    };
    useEffect(() => {
        if (needByDate) {
            setAssignmentDates((dates) => ({
                ...dates,
                end_date: needByDate,
            }));
        }
    }, [needByDate]);
    const resetFields = () => {
        setDescription('');
        setTitle('');
        setAttachments([]);
        setEmployees([]);
        setSelectedEmployees([]);
        setAssignmentDates({ start_date: '', end_date: '' });
        setNeedByDate('');
        setSrTypes([]);
        setSelectedSrType(null);
        setSearchQuery('');
        setShowTeam(false);
    };

    useImperativeHandle(ref, () => ({
        reset: resetFields,
    }));

    useEffect(() => {
        if (ticket) {
            setDescription(ticket.description || '');
            setTitle(ticket.request_title || '');
            setNeedByDate(ticket.need_by_date || '');
            setAttachments(ticket.attachments || []);
            fetchSrTypes(ticket.main_type_id);

            setAssignmentDates((dates) => ({
                ...dates,
                start_date: ticket.created_at ? ticket.created_at.split('T')[0] : '',
                end_date: ticket.need_by_date || ''
            }));
        }
    }, [ticket]);

    useEffect(() => {
        if (showTeam) {
            fetchEmployees();
        }
    }, [showTeam]);

    const fetchSrTypes = async (mainTypeId) => {
        try {
            const response = await axios.get('/ticket_types/fetch_ticket_types', { params: { main_type_id: mainTypeId } });
            const srTypes = response.data.map(type => ({ value: type.id, label: type.name }));
            setSrTypes(srTypes);

            let defaultSrType = null;
            if (ticket && ticket.ticket_type) {
                defaultSrType = srTypes.find(type => type.value === ticket.ticket_type.id);
            }
            setSelectedSrType(defaultSrType);
        } catch (error) {
            toast.error("Error fetching SR types");
        }
    };

    const fetchEmployees = async () => {
        try {
            const response = await axios.get(`/employees/fetch_employee`, {
                params: {
                    department_id: ticket.main_type.department_id,
                    location_id: ticket.location_id,
                },
            });
            setEmployees(response.data);
        } catch (error) {
            console.error("Error fetching employees:", error);
        }
    };

    const handleToggleTeam = () => {
        setShowTeam(!showTeam);
    };

    const handleChangeDescription = (value) => {
        setDescription(value);
    };

    const handleAddAttachment = (event) => {
        const files = Array.from(event.target.files);
        setAttachments([...attachments, ...files]);
    };

    const handleViewAttachment = async (attachmentId) => {
        try {
            const response = await axios.get(`/service_requests/${ticket.id}/fetch_attachment/${attachmentId}`);
            const fileUrl = response.data.file_url;
            const newWindow = window.open(fileUrl, '_blank');
            if (newWindow) {
                newWindow.opener = null;
            } else {
                alert('Please allow popups for this website');
            }
        } catch (error) {
            console.error("Error fetching attachment:", error);
        }
    };

    const handleSubmit = async () => {
        if (!assignmentDates.start_date || !assignmentDates.end_date) {
            toast.error('Please select both start and end dates.');
            return;
        }
        if (!needByDate) {
            toast.error('Need By Date is required.');
            return;
        }

        if (!selectedSrType) {
            toast.error('SR Type is required.');
            return;
        }
        if (selectedEmployees.length === 0) {
            toast.error('Please select at least one team member.');
            return;
        }

        try {
            const requestAssignments = selectedEmployees.map(employee => ({
                employee_id: employee.id,
                start_date: assignmentDates.start_date,
                end_date: assignmentDates.end_date,
                request_title: title,
            }));
            const formData = new FormData();
            formData.append('request_assignments', JSON.stringify(requestAssignments));
            formData.append('ticket_type_id', selectedSrType ? selectedSrType.value : null);
            formData.append('need_by_date', needByDate);
            formData.append('reporter_location', selectedLocation ? selectedLocation.label : '');

            attachments.forEach(file => {
                if (file instanceof File) {
                    formData.append('attachments[]', file);
                } else {
                    console.warn('Non-file object detected in attachments', file);
                }
            });

            await axios.post(`/service_requests/${ticket.id}/create_request_assignments`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });

            toast.success('Request assignments created successfully');
            resetFields();
            handleClose();
            onTaskUpdated();
        } catch (error) {
            console.error("Error creating request assignments:", error);
            toast.error('Error creating request assignments');
        }
    };

    const inputStyle = { height: '30px', fontSize: '14px' };

    const getValue = (obj, key) => (obj && obj[key]) ? obj[key] : '';

    const handleSearchQueryChange = (e) => {
        setSearchQuery(e.target.value);
    };

    const filteredEmployees = employees.filter(employee =>
        (employee.name && employee.name.toLowerCase().includes(searchQuery.toLowerCase())) ||
        (employee.employee_code && employee.employee_code.toLowerCase().includes(searchQuery.toLowerCase()))
    );

    const sanitizeContent = (htmlContent) => {
        return DOMPurify.sanitize(htmlContent, { FORBID_TAGS: ['img'] });
    };

    return (
        <Modal show={show} onHide={handleClose} size="lg">
            <Modal.Header>
                <Modal.Title>Create Task</Modal.Title>
                <FontAwesomeIcon icon={faTimes} onClick={handleClose} style={{ cursor: 'pointer', marginLeft: 'auto' }} />
            </Modal.Header>
            <Modal.Body>
                <TaskView
                    task={{
                        companyName: ticket && ticket.location && ticket.location.company ? ticket.location.company.name : '',
                        locationName: ticket && ticket.location && ticket.location.name ? ticket.location.name : '',
                        srNo: getValue(ticket, 'ticket_number'),
                        department: ticket && ticket.main_type.department ? ticket.main_type.department.name : '',
                        subDept: ticket && ticket.main_type ? ticket.main_type.name : '',
                        srType: selectedSrType ? selectedSrType.label : getValue(ticket && ticket.ticket_type, 'name'),
                        timeCreated: ticket ? new Date(ticket.created_at).toLocaleString() : '',
                        needDate: needByDate,
                    }}
                />
                <Form>
                    <div className="form-group">
                        <label>Request Title</label>
                        <input
                            type="text"
                            className="form-control"
                            placeholder="Request Title"
                            style={inputStyle}
                            value={title}
                            onChange={(e) => setTitle(e.target.value)}
                            style={{ backgroundColor: "#f1f1f1", ...inputStyle }}
                            disabled
                        />
                    </div>
                    <div className="form-group row">
                        <div className="col-md-6">
                            <Form.Group>
                                <Form.Label>Requester Location</Form.Label>
                                <Select
                                    options={locations.map(location => ({
                                        value: location.id,
                                        label: location.name
                                    }))}
                                    value={selectedLocation}
                                    onChange={setSelectedLocation}
                                    placeholder="Select Location"
                                    styles={{
                                        menuPortal: base => ({ ...base, zIndex: 9999 }),
                                        control: base => ({ ...base, minHeight: '38px' })
                                    }}
                                    menuPortalTarget={document.body}
                                />
                            </Form.Group>
                        </div>
                        <div className="col-md-6">
                            <label>SR Type <span style={{ color: 'red' }}>*</span></label>
                            <Select
                                options={srTypes}
                                value={selectedSrType}
                                onChange={setSelectedSrType}
                                placeholder="Select SR Type"
                                required
                            />
                        </div>
                        <div className="col-md-6">
                            <label>Need By Date <span style={{ color: 'red' }}>*</span></label>
                            <input
                                type="date"
                                className="form-control"
                                value={needByDate}
                                onChange={handleNeedByDateChange}
                                style={{ ...inputStyle }}
                                min={new Date().toISOString().split('T')[0]}
                                required
                            />
                        </div>
                    </div>

                    <div className="form-group">
                        <label className={'d-flex justify-content-center align-items-center label-color'}>Description</label>
                        <ReactQuill
                            value={sanitizeContent(description)}
                            onChange={handleChangeDescription}
                            theme="snow"
                            style={{ height: '150px' }}
                        />
                    </div>
                    <div className="form-group">
                        <label className={'d-flex justify-content-center align-items-center label-color'}
                               style={{ marginTop: '3rem' }}>Attachments (Doc, JPEG, PNG, PDF) </label>
                        <div style={{ border: '1px solid #ccc', padding: '10px', marginBottom: '10px' }}>
                            {attachments.length > 0 ? (
                                attachments.map((file, index) => (
                                    <div
                                        key={index}
                                        style={{
                                            marginBottom: '10px',
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            alignItems: 'center'
                                        }}
                                    >
                                        <span>{file.file_file_name || file.name}</span>
                                        <div>
                                            <FontAwesomeIcon
                                                icon={faEye}
                                                onClick={() => handleViewAttachment(file.id)}
                                                style={{ cursor: 'pointer', marginRight: '10px' }}
                                            />
                                        </div>
                                    </div>
                                ))
                            ) : (
                                <p>No attachments</p>
                            )}
                        </div>
                        <Button variant="success" size="sm" onClick={() => document.getElementById('fileInput').click()}
                                style={{ cursor: 'pointer', marginRight: '10px' }}
                                className="custom-height d-flex align-items-center">
                            <FontAwesomeIcon icon={faPlus} />
                        </Button>
                        <input
                            type="file"
                            id="fileInput"
                            style={{ display: 'none' }}
                            multiple
                            onChange={handleAddAttachment}
                        />
                    </div>
                    <div className="form-group">
                        <label className={'d-flex justify-content-center align-items-center label-color'}>Task Assignment</label>
                        <div className="form-row">
                            <div className="col">
                                <label>Date From <span style={{ color: 'red' }}>*</span></label>
                                <input
                                    type="date"
                                    className="form-control"
                                    style={inputStyle}
                                    value={assignmentDates.start_date}
                                    onChange={handleStartDateChange}
                                    min={new Date().toISOString().split('T')[0]}
                                    disabled
                                />
                            </div>
                            <div className="col">
                                <label>Date To <span style={{ color: 'red' }}>*</span></label>
                                <input
                                    type="date"
                                    className="form-control"
                                    style={inputStyle}
                                    value={assignmentDates.end_date}
                                    onChange={handleEndDateChange}
                                    min={new Date().toISOString().split('T')[0]}
                                />
                            </div>
                        </div>
                    </div>
                    <Button variant="primary" onClick={handleToggleTeam} style={{ marginBottom: '10px' }}>
                        {showTeam ? 'Hide Team' : 'Show Team'}
                    </Button>
                    <div className="form-group">
                        {showTeam && (
                            <React.Fragment>
                                <label>Team Assignment</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Search"
                                    value={searchQuery}
                                    onChange={handleSearchQueryChange}
                                    style={{ width: '200px', float: 'right', height: '30px' }}
                                />
                                <Table striped bordered hover style={{ marginTop: '10px' }}>
                                    <thead>
                                    <tr>
                                        <th>Image</th>
                                        <th>EIN</th>
                                        <th>Name</th>
                                        <th>Select</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {filteredEmployees.map((employee) => (
                                        <tr key={employee.id}>
                                            <td><img src={employee.profile_image_url} alt="Avatar"
                                                     style={{ width: '50px', height: '50px' }} /></td>
                                            <td>{employee.employee_code}</td>
                                            <td>{employee.name}</td>
                                            <td>
                                                <input
                                                    type="checkbox"
                                                    onChange={(e) => {
                                                        if (e.target.checked) {
                                                            setSelectedEmployees([...selectedEmployees, employee]);
                                                        } else {
                                                            setSelectedEmployees(selectedEmployees.filter(e => e.id !== employee.id));
                                                        }
                                                    }}
                                                />
                                            </td>
                                        </tr>
                                    ))}
                                    </tbody>
                                </Table>
                            </React.Fragment>
                        )}
                    </div>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" onClick={handleSubmit}>
                    Create Task
                </Button>
                <Button variant="secondary" onClick={handleClose}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    );
});

export default AssignmentFormModal;
