import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from "react-router-dom";
import axios from 'axios';
import { toast } from 'react-hot-toast';
import logo1 from '../images/logo.gif';
import user1 from '../images/user/02.jpg';

const TopNavbar = ({ currentUser, avatarUrl }) => {
    const [approvals, setApprovals] = useState([]);
    const navigate = useNavigate();

    const fetchApprovals = async (email) => {
        try {
            const response = await axios.get(`/approvals/pending_approvals?to_email=${email}`);
            setApprovals(response.data);
        } catch (error) {
            toast.error('Error fetching approval notifications.');
            console.error('Error fetching approvals:', error.message, error.response);
        }
    };

    useEffect(() => {
        if (currentUser && currentUser.email) {
            fetchApprovals(currentUser.email);
        }

        // Event handler for approvals update
        const handleApprovalsUpdated = () => {
            console.log('Approvals updated event received');
            if (currentUser && currentUser.email) {
                fetchApprovals(currentUser.email);
            }
        };

        // Add event listener for 'approvalsUpdated' event
        window.addEventListener('approvalsUpdated', handleApprovalsUpdated);

        // Cleanup event listener on component unmount
        return () => {
            window.removeEventListener('approvalsUpdated', handleApprovalsUpdated);
        };
    }, [currentUser]);

    const handleNotificationClick = (ticketId) => {
        navigate(`/service_requests/${ticketId}/details`, { state: { fromNotification: true } });
    };

    return (
        <div className="iq-top-navbar">
            <div className="iq-navbar-custom">
                <div className="iq-sidebar-logo">
                    <div className="top-logo">
                        <a href="/" className="logo">
                            <div className="iq-light-logo">
                                <img src={logo1} className="img-fluid" alt=""/>
                            </div>
                            <div className="iq-dark-logo">
                                <img src={logo1} className="img-fluid" alt=""/>
                            </div>
                            <span>Sapphire</span>
                        </a>
                    </div>
                </div>
                <nav className="navbar navbar-expand-lg navbar-light p-0">
                    <div className="navbar-left">
                        <ul id="topbar-data-icon" className="d-flex p-0 topbar-menu-icon">
                            <li className="nav-item">
                                <Link to='/dashboard' className="nav-link font-weight-bold search-box-toggle">
                                    <i className="ri-home-4-line"></i>
                                </Link>
                            </li>
                            <li>
                                <Link to='/pms' className="nav-link router-link-exact-active router-link-active">
                                    <i className="ri-chat-check-line"></i>
                                </Link>
                            </li>
                            <li>
                                <a href="/activity" className="nav-link">
                                    <i className="ri-list-check-2"></i>
                                </a>
                            </li>
                            <li>
                                <a href="#" className="nav-link">
                                    <i className="ri-file-list-line"></i>
                                </a>
                            </li>
                            <li>
                                <a href="/user-profile" className="nav-link">
                                    <i className="ri-question-answer-line"></i>
                                </a>
                            </li>
                        </ul>
                        <div className="iq-search-bar d-none d-md-block">
                            <form action="#" className="searchbox">
                                <input type="text" className="text search-input" placeholder="Type here to search..."/>
                                <a className="search-link" href="#">
                                    <i className="ri-search-line"></i>
                                </a>
                                <div className="searchbox-datalink">
                                    <h6 className="pl-3 pt-3 pb-3">Pages</h6>
                                    <ul className="m-0 pl-3 pr-3 pb-3">
                                        <li className="iq-bg-primary-hover rounded">
                                            <a href="/dashboard"
                                               className="nav-link router-link-exact-active router-link-active pr-2">
                                                <i className="ri-home-4-line pr-2"></i>Dashboard
                                            </a>
                                        </li>
                                        <li className="iq-bg-primary-hover rounded">
                                            <a href="/activity" className="nav-link">
                                                <i className="ri-message-line pr-2"></i>Activity
                                            </a>
                                        </li>
                                        <li className="iq-bg-primary-hover rounded">
                                            <a href="#" className="nav-link">
                                                <i className="ri-calendar-2-line pr-2"></i>Calendar
                                            </a>
                                        </li>
                                        <li className="iq-bg-primary-hover rounded">
                                            <a href="#" className="nav-link">
                                                <i className="ri-profile-line pr-2"></i>Profile
                                            </a>
                                        </li>
                                        <li className="iq-bg-primary-hover rounded">
                                            <a href="#" className="nav-link">
                                                <i className="ri-chat-check-line pr-2"></i>Todo
                                            </a>
                                        </li>
                                        <li className="iq-bg-primary-hover rounded">
                                            <a href="#" className="nav-link">
                                                <i className="ri-mail-line pr-2"></i>Email
                                            </a>
                                        </li>
                                        <li className="iq-bg-primary-hover rounded">
                                            <a href="#" className="nav-link">
                                                <i className="ri-message-line pr-2"></i>Product Listing
                                            </a>
                                        </li>
                                        <li className="iq-bg-primary-hover rounded">
                                            <a href="#" className="nav-link">
                                                <i className="ri-file-list-line pr-2"></i>Product Details
                                            </a>
                                        </li>
                                        <li className="iq-bg-primary-hover rounded">
                                            <a href="#" className="nav-link">
                                                <i className="ri-compasses-line pr-2"></i>Faq
                                            </a>
                                        </li>
                                        <li className="iq-bg-primary-hover rounded">
                                            <a href="#" className="nav-link">
                                                <i className="ri-clockwise-line pr-2"></i>Form-wizard
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </form>
                        </div>
                    </div>
                    <button className="navbar-toggler" type="button" data-toggle="collapse"
                            data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                            aria-label="Toggle navigation">
                        <i className="ri-menu-3-line"></i>
                    </button>
                    <div className="iq-menu-bt align-self-center">
                        <div className="wrapper-menu">
                            <div className="main-circle">
                                <i className="ri-arrow-left-s-line"></i>
                            </div>
                            <div className="hover-circle">
                                <i className="ri-arrow-right-s-line"></i>
                            </div>
                        </div>
                    </div>
                    <div className="collapse navbar-collapse" id="navbarSupportedContent">
                        <ul className="navbar-nav ml-auto navbar-list">
                            <li className="nav-item dropdown">
                                <a href="#" className="search-toggle iq-waves-effect">
                                    <div id="lottie-mail">
                                        <svg width="100%" height="100%" viewBox="0 0 24 24" fill="none"
                                             xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M9.35419 21C10.0593 21.6224 10.9856 22 12 22C13.0145 22 13.9407 21.6224 14.6458 21M18 8C18 6.4087 17.3679 4.88258 16.2427 3.75736C15.1174 2.63214 13.5913 2 12 2C10.4087 2 8.8826 2.63214 7.75738 3.75736C6.63216 4.88258 6.00002 6.4087 6.00002 8C6.00002 11.0902 5.22049 13.206 4.34968 14.6054C3.61515 15.7859 3.24788 16.3761 3.26134 16.5408C3.27626 16.7231 3.31488 16.7926 3.46179 16.9016C3.59448 17 4.19261 17 5.38887 17H18.6112C19.8074 17 20.4056 17 20.5382 16.9016C20.6852 16.7926 20.7238 16.7231 20.7387 16.5408C20.7522 16.3761 20.3849 15.7859 19.6504 14.6054C18.7795 13.206 18 11.0902 18 8Z"
                                                stroke="currentColor"
                                                strokeWidth="2"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                            />
                                        </svg>
                                    </div>
                                    <span className={`bg-primary ${approvals.length > 0 ? 'count-mail' : ''}`}></span>
                                </a>
                                <div className="iq-sub-dropdown">
                                    <div className="iq-card shadow-none m-0">
                                        <div className="iq-card-body p-0 ">
                                            <div className="bg-primary p-3">
                                                <h5 className="mb-0 text-white">All Notifications<small className="badge badge-light float-right pt-1">{approvals.length}</small>
                                                </h5>
                                            </div>
                                            {approvals.length > 0 ? (
                                                approvals.map((approval) => (
                                                    <a key={approval.id} onClick={() => handleNotificationClick(approval.ticket_id)} className="iq-sub-card" style={{ cursor: 'pointer' }}>
                                                        <div className="media align-items-center">
                                                            <div className="media-body ml-3">
                                                                <h6 className="mb-1 ">{approval.ticket.ticket_number}</h6>
                                                                <small className="float-right font-size-12">{new Date(approval.created_at).toLocaleString()}</small>
                                                                <p className="mb-0">{approval.comment}</p>
                                                            </div>
                                                        </div>
                                                    </a>
                                                ))
                                            ) : (
                                                <p className="text-center p-3">No new notifications.</p>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                    <ul className="navbar-list">
                        <li>
                            <a href="#"
                               className="search-toggle iq-waves-effect d-flex align-items-center bg-primary rounded">
                                <img src={avatarUrl || user1} className="img-fluid rounded mr-3" alt="user"/>
                                <div className="caption">
                                    <h6 className="mb-0 line-height text-white">{currentUser.full_name}</h6>
                                    <span className="font-size-12 text-white">Available</span>
                                </div>
                            </a>
                            <div className="iq-sub-dropdown iq-user-dropdown">
                                <div className="iq-card shadow-none m-0">
                                    <div className="iq-card-body p-0 ">
                                        <div className="bg-primary p-3">
                                            <h5 className="mb-0 text-white line-height">Hello {currentUser.full_name}</h5>
                                            <span className="text-white font-size-12">Available</span>
                                        </div>
                                        <Link to='/user_profile' className="iq-sub-card iq-bg-primary-hover">
                                            <div className="media align-items-center">
                                                <div className="rounded iq-card-icon iq-bg-primary">
                                                    <i className="ri-file-user-line"></i>
                                                </div>
                                                <div className="media-body ml-3">
                                                    <h6 className="mb-0">My Profile</h6>
                                                    <p className="mb-0 font-size-12">View personal profile details.</p>
                                                </div>
                                            </div>
                                        </Link>
                                        <div className="d-inline-block w-100 text-center p-3">
                                            <a className="btn btn-primary dark-btn-primary" href="/logout"
                                               role="button">
                                                Sign out<i className="ri-login-box-line ml-2"></i>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </li>
                    </ul>
                </nav>
            </div>
        </div>
    );
};

export default TopNavbar;
