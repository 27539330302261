import React, { useState, useEffect } from 'react';
import axios from 'axios';
import bg from '../../images/page-img/profile-bg.jpg';
import user from '../../images/user/05.jpg';
import { Toaster, toast } from 'react-hot-toast';

const ProfilePage = ({ currentUser, avatarUrl }) => {
    const [activityStreams, setActivityStreams] = useState([]);
    const [openLinksInNewWindow, setOpenLinksInNewWindow] = useState(currentUser && currentUser.target);
    const [form, setForm] = useState({
        old_password: '',
        password: ''
    });

    useEffect(() => {
        const fetchActivityStreams = async () => {
            try {
                const response = await fetch('/activity_streams', {
                    headers: {
                        'Content-Type': 'application/json',
                        'Accept': 'application/json'
                    }
                });
                if (response.ok) {
                    const data = await response.json();
                    if (data && data.activity_stream) {
                        const filteredStreams = data.activity_stream.filter(
                            stream => stream.user_id === currentUser.id
                        );
                        setActivityStreams(filteredStreams);
                    } else {
                        console.error('Activity stream data not found in response:', data);
                    }
                } else {
                    console.error('Failed to fetch activity streams');
                }
            } catch (error) {
                console.error('Error fetching activity streams:', error);
            }
        };

        fetchActivityStreams();
    }, [currentUser.id]);


    const updatePreference = async (openInNewWindow) => {
        try {
            const response = await fetch('/update_preference', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
                },
                body: JSON.stringify({ open_links_in_new_window: openInNewWindow })
            });
            if (response.ok) {
                console.log('User preference updated successfully');
                window.location.reload();
            } else {
                console.error('Failed to update user preference');
            }
        } catch (error) {
            console.error('Error updating user preference:', error);
        }
    };

    const handleCheckboxChange = (event) => {
        const openInNewWindow = event.target.checked;
        setOpenLinksInNewWindow(openInNewWindow);
        updatePreference(openInNewWindow);
    };

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setForm({
            ...form,
            [name]: value
        });
    };

    const validatePassword = (password) => {
        const passwordPattern = /^(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*]).{8,}$/;
        return passwordPattern.test(password);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        const { old_password, password } = form;

        if (!validatePassword(password)) {
            toast.error('Password must be at least 8 characters long, start with a capital letter, and include a number and a special character.');
            return;
        }

        try {
            const csrfToken = document.querySelector('meta[name="csrf-token"]').getAttribute('content');
            const id = currentUser ? currentUser.id : null;
            const response = await axios.post('/change_password_user', {
                old_password,
                password,
                id
            }, {
                headers: {
                    'X-CSRF-Token': csrfToken
                }
            });

            if (response.data.success) {
                toast.success(response.data.message || 'Password changed successfully');

                window.location.href = `/logout`;

            } else {
                toast.error(response.data.message || 'Failed to change password');
            }
        } catch (error) {
            console.error('Error changing password:', error);
            toast.error('An error occurred while changing the password.');
        }
    };


    return (
        <div>
            <Toaster />
            <div className="row">
                <div className="col-sm-12">
                    <div className="iq-card">
                        <div className="iq-card-body profile-page p-0">
                            <div className="profile-header">
                                <div className="cover-container">
                                    <img src={bg} alt="profile-bg" className="rounded img-fluid" />
                                </div>
                                <div className="profile-info p-4">
                                    <div className="row">
                                        <div className="col-sm-12 col-md-6">
                                            <div className="user-detail pl-5">
                                                <div className="d-flex flex-wrap align-items-center">
                                                    <div className="profile-img pr-4">
                                                        <img
                                                            src={avatarUrl ? avatarUrl : user}
                                                            alt="profile"
                                                            className="avatar-130 img-fluid"
                                                        />
                                                    </div>
                                                    <div className="profile-detail d-flex align-items-center">
                                                        <h3>{currentUser && currentUser.full_name}</h3>
                                                        <p className="m-0 pl-3"> - {currentUser && currentUser.role && currentUser.role.name}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-sm-12 col-md-6">
                                            <ul className="nav nav-pills d-flex align-items-end float-right profile-feed-items p-0 m-0">
                                                <li>
                                                    <a className="nav-link active" data-toggle="pill"
                                                       href="#profile-activity">Activity</a>
                                                </li>
                                                <li>
                                                    <a className="nav-link" data-toggle="pill" href="#change-password">Change
                                                        Password</a>
                                                </li>
                                                <li>
                                                    <a className="nav-link" data-toggle="pill" href="#email-sms">settings
                                                        </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-sm-12">
                    <div className="row">
                        <div className="col-lg-6 profile-center">
                            <div className="tab-content">
                                <div className="tab-pane fade active show" id="profile-activity" role="tabpanel">
                                    <div className="iq-card">
                                        <div className="iq-card-header d-flex justify-content-between">
                                            <div className="iq-header-title">
                                                <h4 className="card-title">Activity timeline</h4>
                                            </div>
                                        </div>

                                        <div className="iq-card-body">
                                            {activityStreams.map((activity, index) => (
                                                <ul className="iq-timeline" key={index}>
                                                    <li>
                                                        <div className="timeline-dots"></div>
                                                        <h6 className="float-left mb-1">{activity.user_name}</h6>
                                                        <small
                                                            className="float-right mt-1">{activity.action_datetime}</small>
                                                        <div className="d-inline-block w-100">
                                                            <p>{activity.action_name}</p>
                                                        </div>
                                                    </li>
                                                </ul>
                                            ))}

                                        </div>

                                    </div>
                                </div>
                                <div className="tab-pane fade" id="change-password" role="tabpanel">
                                    <div className="iq-card">
                                        <div className="iq-card-header d-flex justify-content-between">
                                            <div className="iq-header-title">
                                                <h4 className="card-title">Change Password</h4>
                                            </div>
                                        </div>
                                        <div className="iq-card-body">
                                            <form onSubmit={handleSubmit}>
                                                <div className="form-group">
                                                    <label htmlFor="cPass">Current Password:</label>
                                                    <input
                                                        type="password"
                                                        className="form-control"
                                                        name="old_password"
                                                        id="cPass"
                                                        value={form.old_password}
                                                        onChange={handleInputChange}
                                                    />
                                                </div>
                                                <div className="form-group">
                                                    <label htmlFor="nPass">New Password:</label>
                                                    <input
                                                        type="password"
                                                        className="form-control"
                                                        name="password"
                                                        id="nPass"
                                                        value={form.password}
                                                        onChange={handleInputChange}
                                                    />
                                                </div>
                                                <button type="submit" className="btn btn-primary">Submit</button>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                                <div className="tab-pane fade" id="email-sms" role="tabpanel">
                                    <div className="iq-card">
                                        <div className="iq-card-header d-flex justify-content-between">
                                            <div className="iq-header-title">
                                                <h4 className="card-title">settings</h4>
                                            </div>
                                        </div>
                                        <div className="iq-card-body">
                                            <div className="form-group row align-items-center">
                                                <label className="col-md-4" htmlFor="emailNotification">Open
                                                    page in new window:</label>
                                                <div className="col-md-8 custom-control custom-switch">
                                                    <input
                                                        type="checkbox"
                                                        className="custom-control-input"
                                                        id="emailNotification"
                                                        checked={openLinksInNewWindow}
                                                        onChange={handleCheckboxChange}
                                                    />
                                                    <label className="custom-control-label"
                                                           htmlFor="emailNotification"></label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ProfilePage;
