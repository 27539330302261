import React, { useState, useCallback } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import Select from 'react-select';
import { toast } from 'react-hot-toast';
import axios from 'axios';
import debounce from 'lodash.debounce';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

const ApprovalModal = ({
                           show,
                           onHide,
                           approvalEmail,
                           setApprovalEmail,
                           approvalComment,
                           setApprovalComment,
                           handleAddApproval
                       }) => {
    const [emailOptions, setEmailOptions] = useState([]);
    const [loadingEmails, setLoadingEmails] = useState(false);

    const fetchEmails = async (inputValue) => {
        if (!inputValue || inputValue.length < 2) {
            setEmailOptions([]);
            return;
        }

        try {
            setLoadingEmails(true);
            const response = await axios.get(`/employees/fetch_employee`, {
                params: { official_email: inputValue },
            });
            const fetchedEmails = response.data.map((emp) => ({
                value: emp.official_email,
                label: `${emp.name !== 'External Email' ? emp.name : 'External Email'} (${emp.official_email})`,
            }));
            setEmailOptions(fetchedEmails);
        } catch (error) {
            toast.error('Error fetching email options.');
            console.error(error);
        } finally {
            setLoadingEmails(false);
        }
    };

    const debouncedFetchEmails = useCallback(debounce(fetchEmails, 300), []);

    const handleInputChange = (inputValue, { action }) => {
        if (action === 'input-change') {
            debouncedFetchEmails(inputValue);
        }
        return inputValue;
    };

    const handleEmailChange = (selectedOptions) => {
        setApprovalEmail(selectedOptions || []);
    };

    return (
        <Modal show={show} onHide={onHide}>
            <Modal.Header>
                <Modal.Title>Add Approval</Modal.Title>
                <Button
                    variant="link"
                    onClick={onHide}
                    style={{ color: 'black', padding: '0' }}
                >
                    <FontAwesomeIcon icon={faTimes} />
                </Button>
            </Modal.Header>
            <Modal.Body>
                <Form.Group controlId="approvalEmail">
                    <Form.Label>To Email</Form.Label>
                    <Select
                        value={approvalEmail || []}
                        onChange={handleEmailChange}
                        options={emailOptions}
                        onInputChange={handleInputChange}
                        isLoading={loadingEmails}
                        placeholder="Type to search recipient emails"
                        isMulti
                        noOptionsMessage={() => "Type to search employees"}
                    />
                </Form.Group>
                <Form.Group controlId="approvalComment">
                    <Form.Label>Comment</Form.Label>
                    <Form.Control
                        as="textarea"
                        rows={3}
                        value={approvalComment}
                        onChange={(e) => setApprovalComment(e.target.value)}
                        placeholder="Add a comment..."
                    />
                </Form.Group>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={onHide}>
                    Close
                </Button>
                <Button variant="primary" onClick={handleAddApproval}>
                    Add
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default ApprovalModal;
