import React, {useState, useRef, useEffect} from 'react';
import {Container, Row, Col, Form, Button, Card, Modal, Dropdown} from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
    faPhone, faMobileAlt, faGlobe, faEnvelope,
    faMapMarkerAlt, faPlusCircle, faShareAlt,
    faPalette, faPen, faFileCode, faDownload, faSave, faEye, faEdit, faTrashAlt
} from '@fortawesome/free-solid-svg-icons';
import {
    faFacebook, faLinkedin, faTwitter, faInstagram, faTiktok
} from '@fortawesome/free-brands-svg-icons';
import html2canvas from 'html2canvas';
import axios from 'axios';
import {Toaster, toast} from 'react-hot-toast';
import phone_icon from '../images/icons/phone.png';
import mail_icon from '../images/icons/mail.png';
import mobile_icon from '../images/icons/mobile.png';
import web_icon from '../images/icons/web.png';
import map_icon from '../images/icons/address.png';
import fb from '../images/icons/fb.jpeg';
import instagram_icon from '../images/icons/insta.jpeg';
import linkedin_icon from '../images/icons/lnkdn.jpeg';
import twitter_icon from '../images/icons/twiter.jpeg';
import tiktok_icon from '../images/icons/ticktok.jpeg';
import JSZip from "jszip";
import {saveAs} from 'file-saver';

const SignatureGenerator = () => {
    const [selectedTab, setSelectedTab] = useState('details');
    const [name, setName] = useState('');
    const [title, setTitle] = useState('');
    const [department, setDepartment] = useState('');
    const [company, setCompany] = useState('');
    const [phone, setPhone] = useState('');
    const [mobile, setMobile] = useState('');
    const [website, setWebsite] = useState('');
    const [email, setEmail] = useState('');
    const [address, setAddress] = useState('');
    const [image, setImage] = useState(null);
    const [signatures, setSignatures] = useState([]);
    const [selectedSignature, setSelectedSignature] = useState(null);
    const [bannerImage, setBannerImage] = useState(null);
    const [pngImage, setPngImage] = useState(null);
    const [bannerImageFile, setBannerImageFile] = useState(null);

    const [facebook, setFacebook] = useState('');
    const [instagram, setInstagram] = useState('');
    const [linkedin, setLinkedin] = useState('');
    const [twitter, setTwitter] = useState('');
    const [tiktok, setTiktok] = useState('');
    const [youtube, setYoutube] = useState('');

    const [selectedTemplate, setSelectedTemplate] = useState('sapphireTemplate');
    const previewRef = useRef();

    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [signatureToDelete, setSignatureToDelete] = useState(null);
    const [employeeCode, setEmployeeCode] = useState('');
    const [uploadedPng, setUploadedPng] = useState(null);
    const [companies, setCompanies] = useState([]);
    const [companyFilterDetails, setCompanyFilterDetails] = useState(null);
    const [selectedCompanyIdDetails, setSelectedCompanyIdDetails] = useState(null);
    const [selectedCompanyId, setSelectedCompanyId] = useState(null);

    const [companyFilterSaved, setCompanyFilterSaved] = useState(null);
    const [selectedCompanyIdSaved, setSelectedCompanyIdSaved] = useState(null);

    const [showViewModal, setShowViewModal] = useState(false);
    const [signatureToView, setSignatureToView] = useState(null);
    const [includeBanner, setIncludeBanner] = useState(true);
    const [searchQuery, setSearchQuery] = useState('');
    const [filteredSignatures, setFilteredSignatures] = useState([]);
    const [bannerImageUrl, setBannerImageUrl] = useState(null);
    useEffect(() => {
        setFilteredSignatures(
            signatures.filter((signature) =>
                signature.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
                signature.employee_code.toLowerCase().includes(searchQuery.toLowerCase()) ||
                signature.company.toLowerCase().includes(searchQuery.toLowerCase())
            )
        );
    }, [searchQuery, signatures]);
    useEffect(() => {
        fetchCompanies();
    }, []);

    const downloadScript = (signature) => {
        const scriptUrl = `/signatures/${signature.id}/download_script`;

        // Use fetch API to get the script content
        fetch(scriptUrl, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/octet-stream',
                'Accept': 'application/octet-stream',
            },
            credentials: 'include', // Include cookies if authentication is required
        })
            .then((response) => {
                if (!response.ok) {
                    throw new Error('Failed to download script');
                }
                return response.blob();
            })
            .then((blob) => {
                const url = window.URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.href = url;
                a.download = 'Update-OutlookSignature.ps1';
                document.body.appendChild(a);
                a.click();
                a.remove();
                window.URL.revokeObjectURL(url);
                toast.success('Script downloaded successfully!');
            })
            .catch((error) => {
                console.error('Error downloading script:', error);
                toast.error('Failed to download script.');
            });
    };

    const downloadAllSignatures = () => {
        const zip = new JSZip();
        const currentDomain = window.location.origin;

        // Loop through each signature and create an HTML file
        signatures.forEach((signature) => {
            const fullSignatureImageUrl = `${currentDomain}${signature.signature_image_url}`;

            // Use the same HTML content generation logic as in saveHtmlToFile
            const htmlContent = `
 <html>
                <body style="margin:0; padding:0;">
                    <table width="100%" cellpadding="0" cellspacing="0" border="0" style="max-width: 400px;">
                        <tr>
                            <td>
                                <img src="${fullSignatureImageUrl}" alt="Signature Image" style="display: block; max-width: 100%;" />
                            </td>
                        </tr>
                    </table>
                </body>
            </html>
    `;

            // Add HTML file to the ZIP archive with a unique name based on signature details
            zip.file(`${signature.name}_${signature.employee_code}.htm`, htmlContent);
        });

        // Generate the ZIP file and download it
        zip.generateAsync({type: 'blob'}).then((content) => {
            saveAs(content, 'signatures.zip');
            toast.success('All signature HTML files downloaded successfully!');
        });
    };


    const fetchCompanies = async () => {
        try {
            const response = await axios.get('/companies/fetch_company');
            setCompanies(response.data);
        } catch (error) {
            toast.error('Error fetching companies');
            console.error('Error fetching companies:', error);
        }
    };

    useEffect(() => {
        if (selectedCompanyIdSaved) {
            fetchSignatures(selectedCompanyIdSaved);
        } else {
            setSignatures([]);
        }
    }, [selectedCompanyIdSaved]);

    const fetchSignatures = async (companyId = null) => {
        if (!companyId) {
            setSignatures([]);
            return;
        }
        try {
            const response = await axios.get('/signatures', {
                params: {
                    company_id: companyId
                }
            });
            setSignatures(response.data);
        } catch (error) {
            console.error('Error fetching signatures:', error);
        }
    };

    const handleCompanyFilterChangeDetails = (selectedCompanyName) => {
        const selectedCompany = companies.find(company => company.name === selectedCompanyName);
        setCompanyFilterDetails(selectedCompanyName);
        const companyId = selectedCompany ? selectedCompany.id : null;
        setSelectedCompanyId(companyId);
        setSelectedCompanyIdDetails(companyId);
    };

    const handleCompanyFilterChangeSaved = (selectedCompanyName) => {
        const selectedCompany = companies.find(company => company.name === selectedCompanyName);
        setCompanyFilterSaved(selectedCompanyName);
        setSelectedCompanyIdSaved(selectedCompany ? selectedCompany.id : null);
    };

    const handleBannerImageUpload = (event) => {
        const file = event.target.files[0];
        setBannerImageFile(file);
        const reader = new FileReader();
        reader.onloadend = () => {
            setBannerImage(reader.result);
        };
        reader.readAsDataURL(file);
    };

// Function to update all signatures with the new banner
    const updateBannerForAllSignatures = async () => {
        if (!bannerImageFile) {
            toast.error('Please select a banner image to upload.');
            return;
        }

        const formData = new FormData();
        formData.append('signature[banner]', bannerImageFile);

        try {
            // Upload the banner for all signatures in the database
            const response = await axios.post('/signatures/update_banner_for_all', formData, {
                headers: { 'Content-Type': 'multipart/form-data' },
            });

            if (response.data.success) {
                toast.success('Banner updated successfully for all signatures.');

                // Re-fetch the signatures to get the updated banner_image_url
                await fetchSignatures(selectedCompanyIdSaved);

                // Now update all signatures with the new banner
                await updateAllSignatures();

                toast.success('All signatures updated successfully!');
            } else {
                toast.error('Failed to update banners for all signatures');
                console.error('Server Response:', response.data);
            }
        } catch (error) {
            toast.error('Error updating banners for all signatures');
            console.error('Error updating banners:', error);
        }
    };




    const generateSignatureHtml = (signature, forDownload = false) => {
        const commonStyle = {
            fontFamily: 'Arial, sans-serif',
            fontSize: '14px',
            color: 'black',
            maxWidth: '600px',
            margin: 'auto',
            padding: '20px',
            border: forDownload ? 'none' : '0px solid #ddd',
            borderRadius: '5px',
            boxShadow: forDownload ? 'none' : '0 0 10px rgba(0, 0, 0, 0.1)'
        };
        const commonPStyle = {
            margin: '0',
            color: '#666',
            fontSize: '14px'
        };
        const cStyle = {
            margin: '0',
            color: '#000',
            fontSize: '17px'
        };
        const iconStyle = {marginRight: '5px', marginLeft: '5px', width: '14px'};
        const bannerImageStyle = {width: '380px', height: '103px'};

        const templates = {
            classic: (
                <div style={{
                    fontFamily: 'Arial, sans-serif',
                    color: '#333',
                    padding: '10px',
                    maxWidth: '750px',
                    border: '0px solid #ddd',
                    borderRadius: '8px'
                }}>
                    <table style={{width: '100%'}}>
                        <tbody>
                        <tr>
                            <td style={{width: '80px'}}>
                                {signature.image_url && <img src={signature.image_url} alt="Logo" style={{
                                    width: '130px',
                                    display: 'block',
                                    marginLeft: '-6px',
                                    marginRight: '30px',
                                    padding: '2px',
                                }}/>}
                            </td>
                            <td style={{verticalAlign: 'top'}}>
                                <p style={{
                                    margin: '0',
                                    color: '#000',
                                    fontWeight: 'bold',
                                    fontSize: '18px'
                                }}>{signature.name}</p>
                                <p style={cStyle}>{signature.title}</p>
                                <p style={cStyle}>{signature.department}</p>
                                <p style={{margin: '5px 0', ...commonPStyle}}>
                                    {signature.phone && (
                                        <React.Fragment>
                                            <img src={phone_icon} style={iconStyle}/>
                                            {signature.phone}<br/>
                                        </React.Fragment>
                                    )}
                                    {signature.mobile && (
                                        <React.Fragment>
                                            <img src={mobile_icon} style={iconStyle}/>
                                            {signature.mobile}<br/>
                                        </React.Fragment>
                                    )}
                                    {signature.website && (
                                        <React.Fragment>
                                            <img src={web_icon} style={iconStyle}/>
                                            <a href={signature.website} style={{
                                                textDecoration: 'none',
                                                color: '#1155cc'
                                            }}>{signature.website}</a><br/>
                                        </React.Fragment>
                                    )}
                                    {signature.email && (
                                        <React.Fragment>
                                            <img src={mail_icon} style={iconStyle}/>
                                            <a href={`mailto:${signature.email}`} style={{
                                                textDecoration: 'none',
                                                color: '#1155cc'
                                            }}>{signature.email}</a><br/>
                                        </React.Fragment>
                                    )}
                                    {signature.address && (
                                        <React.Fragment>
                                            <img src={map_icon} style={iconStyle}/>
                                            {signature.address}
                                        </React.Fragment>
                                    )}
                                </p>
                                <div style={{marginTop: '10px', display: 'flex', alignItems: 'center'}}>
                                    {getSocialIcons(signature)}
                                </div>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                    {includeBanner && signature.banner_image_url && <img src={signature.banner_image_url} alt=""
                                                                         style={{
                                                                             width: '102%',
                                                                             borderRadius: '8px',
                                                                             marginLeft: '-6px'
                                                                         }}/>}


                </div>
            ),
            sapphireTemplate: (
                <div style={{
                    fontFamily: 'Arial, sans-serif',
                    color: '#333',
                    padding: '10px',
                    maxWidth: '750px',
                    border: '0px solid #ddd',
                    borderRadius: '8px'
                }}>
                    <table style={{width: '100%', borderCollapse: 'collapse'}}>
                        <tbody>
                        <tr>
                            <td style={{verticalAlign: 'top', paddingRight: '10px', width: '30%'}}>
                                <div style={{
                                    fontSize: '16px',
                                    fontWeight: 'bold',
                                    color: '#2E3192',
                                    marginBottom: '-7px',
                                    marginLeft: '-10px',
                                    marginTop: '0px'
                                }}>{signature.name}</div>
                                <div style={{
                                    fontSize: '13px',
                                    color: '#0066cc',
                                    fontWeight: 'bold',
                                    marginBottom: '2px',
                                    marginLeft: '-10px'
                                }}>{signature.title}</div>
                                <div style={{
                                    fontSize: '13px',
                                    color: '#0066cc',
                                    fontWeight: 'bold',
                                    marginBottom: '2px',
                                    marginLeft: '-10px'
                                }}>{signature.department}</div>
                                <div style={{
                                    fontSize: '13px',
                                    color: '#0066cc',
                                    fontWeight: 'bold',
                                    marginTop: '-5px',
                                    marginLeft: '-10px'
                                }}>{signature.company}</div>
                                {signature.image_url && <img src={signature.image_url} alt="Logo" style={{
                                    width: '130px',
                                    display: 'block',
                                    marginLeft: '0px'
                                }}/>}
                            </td>
                            <td style={{width: '1px', position: 'relative'}}>
                                <div style={{
                                    position: 'absolute',
                                    top: '5px',
                                    bottom: '5px',
                                    width: '1px',
                                    backgroundColor: '#ddd',
                                    left: '0px'
                                }}></div>
                            </td>
                            <td style={{verticalAlign: 'top', paddingLeft: '10px', width: '70%'}}>
                                <div
                                    style={{fontSize: '13px', color: '#0066cc', marginTop: '-2px', marginLeft: '-3px'}}>
                                    {signature.mobile && <div>Mobile: {signature.mobile}</div>}
                                </div>
                                <div
                                    style={{fontSize: '13px', color: '#0066cc', marginTop: '-2px', marginLeft: '-3px'}}>
                                    {signature.phone && <div>Phone: {signature.phone}</div>}
                                </div>
                                <div
                                    style={{fontSize: '13px', color: '#0066cc', marginTop: '-2px', marginLeft: '-3px'}}>
                                    {signature.address && <div>Address: {signature.address}</div>}
                                </div>
                                {includeBanner && signature.banner_image_url && (
                                    <img src={signature.banner_image_url} alt=""
                                         style={{width: '103%', borderRadius: '5px', marginLeft: '-4px'}}/>
                                )}
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            ),


            horizontal: (
                <div id="signatureContent" style={{...commonStyle}} ref={previewRef}>
                    <table style={{width: '100%', verticalAlign: 'top'}}>
                        <tbody>
                        <tr>
                            <td style={{verticalAlign: 'top'}}>
                                <p style={{
                                    margin: '0',
                                    fontSize: '18px',
                                    fontWeight: 'bold',
                                    color: '#333'
                                }}>{signature.name}</p>
                                <p style={commonPStyle}>{signature.company} , {signature.title}</p>
                                <p style={{margin: '5px 0', ...commonPStyle}}>
                                    {signature.phone && (
                                        <React.Fragment>
                                            <img src={phone_icon} style={iconStyle}/>
                                            {signature.phone}
                                        </React.Fragment>
                                    )}
                                    {signature.mobile && (
                                        <React.Fragment>
                                            <img src={mobile_icon} style={iconStyle}/>
                                            {signature.mobile}
                                        </React.Fragment>
                                    )}
                                    {signature.website && (
                                        <React.Fragment>
                                            <img src={web_icon} style={iconStyle}/>
                                            <a href={signature.website} style={{
                                                textDecoration: 'none',
                                                color: '#1155cc'
                                            }}>{signature.website}</a>
                                        </React.Fragment>
                                    )}
                                    {signature.email && (
                                        <React.Fragment>
                                            <img src={mail_icon} style={iconStyle}/>
                                            <a href={`mailto:{signature.email}`}
                                               style={{textDecoration: 'none', color: '#1155cc'}}>{signature.email}</a>
                                        </React.Fragment>
                                    )}
                                    {signature.address && (
                                        <React.Fragment>
                                            <img src={map_icon} style={iconStyle}/>
                                            {signature.address}
                                        </React.Fragment>
                                    )}
                                </p>
                                <div style={{marginTop: '10px', display: 'flex', alignItems: 'center'}}>
                                    {getSocialIcons(signature)}
                                </div>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                    {includeBanner && signature.banner_image_url &&
                        <img src={signature.banner_image_url} style={bannerImageStyle}/>}

                </div>
            ),
            wide: (
                <div id="signatureContent" style={{...commonStyle, borderTop: '2px solid #666', padding: '10px'}}
                     ref={previewRef}>
                    <table style={{width: '100%', verticalAlign: 'top'}}>
                        <tbody>
                        <tr>
                            <td style={{verticalAlign: 'top'}}>
                                <p style={{
                                    margin: '0',
                                    fontSize: '18px',
                                    fontWeight: 'bold',
                                    color: '#333'
                                }}>{signature.name}</p>
                                <p style={commonPStyle}>{signature.company} , {signature.title}</p>
                                <p style={{margin: '5px 0', ...commonPStyle}}>
                                    {signature.phone && (
                                        <React.Fragment>
                                            <img src={phone_icon} style={iconStyle}/>
                                            {signature.phone}
                                        </React.Fragment>
                                    )}
                                    {signature.mobile && (
                                        <React.Fragment>
                                            <img src={mobile_icon} style={iconStyle}/>
                                            {signature.mobile}
                                        </React.Fragment>
                                    )}
                                    {signature.website && (
                                        <React.Fragment>
                                            <img src={web_icon} style={iconStyle}/>
                                            <a href={signature.website} style={{
                                                textDecoration: 'none',
                                                color: '#1155cc'
                                            }}>{signature.website}</a>
                                        </React.Fragment>
                                    )}
                                    {signature.email && (
                                        <React.Fragment>
                                            <img src={mail_icon} style={iconStyle}/>
                                            <a href={`mailto:${signature.email}`}
                                               style={{textDecoration: 'none', color: '#1155cc'}}>{signature.email}</a>
                                        </React.Fragment>
                                    )}
                                    {signature.address && (
                                        <React.Fragment>
                                            <img src={map_icon} style={iconStyle}/>
                                            {signature.address}
                                        </React.Fragment>
                                    )}
                                </p>
                            </td>
                            <td style={{verticalAlign: 'top', textAlign: 'right'}}>
                                <div style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-end'}}>
                                    {getSocialIcons(signature)}
                                </div>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                    {includeBanner && signature.banner_image_url &&
                        <img src={signature.banner_image_url} style={bannerImageStyle}/>}

                </div>
            ),
            compact: (
                <div id="signatureContent" style={commonStyle} ref={previewRef}>
                    <table style={{width: '100%'}}>
                        <tbody>
                        <tr>
                            <td style={{verticalAlign: 'top'}}>
                                <p style={{
                                    margin: '0',
                                    color: '#333',
                                    fontWeight: 'bold',
                                    fontSize: '18px'
                                }}>{signature.name}</p>
                                <p style={commonPStyle}>{signature.company} , {signature.title}</p>
                                <p style={{margin: '5px 0', ...commonPStyle}}>
                                    {signature.phone && (
                                        <React.Fragment>
                                            <img src={phone_icon} style={iconStyle}/>
                                            {signature.phone}<br/>
                                        </React.Fragment>
                                    )}
                                    {signature.mobile && (
                                        <React.Fragment>
                                            <img src={mobile_icon} style={iconStyle}/>
                                            {signature.mobile}<br/>
                                        </React.Fragment>
                                    )}
                                    {signature.website && (
                                        <React.Fragment>
                                            <img src={web_icon} style={iconStyle}/>
                                            <a href={signature.website} style={{
                                                textDecoration: 'none',
                                                color: '#1155cc'
                                            }}>{signature.website}</a><br/>
                                        </React.Fragment>
                                    )}
                                    {signature.email && (
                                        <React.Fragment>
                                            <img src={mail_icon} style={iconStyle}/>
                                            <a href={`mailto:${signature.email}`} style={{
                                                textDecoration: 'none',
                                                color: '#1155cc'
                                            }}>{signature.email}</a><br/>
                                        </React.Fragment>
                                    )}
                                    {signature.address && (
                                        <React.Fragment>
                                            <img src={map_icon} style={iconStyle}/>
                                            {signature.address}
                                        </React.Fragment>
                                    )}
                                </p>
                                <div style={{
                                    display: 'flex', justifyContent: 'flex-end',
                                    alignItems: 'center', backgroundColor: '#3b5998',
                                    borderRadius: '5px', padding: '10px', width: '100%'
                                }}>
                                    {getSocialIcons(signature)}
                                </div>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                    {includeBanner && signature.banner_image_url &&
                        <img src={signature.banner_image_url} style={bannerImageStyle}/>}

                </div>
            )
        };

        return templates[selectedTemplate] || templates['classic'];
    };

    const getSocialIcons = (signature) => (
        <div style={{display: 'flex', flexDirection: 'column'}}>
            {facebook && <a href={facebook}><img src={fb} style={{width: '25px', marginBottom: '8px'}}/></a>}
            {instagram &&
                <a href={instagram}><img src={instagram_icon} style={{width: '25px', marginBottom: '8px'}}/></a>}
            {linkedin && <a href={linkedin}><img src={linkedin_icon} style={{width: '25px', marginBottom: '8px'}}/></a>}
            {twitter && <a href={twitter}><img src={twitter_icon} style={{width: '20px', marginBottom: '8px'}}/></a>}
            {tiktok && <a href={tiktok}><img src={tiktok_icon} style={{width: '25px', marginBottom: '8px'}}/></a>}
        </div>
    );

    const renderDetailsForm = () => (
        <Form>
            <Row>
                <Col md={8}>
                    <Form.Group controlId="formEmployeeCode">
                        <Form.Label>Employee Code</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Enter employee code"
                            value={employeeCode}
                            onChange={(e) => setEmployeeCode(e.target.value)}
                        />
                    </Form.Group>
                </Col>
                <Col md={8}>
                    <Form.Group controlId="formName">
                        <Form.Label>Name</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Enter name"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                        />
                    </Form.Group>
                </Col>
                <Col md={4} className="d-flex align-items-end">
                    <Form.Group controlId="formImage" className="w-100">
                        <Form.Label>Upload Banner</Form.Label>
                        <input
                            type="file"
                            id="fileInput"
                            className="file-input"
                            onChange={handleBannerImageUpload}
                        />
                        <label htmlFor="fileInput" className="custom-file-upload">
                            <FontAwesomeIcon icon={faPlusCircle} style={{marginRight: '5px'}}/>
                            Upload Banner
                        </label>
                    </Form.Group>
                </Col>
            </Row>
            <Form.Group controlId="formCompany">
                <Form.Label>company</Form.Label>

                <Form.Control
                    type="text"
                    placeholder="Enter company"
                    value={companyFilterDetails}
                    onChange={(e) => setCompany(e.target.value)}
                />
            </Form.Group>
            <Form.Group controlId="formTitle">
                <Form.Label>Designation</Form.Label>
                <Form.Control
                    type="text"
                    placeholder="Enter title"
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                />
            </Form.Group>
            <Form.Group controlId="formDepartment">
                <Form.Label>Department</Form.Label>
                <Form.Control
                    type="text"
                    placeholder="Enter Department"
                    value={department}
                    onChange={(e) => setDepartment(e.target.value)}
                />
            </Form.Group>
            <Form.Group controlId="formPhone">
                <Form.Label>Phone</Form.Label>
                <Form.Control
                    type="text"
                    placeholder="Enter phone"
                    value={phone}
                    onChange={(e) => setPhone(e.target.value)}
                />
            </Form.Group>
            <Form.Group controlId="formMobile">
                <Form.Label>Mobile</Form.Label>
                <Form.Control
                    type="text"
                    placeholder="Enter mobile"
                    value={mobile}
                    onChange={(e) => setMobile(e.target.value)}
                />
            </Form.Group>
            <Form.Group controlId="formWebsite">
                <Form.Label>Website</Form.Label>
                <Form.Control
                    type="text"
                    placeholder="Enter website"
                    value={website}
                    onChange={(e) => setWebsite(e.target.value)}
                />
            </Form.Group>
            <Form.Group controlId="formEmail">
                <Form.Label>Email</Form.Label>
                <Form.Control
                    type="email"
                    placeholder="Enter email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                />
            </Form.Group>
            <Form.Group controlId="formAddress">
                <Form.Label>Address</Form.Label>
                <Form.Control
                    type="text"
                    placeholder="Enter address"
                    value={address}
                    onChange={(e) => setAddress(e.target.value)}
                />
            </Form.Group>
            <Form.Group controlId="includeBannerCheckbox">
                <Form.Check
                    type="checkbox"
                    label="Include Banner"
                    checked={includeBanner}
                    onChange={(e) => setIncludeBanner(e.target.checked)}
                />
            </Form.Group>
        </Form>
    );

    const renderSocialForm = () => (
        <Form>
            <Form.Group controlId="formFacebook">
                <Form.Label>
                    <FontAwesomeIcon icon={faFacebook} style={{color: '#3b5998', marginRight: '10px'}}/>
                    Facebook URL
                </Form.Label>
                <Form.Control
                    type="text"
                    placeholder="Enter Facebook URL"
                    value={facebook}
                    onChange={(e) => setFacebook(e.target.value)}
                />
            </Form.Group>
            <Form.Group controlId="formInstagram">
                <Form.Label>
                    <FontAwesomeIcon icon={faInstagram} style={{color: '#E1306C', marginRight: '10px'}}/>
                    Instagram Username
                </Form.Label>
                <Form.Control
                    type="text"
                    placeholder="Enter Instagram Username"
                    value={instagram}
                    onChange={(e) => setInstagram(e.target.value)}
                />
            </Form.Group>
            <Form.Group controlId="formLinkedin">
                <Form.Label>
                    <FontAwesomeIcon icon={faLinkedin} style={{color: '#0e76a8', marginRight: '10px'}}/>
                    LinkedIn URL
                </Form.Label>
                <Form.Control
                    type="text"
                    placeholder="Enter LinkedIn URL"
                    value={linkedin}
                    onChange={(e) => setLinkedin(e.target.value)}
                />
            </Form.Group>
            <Form.Group controlId="formTwitter">
                <Form.Label>
                    <FontAwesomeIcon icon={faTwitter} style={{color: '#00acee', marginRight: '10px'}}/>
                    Twitter Handle
                </Form.Label>
                <Form.Control
                    type="text"
                    placeholder="Enter Twitter Handle"
                    value={twitter}
                    onChange={(e) => setTwitter(e.target.value)}
                />
            </Form.Group>
            <Form.Group controlId="formTiktok">
                <Form.Label>
                    <FontAwesomeIcon icon={faTiktok} style={{color: '#000000', marginRight: '10px'}}/>
                    TikTok Username
                </Form.Label>
                <Form.Control
                    type="text"
                    placeholder="Enter TikTok Username"
                    value={tiktok}
                    onChange={(e) => setTiktok(e.target.value)}
                />
            </Form.Group>
        </Form>
    );

    const renderTemplateForm = () => (
        <div className="template-container">
            <div className="template-section">
                <div className="template-card" onClick={() => setSelectedTemplate('sapphireTemplate')}>
                    <div className={`template-item ${selectedTemplate === 'sapphireTemplate' ? 'selected' : ''}`}>
                        <div className="template-preview sapphireTemplate"></div>
                        <span>Sapphire</span>
                    </div>
                </div>
                <div className="template-card" onClick={() => setSelectedTemplate('classic')}>
                    <div className={`template-item ${selectedTemplate === 'classic' ? 'selected' : ''}`}>
                        <div className="template-preview classic"></div>
                        <span>Classic</span>
                    </div>
                </div>
            </div>
            <div className="template-section">
                <div className="template-card" onClick={() => setSelectedTemplate('wide')}>
                    <div className={`template-item ${selectedTemplate === 'wide' ? 'selected' : ''}`}>
                        <div className="template-preview wide"></div>
                        <span>Wide</span>
                    </div>
                </div>
                <div className="template-card" onClick={() => setSelectedTemplate('compact')}>
                    <div className={`template-item ${selectedTemplate === 'compact' ? 'selected' : ''}`}>
                        <div className="template-preview compact"></div>
                        <span>Compact</span>
                    </div>
                </div>
            </div>
            <div className="template-card" onClick={() => setSelectedTemplate('horizontal')}>
                <div className={`template-item ${selectedTemplate === 'horizontal' ? 'selected' : ''}`}>
                    <div className="template-preview horizontal"></div>
                    <span>Horizontal</span>
                </div>
            </div>
        </div>
    );

    const copyHtmlToClipboard = () => {
        const previewContent = previewRef.current.cloneNode(true);

        previewContent.style.textAlign = 'left';

        const htmlContent = previewContent.innerHTML;
        const blob = new Blob([htmlContent], {type: 'text/html'});
        const clipboardItem = new ClipboardItem({'text/html': blob});

        navigator.clipboard.write([clipboardItem]).then(() => {
            toast.success('HTML code copied to clipboard!', {
                icon: '📋',
                duration: 4000,
                action: {
                    text: 'Okay',
                    onClick: () => toast.dismiss()
                }
            });
        }).catch((err) => {
            toast.error('Failed to copy HTML code.', {
                icon: '❌',
                duration: 4000,
                action: {
                    text: 'Okay',
                    onClick: () => toast.dismiss()
                }
            });
            console.error('Failed to copy HTML code: ', err);
        });
    };


    const updateAllSignatures = async () => {
        try {
            for (const signature of signatures) {
                // Update each signature by regenerating its image
                await captureAndUploadSignature(signature);
            }
        } catch (error) {
            toast.error(`Error updating signatures: ${error.message}`);
            console.error('Error updating signatures:', error);
        }
    };

// Helper function to capture and upload signature
    const captureAndUploadSignature = async (signature) => {
        return new Promise((resolve) => {
            // Create a temporary container for the signature HTML
            const tempContainer = document.createElement('div');
            tempContainer.style.position = 'absolute';
            tempContainer.style.left = '-9999px';
            document.body.appendChild(tempContainer);

            // Use the updated banner_image_url from the signature
            const bannerUrl = signature.banner_image_url;

            // Create the signature HTML content dynamically based on the signature data
            const signatureHtml = `
      <div style="font-family: Arial, sans-serif; font-size: 14px; color: black; max-width: 600px; margin: auto; padding: 20px; border: 0px solid #ddd; border-radius: 5px;">
        <table style="width: 100%;">
          <tr>
            <td style="width: 80px;">
              ${signature.image_url ? `<img src="${signature.image_url}" alt="Logo" style="width: 130px; display: block; margin-left: -6px; margin-right: 30px; padding: 2px;" />` : ''}
            </td>
            <td style="vertical-align: top;">
              <p style="margin: 0; color: #000; font-weight: bold; font-size: 20px;">${signature.name}</p>
              <p style="margin: 0; color: #666; font-size: 14px;">${signature.title}</p>
              <p style="margin: 0; color: #666; font-size: 14px;">${signature.department}</p>
              <p style="margin: 5px 0; color: #666; font-size: 14px;">
                ${signature.phone ? `<img src="${phone_icon}" style="margin-right: 5px; width: 14px;" /> ${signature.phone}<br />` : ''}
                ${signature.mobile ? `<img src="${mobile_icon}" style="margin-right: 5px; width: 14px;" /> ${signature.mobile}<br />` : ''}
                ${signature.website ? `<img src="${web_icon}" style="margin-right: 5px; width: 14px;" /> <a href="${signature.website}" style="text-decoration: none; color: #1155cc;">${signature.website}</a><br />` : ''}
                ${signature.email ? `<img src="${mail_icon}" style="margin-right: 5px; width: 14px;" /> <a href="mailto:${signature.email}" style="text-decoration: none; color: #1155cc;">${signature.email}</a><br />` : ''}
                ${signature.address ? `<img src="${map_icon}" style="margin-right: 5px; width: 14px;" /> ${signature.address}` : ''}
              </p>
            </td>
          </tr>
        </table>
        ${bannerUrl ? `<img src="${bannerUrl}" alt="Banner Image" style="width: 102%; border-radius: 8px; margin-left: -6px;" />` : ''}
      </div>
    `;

            // Set the inner HTML of the temporary container
            tempContainer.innerHTML = signatureHtml;

            // Capture the signature using html2canvas
            html2canvas(tempContainer, {
                scale: 4, // Increase scale for better quality
                useCORS: true,
                backgroundColor: null,
            }).then((canvas) => {
                // Create a blob from the canvas and upload it
                canvas.toBlob((blob) => {
                    const formData = new FormData();
                    formData.append('signature[employee_code]', signature.employee_code);
                    formData.append('signature[signature_image]', blob, `${signature.employee_code}.png`);

                    axios
                        .post(`/signatures/${signature.id}/upload_image`, formData, {
                            headers: { 'Content-Type': 'multipart/form-data' },
                        })
                        .then(() => {
                            toast.success(`Signature image updated successfully for ${signature.name}`);
                            resolve();
                        })
                        .catch((error) => {
                            console.error(`Error uploading image for ${signature.name}:`, error);
                            toast.error(`Failed to upload signature image for ${signature.name}`);
                            resolve();
                        });
                });
            }).finally(() => {
                // Remove the temporary container after capturing
                document.body.removeChild(tempContainer);
            });
        });
    };

    const saveSignature = async () => {
        const formData = new FormData();
        formData.append('signature[employee_code]', employeeCode);
        formData.append('signature[name]', name);
        formData.append('signature[title]', title);
        formData.append('signature[department]', department);
        formData.append('signature[company]', company);
        formData.append('signature[company_id]', selectedCompanyId);
        formData.append('signature[phone]', phone);
        formData.append('signature[mobile]', mobile);
        formData.append('signature[website]', website);
        formData.append('signature[email]', email);
        formData.append('signature[address]', address);
        formData.append('signature[facebook]', facebook);
        formData.append('signature[instagram]', instagram);
        formData.append('signature[linkedin]', linkedin);
        formData.append('signature[twitter]', twitter);
        formData.append('signature[youtube]', youtube);
        formData.append('signature[tiktok]', tiktok);
        formData.append('signature[template]', selectedTemplate);

        if (image) {
            const imageBlob = await fetch(image).then(r => r.blob());
            formData.append('signature[signature_image]', imageBlob, 'profile.jpg');
        }
        if (includeBanner && bannerImageFile) {
            formData.append('signature[banner]', bannerImageFile, bannerImageFile.name);
        }

        try {
            let response;
            if (selectedSignature) {
                response = await axios.put(`/signatures/${selectedSignature.id}`, formData, {
                    headers: {'Content-Type': 'multipart/form-data'}
                });
                toast.success('Signature updated successfully');
            } else {
                response = await axios.post('/signatures', formData, {
                    headers: {'Content-Type': 'multipart/form-data'}
                });
                toast.success('Signature created successfully');
            }

            // Ensure previewRef is not null before accessing it
            if (!previewRef.current) {
                throw new Error('Preview reference is not available.');
            }

            const previewDiv = previewRef.current;

            // Ensure previewDiv is defined before querying elements within it
            if (previewDiv) {
                const bannerElements = previewDiv.querySelectorAll('.banner-image');
                const buttonElements = previewDiv.querySelectorAll('.buttons');

                const originalStyles = {
                    border: previewDiv.style.border,
                    boxShadow: previewDiv.style.boxShadow,
                    outline: previewDiv.style.outline,
                };

                const hideElementsAndBorders = () => {
                    bannerElements.forEach(el => (el.style.display = 'none'));
                    buttonElements.forEach(el => (el.style.display = 'none'));

                    previewDiv.style.setProperty('border', 'none', 'important');
                    previewDiv.style.setProperty('box-shadow', 'none', 'important');
                    previewDiv.style.setProperty('outline', 'none', 'important');
                    previewDiv.style.setProperty('margin', '0', 'important');
                    previewDiv.style.setProperty('padding', '0', 'important');
                    previewDiv.classList.add('no-borderss');
                };

                const restoreStyles = () => {
                    bannerElements.forEach(el => (el.style.display = ''));
                    buttonElements.forEach(el => (el.style.display = ''));

                    previewDiv.style.border = originalStyles.border;
                    previewDiv.style.boxShadow = originalStyles.boxShadow;
                    previewDiv.style.outline = originalStyles.outline;
                    previewDiv.style.margin = '';
                    previewDiv.style.padding = '';

                    previewDiv.classList.remove('no-borderss');
                };

                hideElementsAndBorders();

                const captureAndUpload = new Promise(async (resolve) => {
                    // Desired output dimensions
                    const targetWidth = 480;
                    const targetHeight = 155;
                    // Multiply by devicePixelRatio to account for high-DPI displays
                    const scaleFactor = window.devicePixelRatio || 1;

                    // Ensure all fonts and images are loaded
                    await document.fonts.ready;

                    // Use original dimensions without altering previewDiv's size
                    html2canvas(previewDiv, {
                        scale: scaleFactor, // Renders at higher internal resolution
                        useCORS: true,
                        allowTaint: true,
                        backgroundColor: null,
                        width: targetWidth,
                        height: targetHeight,
                        windowWidth: previewDiv.scrollWidth,
                        windowHeight: previewDiv.scrollHeight,
                        logging: false, // Set to true if you need to debug
                    }).then((canvas) => {
                        // Resize the canvas to the target dimensions
                        const resizedCanvas = document.createElement('canvas');
                        resizedCanvas.width = targetWidth;
                        resizedCanvas.height = targetHeight;
                        const ctx = resizedCanvas.getContext('2d');

                        // Draw the high-resolution canvas onto the resized canvas
                        ctx.drawImage(
                            canvas,
                            0,
                            0,
                            canvas.width,
                            canvas.height,
                            0,
                            0,
                            targetWidth,
                            targetHeight
                        );

                        // Convert the resized canvas to a blob
                        resizedCanvas.toBlob(
                            (blob) => {
                                const formData = new FormData();
                                formData.append('signature[employee_code]', employeeCode);
                                formData.append('signature[signature_image]', blob, `${employeeCode}.png`);

                                axios
                                    .post(
                                        `/signatures/${
                                            selectedSignature && selectedSignature.id
                                                ? selectedSignature.id
                                                : response.data.id
                                        }/upload_image`,
                                        formData,
                                        {
                                            headers: { 'Content-Type': 'multipart/form-data' },
                                        }
                                    )
                                    .then(() => {
                                        toast.success('Signature image uploaded successfully');
                                        resolve();
                                    })
                                    .catch((error) => {
                                        console.error('Error uploading image:', error);
                                        toast.error('Failed to upload signature image');
                                        resolve();
                                    });
                            },
                            'image/png',
                            1 // Set image quality to maximum
                        );
                    });
                });

                captureAndUpload.then(restoreStyles);

                fetchSignatures(selectedCompanyIdSaved);
                setSelectedTab('saved');
            }
        } catch (error) {
            toast.error(`Error saving signature: ${error.message}`);
            console.error('Error saving signature:', error);
        }
    };
    const handleDeleteSignature = (signature) => {
        setSignatureToDelete(signature);
        setShowDeleteModal(true);
    };

    const handleViewSignature = (signature) => {
        setSignatureToView(signature);
        setShowViewModal(true);
    };

    const saveHtmlToFile = (signature) => {
        const currentDomain = window.location.origin;
        const fullSignatureImageUrl = `${currentDomain}${signature.signature_image_url}`;
        const fullBannerImageUrl = `${currentDomain}${signature.banner_image_url}`;

        const htmlContent = `
            <html>
                <body style="margin:0; padding:0;">
                    <table width="100%" cellpadding="0" cellspacing="0" border="0" style="max-width: 400px;">
                        <tr>
                            <td>
                                <img src="${fullSignatureImageUrl}" alt="Signature Image" style="display: block; max-width: 100%;" />
                            </td>
                        </tr>
                    </table>
                </body>
            </html>
        `;

        const blob = new Blob([htmlContent], {type: 'text/html'});
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = 'official_signature.htm';
        link.click();
        URL.revokeObjectURL(link.href);
        toast.success('Official Signature file saved successfully!');
    };

    const handleClearForm = () => {
        setName('');
        setTitle('');
        setDepartment('');
        setCompany('');
        setPhone('');
        setMobile('');
        setWebsite('');
        setEmail('');
        setAddress('');
        setImage(null);
        setFacebook('');
        setInstagram('');
        setLinkedin('');
        setTwitter('');
        setYoutube('');
        setTiktok('');
        setSelectedTemplate('classic');
    };

    const confirmDeleteSignature = async () => {
        try {
            await axios.delete(`/signatures/${signatureToDelete.id}`);
            toast.success('Signature deleted successfully');
            fetchSignatures(selectedCompanyIdSaved);
            setShowDeleteModal(false);
            setSignatureToDelete(null);
        } catch (error) {
            toast.error('Error deleting signature');
            console.error('Error deleting signature:', error);
        }
    };

    const loadSignature = (signature) => {
        setEmployeeCode(signature.employee_code);
        setName(signature.name);
        setTitle(signature.title);
        setDepartment(signature.department);
        setCompany(signature.company);
        setPhone(signature.phone);
        setMobile(signature.mobile);
        setWebsite(signature.website);
        setEmail(signature.email);
        setAddress(signature.address);
        setFacebook(signature.facebook);
        setInstagram(signature.instagram);
        setLinkedin(signature.linkedin);
        setTwitter(signature.twitter);
        setYoutube(signature.youtube);
        setTiktok(signature.tiktok);
        setImage(signature.image_url);
        setBannerImage(signature.banner_image_url);
        setUploadedPng(signature.png_image_url);
        setSelectedTemplate(signature.template);
        setSelectedSignature(signature);
        setSelectedTab('details');
    };

    const renderSavedSignatures = () => {
        if (!selectedCompanyIdSaved || signatures.length === 0) {
            return <p>Please select a company to view saved signatures.</p>;
        }

        const columns = [
            {name: 'Employee Code', selector: row => row.employee_code, sortable: true},
            {name: 'Name', selector: row => row.name, sortable: true},
            {name: 'Company', selector: row => row.company, sortable: true},
            {
                name: 'Actions',
                cell: (row) => (
                    <div>
                        <Button variant="outline-primary" onClick={() => loadSignature(row)}><FontAwesomeIcon
                            icon={faEdit}/></Button>{' '}
                        <Button variant="outline-danger" onClick={() => handleDeleteSignature(row)}><FontAwesomeIcon
                            icon={faTrashAlt}/></Button>{' '}
                        <Button variant="outline-info" onClick={() => saveHtmlToFile(row)}><FontAwesomeIcon
                            icon={faFileCode}/></Button>{' '}
                        <Button variant="outline-secondary" onClick={() => handleViewSignature(row)}><FontAwesomeIcon
                            icon={faEye}/></Button>
                        <Button variant="outline-success" className={'ml-2'} onClick={() => downloadScript(row)}>
                            <FontAwesomeIcon icon={faDownload} /> Script
                        </Button>
                    </div>
                )
            }
        ];

        return (
            <React.Fragment>
                <Form.Group className="mb-3">
                    <Form.Control
                        type="text"
                        placeholder="Search Signatures"
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                    />
                </Form.Group>
                <div className={'d-flex justify-content-between'}>
                    <Button variant="primary" onClick={updateAllSignatures}>
                        Update All Signatures
                    </Button>
                    <Button variant="success"  onClick={downloadAllSignatures}>
                        Download All Signatures
                    </Button>
                </div>

                <DataTable
                    columns={columns}
                    data={signatures.filter(signature =>
                        signature.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
                        signature.employee_code.toLowerCase().includes(searchQuery.toLowerCase()) ||
                        signature.company.toLowerCase().includes(searchQuery.toLowerCase())
                    )}
                    pagination
                />
            </React.Fragment>
        );
    };


    return (
        <Container fluid className="signature-container">
            <Toaster/>
            <Row>
                <Col md={1} className="sidebar1">
                    <div className={`sidebar-item ${selectedTab === 'details' ? 'active' : ''}`}
                         onClick={() => setSelectedTab('details')}>
                        <FontAwesomeIcon icon={faPen} className="fa-icon"/><br/> Details
                    </div>
                    <div className={`sidebar-item ${selectedTab === 'social' ? 'active' : ''}`}
                         onClick={() => setSelectedTab('social')}>
                        <FontAwesomeIcon icon={faShareAlt} className="fa-icon"/><br/> Social
                    </div>
                    <div className={`sidebar-item ${selectedTab === 'template' ? 'active' : ''}`}
                         onClick={() => setSelectedTab('template')}>
                        <FontAwesomeIcon icon={faPalette} className="fa-icon"/><br/> Template
                    </div>
                    <div className={`sidebar-item ${selectedTab === 'saved' ? 'active' : ''}`}
                         onClick={() => setSelectedTab('saved')}>
                        <FontAwesomeIcon icon={faFileCode} className="fa-icon"/><br/> Saved
                    </div>
                </Col>
                {selectedTab !== 'saved' && (
                    <Col md={3} className="signature-details">
                        <h4 className="mb-2">Signature Details</h4>
                        <Form.Group controlId="formCompany" className={'mb-3'}>
                            <Form.Label>Company</Form.Label>
                            <Dropdown onSelect={handleCompanyFilterChangeDetails}>
                                <Dropdown.Toggle variant="primary" id="dropdown-basic">
                                    {companyFilterDetails || "Select Company"}
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    {companies.map((company) => (
                                        <Dropdown.Item key={company.id} eventKey={company.name}>
                                            {company.name}
                                        </Dropdown.Item>
                                    ))}
                                </Dropdown.Menu>
                            </Dropdown>
                        </Form.Group>
                        {selectedTab === 'details' && renderDetailsForm()}
                        {selectedTab === 'social' && renderSocialForm()}
                        {selectedTab === 'template' && renderTemplateForm()}
                    </Col>
                )}
                {selectedTab !== 'saved' && (
                    <Col md={5} className="signature-preview">
                        <h4 className="mb-2">Preview</h4>
                        <div className="preview" ref={previewRef}>
                            {generateSignatureHtml({
                                name,
                                title,
                                department,
                                company,
                                phone,
                                mobile,
                                website,
                                email,
                                address,
                                facebook,
                                instagram,
                                linkedin,
                                twitter,
                                youtube,
                                selectedTemplate,
                                tiktok,
                                image_url: image,
                                banner_image_url: bannerImage,
                                png_image_url: pngImage
                            })}
                            <div className="buttons" style={{display: 'flex', marginTop: '10px'}}>
                                <Button style={{marginLeft: '10px'}} variant="outline-info" onClick={saveSignature}>
                                    <FontAwesomeIcon icon={faSave}
                                                     style={{marginRight: '5px', color: '#3f729b'}}/>Save Signature
                                </Button>
                                <Button variant="outline-danger" onClick={handleClearForm}
                                        style={{marginTop: '0px', marginLeft: '10px'}}>
                                    Clear
                                </Button>
                            </div>
                        </div>
                    </Col>
                )}
                {selectedTab === 'saved' && (
                    <Col md={11}>
                        <h4 className="mb-2">Saved Signatures</h4>
                        <Dropdown onSelect={handleCompanyFilterChangeSaved} className={'mb-3'}>
                            <Dropdown.Toggle variant="success" id="dropdown-basic">
                                {companyFilterSaved || "Select Company"}
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                <Dropdown.Item eventKey={null}>All Companies</Dropdown.Item>
                                {companies.map((company) => (
                                    <Dropdown.Item key={company.id} eventKey={company.name}>
                                        {company.name}
                                    </Dropdown.Item>
                                ))}
                            </Dropdown.Menu>
                        </Dropdown>
                        <Form>
                            <Form.Group controlId="formImage" className="w-100">
                                <Form.Label>Upload Banner</Form.Label>
                                <input
                                    type="file"
                                    id="fileInput"
                                    className="file-input"
                                    onChange={handleBannerImageUpload}
                                />
                                <label htmlFor="fileInput" className="custom-file-upload">
                                    <FontAwesomeIcon icon={faPlusCircle} style={{marginRight: '5px'}}/>
                                    Upload Banner
                                </label>
                            </Form.Group>
                            <Button onClick={updateBannerForAllSignatures} variant="primary"
                                    style={{marginTop: '10px', marginBottom: '20px'}}>
                                Update All Banners
                            </Button>
                        </Form>
                        {renderSavedSignatures()}
                    </Col>
                )}
            </Row>

            {/* View Signature Modal */}
            <Modal show={showViewModal} onHide={() => setShowViewModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>View Signature</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {signatureToView && generateSignatureHtml(signatureToView)}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowViewModal(false)}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>

            {/* Delete Signature Modal */}
            <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Confirm Deletion</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Are you sure you want to delete this signature?
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowDeleteModal(false)}>
                        Cancel
                    </Button>
                    <Button variant="danger" onClick={confirmDeleteSignature}>
                        Delete
                    </Button>
                </Modal.Footer>
            </Modal>
        </Container>
    );
};

export default SignatureGenerator;