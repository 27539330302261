import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import { FaClock, FaCalendarAlt } from 'react-icons/fa';
import Calendar from 'react-calendar';
import moment from 'moment';
import axios from 'axios';

const Dashboard = ({ currentUser }) => {
    const [currentTime, setCurrentTime] = useState(moment().format('HH:mm:ss'));
    const [greeting, setGreeting] = useState('Good Morning');
    const [dayMessage, setDayMessage] = useState('Have a fruitful day ahead ☕️');
    const [quote, setQuote] = useState({ content: 'Keep pushing forward!', author: 'Anonymous' });

    // Add placeholder values for initial weather data
    const [weatherData, setWeatherData] = useState({
        temperature: '25°C',
        condition: 'Sunny',
        location: 'Lahore, PK',
        icon: 'https://openweathermap.org/img/wn/01d.png',
    });

    const [selectedDate, setSelectedDate] = useState(new Date());

    useEffect(() => {
        const timer = setInterval(() => {
            const currentHour = moment().hour();
            setCurrentTime(moment().format('HH:mm:ss'));

            // Set dynamic greetings and day messages based on the time
            if (currentHour < 12) {
                setGreeting('Good Morning');
                setDayMessage('Have a great start to your day ☀️');
            } else if (currentHour < 17) {
                setGreeting('Good Afternoon');
                setDayMessage('Enjoy your afternoon 🌞');
            } else if (currentHour < 20) {
                setGreeting('Good Evening');
                setDayMessage('Relax and unwind 🌙');
            } else {
                setGreeting('Good Night');
                setDayMessage('Good night, sleep tight 🌜');
            }
        }, 1000);
        return () => clearInterval(timer);
    }, []);

    // Fetch a random quote from the quotable.io API
    useEffect(() => {
        const fetchQuote = async () => {
            try {
                const response = await axios.get('https://api.quotable.io/random');
                setQuote({ content: response.data.content, author: response.data.author });
            } catch (error) {
                console.error('Error fetching quote:', error.message);
                // If API call fails, keep a default quote
                setQuote({ content: 'Believe you can and you’re halfway there.', author: 'Theodore Roosevelt' });
            }
        };
        fetchQuote();
    }, []);

    const cardStyle = {
        borderRadius: '15px',
        background: '#fff',
        boxShadow: '5px 5px 15px rgba(0, 0, 0, 0.1)',
        padding: '50px',
        border: 'none',
        marginBottom: '20px',
    };

    return (
        <Container fluid style={{ padding: '50px', minHeight: '100vh' }}>
            <Row className="g-4">
                {/* Greeting Section */}
                <Col md={7}>
                    <Card style={{ ...cardStyle, display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                        <div>
                            <h4 style={{ marginBottom: '10px' }}>{greeting}, {currentUser.full_name}</h4>
                            <p style={{ color: '#6c757d', fontSize: '1.1em' }}>{dayMessage}</p>
                        </div>
                        <h2 style={{ color: 'rgba(75,111,150,0.74)', fontSize: '2.5em' }}>{currentTime}</h2>
                    </Card>
                </Col>

                {/* Weather Section */}
                <Col md={5}>
                    <Card style={{ ...cardStyle, display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <img src={weatherData.icon} alt="Weather Icon" style={{ width: '50px', marginRight: '15px' }} />
                            <div>
                                <h5 style={{ marginBottom: '5px' }}>{weatherData.condition}</h5>
                                <p style={{ color: '#6c757d', fontSize: '0.95em', marginBottom: '0' }}>{weatherData.location}</p>
                            </div>
                        </div>
                        <h1 style={{ color: 'rgba(75,111,150,0.75)', fontSize: '2.5em' }}>{weatherData.temperature}</h1>
                    </Card>
                </Col>

                {/* Calendar Section */}
                <Col md={7}>
                    <Card style={{ ...cardStyle, width: '95%' }}>
                        <Card.Header style={{ backgroundColor: 'transparent', borderBottom: 'none' }}>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <FaCalendarAlt size={30} style={{ marginRight: '10px', color: 'rgba(75,111,150,0.74)' }} />
                                <h4>Calendar</h4>
                            </div>
                        </Card.Header>
                        <Card.Body>
                            <Calendar onChange={setSelectedDate} value={selectedDate} className="mx-auto" style={{ width: '100%', margin: 'auto' }} />
                        </Card.Body>
                    </Card>
                </Col>

                {/* Quote Section */}
                <Col md={4}>
                    <Card style={{ ...cardStyle, textAlign: 'center', padding: '30px' }}>
                        <FaClock size={50} style={{ color: 'rgba(75,111,150,0.75)', marginBottom: '15px' }} />
                        <h3 style={{ marginBottom: '15px' }}>Quote of the Day</h3>
                        <p style={{ fontSize: '1.5em', fontStyle: 'italic', marginBottom: '20px' }}>"{quote.content}"</p>
                        <p style={{ fontWeight: 'bold', color: '#6c757d' }}>- {quote.author}</p>
                    </Card>
                </Col>
            </Row>
        </Container>
    );
};

export default Dashboard;
