import React, {useState, useEffect} from 'react';
import Select from 'react-select';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
    faArrowLeft,
    faPlus,
    faSyncAlt,
    faTimesCircle,
    faTimes,
    faEye,
    faEdit,
    faLink,
    faEnvelope, faCheckCircle
} from '@fortawesome/free-solid-svg-icons';
import {ClipLoader} from 'react-spinners';
import {
    Breadcrumb,
    Card,
    ListGroup,
    Container,
    Row,
    Col,
    Form,
    ProgressBar,
    Modal,
    Button,
    Tooltip,
    OverlayTrigger, Table
} from 'react-bootstrap';
import {useNavigate, useParams, useLocation} from 'react-router-dom';
import axios from 'axios';
import {Toaster, toast} from 'react-hot-toast';
import ReactQuill from 'react-quill';
import StarRatings from 'react-star-ratings';
import DOMPurify from 'dompurify';
import TaskProgress from "./TaskProgress";
import debounce from 'lodash.debounce';
import {Editor, EditorProvider} from 'react-simple-wysiwyg';
import cable from "../../../services/cable";
import CreatableSelect from 'react-select/creatable';
import CreateSubtaskModal from './CreateSubtaskModal';
import ApprovalModal from './ApprovalModal';

const TaskDetail = ({currentUser}) => {
    const [task, setTask] = useState(null);
    const [status, setStatus] = useState('Not Started');
    const [worklogComments, setWorklogComments] = useState([]);
    const [progress, setProgress] = useState(0);
    const [teamMembers, setTeamMembers] = useState([]);
    const [comments, setComments] = useState([]);
    const [newComment, setNewComment] = useState('');
    const [newAttachments, setNewAttachments] = useState([]);
    const [employeeId, setEmployeeId] = useState(null);
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const [newStatus, setNewStatus] = useState('');
    const [showAttachmentConfirmModal, setShowAttachmentConfirmModal] = useState(false);
    const [showEmployeeModal, setShowEmployeeModal] = useState(false);
    const [employees, setEmployees] = useState([]);
    const [selectedEmployees, setSelectedEmployees] = useState([]);
    const [activeMember, setActiveMember] = useState(null);
    const [showEditModal, setShowEditModal] = useState(false);
    const [newNeedByDate, setNewNeedByDate] = useState('');
    const [description, setDescription] = useState('');
    const [showEmailModal, setShowEmailModal] = useState(false);
    const [showRemarksModal, setShowRemarksModal] = useState(false);
    const [remarks, setRemarks] = useState('');
    const [rating, setRating] = useState(0);
    const navigate = useNavigate();
    const {id} = useParams();
    const location = useLocation();
    const currentUserEmail = currentUser.email;
    const currentUserType = currentUser.user_type;
    const [toEmail, setToEmail] = useState([]);
    const [ccEmails, setCcEmails] = useState([]);
    const searchParams = new URLSearchParams(location.search);
    const fromEmail = searchParams.get('from_email') === 'true';
    const completed = searchParams.get('completed') === 'true';
    const sentFromEmail = searchParams.get('sent_from_email') === 'true';
    const fromNotification = location.state && location.state.fromNotification ? location.state.fromNotification : false;

    const [showRemoveConfirmModal, setShowRemoveConfirmModal] = useState(false);
    const [employeeToRemove, setEmployeeToRemove] = useState(null);
    const [activeTeamMembers, setActiveTeamMembers] = useState([]);
    const [hasRequestAssignment, setHasRequestAssignment] = useState(false);
    const [employeeOptions, setEmployeeOptions] = useState([]);
    const [searchValue, setSearchValue] = useState('');
    const [loadingEmployees, setLoadingEmployees] = useState(false);
    const [includePreviousThread, setIncludePreviousThread] = useState(false);
    const [showSubtaskModal, setShowSubtaskModal] = useState(false);
    const [subtasks, setSubtasks] = useState([]);
    const [showApprovalModal, setShowApprovalModal] = useState(false);
    const [approvalEmail, setApprovalEmail] = useState([]);
    const [approvalComment, setApprovalComment] = useState('');
    const [approvals, setApprovals] = useState([]);
    const [showApprovalActionModal, setShowApprovalActionModal] = useState(false);
    const [selectedApproval, setSelectedApproval] = useState(null);
    const [approvalAction, setApprovalAction] = useState('');
    const [approvalFeedback, setApprovalFeedback] = useState('');
    const [modalMode, setModalMode] = useState('edit');
    const [showModal, setShowModal] = useState(false);

    useEffect(() => {
        const fetchEmployeeId = async () => {
            try {
                const response = await axios.get('/employees/fetch_employee', {params: {official_email: currentUserEmail || ''}});
                const employees = response.data;

                const employee = employees.find(emp => emp.official_email === currentUserEmail);
                if (employee) {
                    setEmployeeId(employee.id);
                } else {
                    toast.error("Current Employee record not found.");
                }
            } catch (error) {
                console.error('Error fetching employee details:', error);
                toast.error("Error fetching employee details.");
            }
        };

        fetchEmployeeId();
    }, [currentUserEmail]);

    useEffect(() => {
        fetchTask();
        fetchEmployees();
    }, [id]);

    const handleShowApprovalModal = () => setShowApprovalModal(true);
    const handleHideApprovalModal = () => {
        setShowApprovalModal(false);
        setApprovalEmail([]);
        setApprovalComment('');
    };
    useEffect(() => {
        const subscription = cable.subscriptions.create(
            {channel: 'ServiceRequestsChannel'},
            {
                received: (data) => {
                    toast.success(data, {
                        duration: 4000,
                        position: 'top-center',
                    });
                    fetchComments(id);
                },
            }
        );

        return () => subscription.unsubscribe();
    }, [id]);


    useEffect(() => {
        fetchApprovals();
    }, [id]);



    const handleCloseApprovalActionModal = () => {
        setShowApprovalActionModal(false);
        setApprovalFeedback('');
        setApprovalAction('Approved');
        setSelectedApproval(null);
        setModalMode('edit');
    };

    const handleApprovalActionSubmit = async () => {
        if (!approvalFeedback.trim()) {
            toast.error('Please provide feedback.');
            return;
        }

        try {
            await axios.put(`/approvals/${selectedApproval.id}`, {

                approval: {
                    id: selectedApproval.id,
                    status: approvalAction,
                    feedback: approvalFeedback,
                },
            });

            toast.success(`Approval ${approvalAction.toLowerCase()} successfully.`);
            handleCloseApprovalActionModal();
            fetchApprovals();
            window.dispatchEvent(new Event('approvalsUpdated'));
        } catch (error) {
            toast.error('Error updating approval status.');
            console.error(error);
        }
    };

    const fetchApprovals = async () => {
        try {
            const response = await axios.get(`/approvals/all_approvals`, { params: { ticket_id: id } });
            setApprovals(response.data);
        } catch (error) {
            toast.error('Error fetching approvals data.');
            console.error('Error fetching approvals data:', error);
        }
    };

    const fetchEmployeesBySearch = debounce(async (searchTerm) => {
        if (!searchTerm) return;

        try {
            setLoadingEmployees(true);
            const response = await axios.get(`/employees/fetch_employee`, {
                params: {official_email: searchTerm}
            });

            const fetchedEmployees = response.data.map(emp => ({
                value: emp.official_email,
                label: `${emp.name !== 'External Email' ? emp.name : 'External Email'} (${emp.official_email})`,
            }));

            setEmployeeOptions(fetchedEmployees);
            setLoadingEmployees(false);
        } catch (error) {
            console.error('Error fetching employees:', error);
            toast.error('Error fetching employees.');
            setLoadingEmployees(false);
        }
    }, 300);

    const handleCreateSubtask = () => {
        setShowSubtaskModal(true);
    };
    const handleInputChange = (value) => {
        setSearchValue(value);
        if (value.includes('@') && value.includes('.')) {
            const customEmail = [{value, label: value}];
            setEmployeeOptions(customEmail);
        } else {
            fetchEmployeesBySearch(value);
        }
    };

    const statusChanges = worklogComments
        .filter(comment => comment.content.toLowerCase().includes('status changed'))
        .map(comment => {
            const statusMatch = comment.content.match(/status changed to (.*)/i);
            const status = statusMatch ? statusMatch[1] : 'Unknown Status';
            return {
                status: status,
                timestamp: comment.created_at,
            };
        });


    useEffect(() => {
        const activeMembers = teamMembers.filter(member => member.active_member).map(member => ({
            value: member.id,
            label: member.name
        }));
        setActiveTeamMembers(activeMembers);
    }, [teamMembers]);


    useEffect(() => {
        if (fromEmail && completed) {
            setShowRemarksModal(true);
        }
    }, [fromEmail, completed]);

    const fetchTask = async () => {
        try {
            const ticketResponse = await axios.get(`/service_requests/${id}/fetch_ticket`);
            const ticket = ticketResponse.data.find(ticket => ticket.id === parseInt(id));

            if (ticket) {
                let toEmailSet = new Set();
                let ccEmailSet = new Set();

                if (ticket.reporter_email) {
                    toEmailSet.add(ticket.reporter_email);
                }

                if (ticket.to_email) {
                    if (Array.isArray(ticket.to_email)) {
                        ticket.to_email.forEach(email => ccEmailSet.add(email));
                    } else if (typeof ticket.to_email === 'string' && ticket.to_email.includes(',')) {
                        const emailArray = ticket.to_email.split(',').map(email => email.trim());
                        emailArray.forEach(email => ccEmailSet.add(email));
                    } else {
                        ccEmailSet.add(ticket.to_email);
                    }
                }

                if (ticket.request_assignments && ticket.request_assignments.length > 0) {
                    ticket.request_assignments.forEach(assignment => {
                        if (assignment.employee && assignment.employee.official_email) {
                            toEmailSet.add(assignment.employee.official_email);
                        }
                    });
                }

                if (ticket.cc_email) {
                    if (Array.isArray(ticket.cc_email)) {
                        ticket.cc_email.forEach(email => ccEmailSet.add(email));
                    } else if (typeof ticket.cc_email === 'string' && ticket.cc_email.includes(',')) {
                        const emailArray = ticket.cc_email.split(',').map(email => email.trim());
                        emailArray.forEach(email => ccEmailSet.add(email));
                    } else {
                        ccEmailSet.add(ticket.cc_email);
                    }
                }

                if (currentUser && currentUser.email) {
                    ccEmailSet.add(currentUser.email);
                }

                if (toEmailSet.has(currentUser.email)) {
                    ccEmailSet.delete(currentUser.email);
                }

                const toEmailValue = Array.from(toEmailSet).map(email => ({value: email, label: email}));
                const ccEmailArray = Array.from(ccEmailSet).map(email => ({value: email, label: email}));

                setToEmail(toEmailValue);
                setCcEmails(ccEmailArray);


                const departmentId = ticket.department_id;
                fetchEmployees(departmentId);

                let ccEmails = [];
                if (ticket.cc_email) {
                    if (ticket.cc_email.startsWith('[') && ticket.cc_email.endsWith(']')) {
                        try {
                            ccEmails = JSON.parse(ticket.cc_email);
                        } catch (e) {
                            console.error('Error parsing cc_email JSON:', e);
                            toast.error("Error parsing CC emails.");
                        }
                    } else if (ticket.cc_email.includes(',')) {
                        ccEmails = ticket.cc_email.split(',').map(email => email.trim());
                    } else {
                        ccEmails = [ticket.cc_email.trim()];
                    }
                }

                const employeeResponses = await Promise.all(ccEmails.map(email =>
                    axios.get(`/employees/fetch_employee`, {params: {official_email: email}})
                ));

                const ccEmployees = employeeResponses
                    .filter(res => res.data.length > 0)
                    .map(res => res.data[0].name)
                    .join(', ');

                const requestAssignment = ticket.request_assignments[0];
                setHasRequestAssignment(!!requestAssignment);
                const teamMembers = ticket.request_assignments.map(assignment => ({
                    id: assignment.employee.id,
                    name: assignment.employee.name,
                    active_member: assignment.active_member,
                    request_assignment_id: assignment.id
                }));

                const taskData = {
                    ticketNumber: ticket.ticket_number,
                    createdAt: ticket.created_at,
                    responseTime: 'N/A',
                    createdBy: ticket.reporter,
                    ccEmployee: ccEmployees,
                    project: ticket.request_title,
                    milestone: 'N/A',
                    on_behalf_of: ticket.on_behalf_of,
                    reporter_email: ticket.reporter_email,
                    rating: ticket.rating,
                    remarks: ticket.remarks,
                    startDate: ticket.created_at,
                    endDate: ticket.need_by_date,
                    description: ticket.description,
                    attachments: ticket.attachments || [],
                    progress: requestAssignment ? requestAssignment.progress : 0,
                    assignment_date: requestAssignment ? requestAssignment.created_at : 'N/A',
                    isReporter: ticket.reporter_email
                };

                setTask(taskData);
                setDescription(ticket.description);
                setStatus(requestAssignment ? requestAssignment.status : 'Not Started');
                setProgress(requestAssignment ? requestAssignment.progress : 0);
                setTeamMembers(teamMembers);
                setActiveMember(teamMembers.find(member => member.active_member));
                try {
                    const subtasksResponse = await axios.get(`/service_requests/${id}/sub_tasks`);
                    const subtasksData = subtasksResponse.data;

                    const subtasksWithLastComment = await Promise.all(subtasksData.map(async (subtask) => {
                        try {
                            const commentsResponse = await axios.get(`/service_requests/${subtask.id}/fetch_comments`);
                            const comments = commentsResponse.data;
                            const filteredComments = comments.filter(comment => comment.from_email === false);
                            const lastComment = filteredComments.length > 0 ? filteredComments[0] : null;

                            return {
                                ...subtask,
                                lastComment
                            };
                        } catch (error) {
                            console.error(`Error fetching comments for subtask ${subtask.id}:`, error);
                            return {
                                ...subtask,
                                lastComment: null
                            };
                        }
                    }));

                    setSubtasks(subtasksWithLastComment);
                } catch (error) {
                    console.error('Error fetching subtasks:', error);
                    toast.error('Error fetching subtasks');
                }

                fetchComments(ticket.id, taskData);

            } else {
                const projectTaskResponse = await axios.get(`/projects/fetch_project_tasks`);
                const projectTask = projectTaskResponse.data.find(task => task.id === parseInt(id));

                if (projectTask) {
                    const projectResponse = await axios.get(`/projects/fetch_all_projects`, {
                        params: {id: projectTask.project_id},
                    });

                    const project = projectResponse.data.project;

                    if (project && project.employees) {
                        const taskTeamMembers = projectTask.assignees.map(assignee => ({
                            id: assignee.id,
                            name: assignee.name,
                            active_member: assignee.active_member,
                        }));

                        const allProjectEmployees = project.employees.map(emp => ({
                            id: emp.id,
                            name: emp.name,
                            profile_image_url: emp.profile_image_url,
                        }));

                        const taskData = {
                            id: projectTask.id,
                            ticketNumber: projectTask.task_no,
                            createdAt: projectTask.created_at,
                            responseTime: 'N/A',
                            createdBy: projectTask.created_by,
                            ccEmployee: 'N/A',
                            task_name: projectTask.task_name,
                            milestone: projectTask.milestone_name || 'N/A',
                            rating: null,
                            remarks: null,
                            startDate: projectTask.planned_start_date,
                            endDate: projectTask.planned_due_date,
                            description: projectTask.description,
                            attachments: projectTask.attachments || [],
                            progress: 0,
                            isReporter: projectTask.reporter_email,
                            project_id: projectTask.project_id,
                        };

                        setTask(taskData);
                        setDescription(projectTask.description);
                        setStatus(projectTask ? projectTask.status : 'Not Started');
                        setProgress(projectTask ? projectTask.progress : 0);
                        setTeamMembers(taskTeamMembers.length > 0 ? taskTeamMembers : [{name: 'Not Assigned'}]);
                        setEmployees(allProjectEmployees);

                        fetchComments(projectTask.id, taskData);
                    } else {
                        toast.error("No employees found for this project");
                    }
                } else {
                    toast.error("No data found for this ID");
                }
            }
        } catch (error) {
            console.error(error);
            toast.error("Error fetching task data");
        }
    };
    const handleViewSubtask = (subtaskId) => {
        navigate(`/service_requests/${subtaskId}/details`);
    };

    const handleAddApproval = async () => {
        if (approvalEmail.length === 0) {
            toast.error('Please select at least one email.');
            return;
        }

        if (!approvalComment) {
            toast.error('Please add a comment.');
            return;
        }
        try {
            const approvals = approvalEmail.map((email) => ({
                to_email: email.value,
                comment: approvalComment,
            }));

            await axios.post(`/approvals`, {ticket_id: id, approvals});

            toast.success('Approvals added successfully.');
            handleHideApprovalModal();
            fetchApprovals();
        } catch (error) {
            toast.error('Error adding approvals.');
            console.error(error);
        }
    };


    const fetchEmployees = async (departmentId) => {
        try {
            const response = await axios.get('/employees/fetch_employee', {params: {department_id: departmentId}});
            setEmployees(response.data);
        } catch (error) {
            console.error('Error fetching employees:', error);
            toast.error("Error fetching employees.");
        }
    };

    const fetchComments = async (taskId, taskData = null) => {
        try {
            let response;
            const currentTask = taskData || task;

            if (currentTask && currentTask.task_name) {
                response = await axios.get(`/projects/${taskId}/fetch_project_task_comments`);
            } else {
                response = await axios.get(`/service_requests/${taskId}/fetch_comments`);
            }
            const statusChangeComments = response.data.filter(comment => comment.content);

            setWorklogComments(statusChangeComments);
            setComments(response.data);
        } catch (error) {
            toast.error("Error fetching comments");
            console.error(error);
        }
    };


    const formatDate = (dateString) => {
        const options = {
            day: 'numeric',
            month: 'long',
            year: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            hour12: true,
            timeZone: 'Asia/Karachi'
        };
        return new Date(dateString).toLocaleString('en-US', options);
    };

    const formatDateNeed = (dateString) => {
        const options = {
            day: 'numeric',
            month: 'long',
            year: 'numeric'
        };
        return new Date(dateString).toLocaleString('en-US', options);
    };


    const handleStatusChange = (e) => {
        const statusValue = e.target.value;
        setNewStatus(statusValue);
        setShowConfirmModal(true);
    };

    const confirmStatusChange = async () => {
        let newProgress = 0;
        if (newStatus === 'Mark for Completion' || newStatus === 'Completed') {
            newProgress = 100;
        }
        setStatus(newStatus);
        setProgress(newProgress);
        setShowConfirmModal(false);

        try {
            if (task && task.task_name) {
                await axios.post(`/projects/${task.project_id}/update_task_status`, {
                    task_id: id,
                    status: newStatus,
                    progress: newProgress
                });
                toast.success('Project task status updated successfully');
            } else {
                await axios.post(`/service_requests/${id}/update_status`, {
                    status: newStatus,
                    progress: newProgress
                });
                toast.success('Service request status updated successfully');
            }
            const newComment = {
                content: `Status changed to ${newStatus}`,
                created_at: new Date().toISOString(),
            };
            setWorklogComments(prevComments => [...prevComments, newComment]);

            setTask(prevTask => ({...prevTask, status: newStatus, progress: newProgress}));
        } catch (error) {
            toast.error('Error updating status');
            console.error(error);
        }
    };
    const handleCommentSubmit = async () => {
        if (!newComment.trim()) {
            toast.error("Please enter a comment before posting.");
            return;
        }

        if (!employeeId) {
            toast.error("Unable to fetch employee information.");
            return;
        }

        try {
            const isProjectTask = task && task.task_name;
            const requestUrl = isProjectTask
                ? `/projects/${task.id}/project_task_comments`
                : `/service_requests/${id}/create_comment`;

            const requestData = isProjectTask
                ? {project_task_comment: {content: newComment, employee_id: employeeId}}
                : {comment: {content: newComment, ticket_id: id, employee_id: employeeId}};

            await axios.post(requestUrl, requestData);

            setNewComment('');
            fetchComments(id, task);
            toast.success("Comment posted successfully");
        } catch (error) {
            toast.error("Error posting comment");
            console.error(error);
        }
    };


    const handleEmailClick = () => {
        setShowEmailModal(true);
    };

    const confirmSendEmail = async () => {
        if (!newComment.trim()) {
            toast.error("Please enter a comment before sending an email.");
            return;
        }

        if (!employeeId) {
            toast.error("Unable to fetch employee information.");
            return;
        }

        if (!toEmail || toEmail.length === 0) {
            toast.error('Please select at least one To Email.');
            return;
        }

        try {
            const toEmailString = toEmail.map(email => email.value).join(',');
            const ccEmailString = ccEmails.map(email => email.value).join(',');

            await axios.post(`/service_requests/${id}/create_comment`, {
                comment: {
                    content: newComment,
                    ticket_id: id,
                    employee_id: employeeId,
                },
                to_email: toEmailString,
                cc_email: ccEmailString,
                send_email: true,
                previous_thread: includePreviousThread
            });

            setNewComment('');
            setShowEmailModal(false);
            toast.success('Email sent successfully.');
        } catch (error) {
            toast.error('Error sending email.');
            console.error(error);
        }
    };


    const handleViewAttachment = async (attachmentId) => {
        try {
            let response;
            if (task && task.task_name) {
                response = await axios.get(`/projects/${id}/fetch_attachment/${attachmentId}`);
            } else {
                response = await axios.get(`/service_requests/${id}/fetch_attachment/${attachmentId}`);
            }
            const fileUrl = response.data.file_url;
            const newWindow = window.open(fileUrl, '_blank');
            if (newWindow) {
                newWindow.opener = null;
            } else {
                alert('Please allow popups for this website');
            }
        } catch (error) {
            console.error("Error fetching attachment:", error);
        }
    };


    const handleFileChange = (e) => {
        setNewAttachments(e.target.files);
    };

    const handleUploadAttachment = async () => {
        setShowAttachmentConfirmModal(false);
        const formData = new FormData();
        if (task && task.task_name) {
            for (let i = 0; i < newAttachments.length; i++) {
                formData.append('attachments[]', newAttachments[i]);
            }
        } else {
            for (let i = 0; i < newAttachments.length; i++) {
                formData.append('ticket[attachments_attributes][][file]', newAttachments[i]);
            }
        }
        try {
            if (task && task.task_name) {
                const response = await axios.post(`/projects/${task.id}/upload_task_attachments`, formData, {
                    headers: {'Content-Type': 'multipart/form-data'}
                });
                toast.success('Attachments uploaded successfully for project task');
                setTask(prevTask => ({
                    ...prevTask,
                    attachments: response.data.attachments
                }));
            } else {
                const response = await axios.put(`/service_requests/${id}`, formData, {
                    headers: {'Content-Type': 'multipart/form-data'}
                });
                toast.success('Attachments uploaded successfully for service request');
                setTask(prevTask => ({
                    ...prevTask,
                    attachments: response.data.attachments
                }));
            }

            setNewAttachments([]);
            fetchTask();
        } catch (error) {
            toast.error('Error uploading attachments');
            console.error(error);
        }
    };


    const handleUpdateActiveMember = async () => {
        const activeMemberIds = activeTeamMembers.map(emp => emp.value);

        try {
            if (task && task.task_name) {
                await axios.post(`/projects/${task.project_id}/update_active_member`, {
                    task_id: id,
                    active_member_ids: activeMemberIds.length > 0 ? activeMemberIds : null
                });

                toast.success('Active member updated successfully for the project task');
            } else {
                await axios.post(`/service_requests/${id}/update_status`, {
                    active_members: activeMemberIds.length > 0 ? activeMemberIds : null
                });

                toast.success('Active member updated successfully for the service request');
            }
            fetchTask();
        } catch (error) {
            toast.error('Error updating active member');
            console.error(error);
        }
    };


    const handleAddTeamMembers = async () => {
        try {
            const newEmployees = selectedEmployees.filter(selectedEmp =>
                !teamMembers.some(member => member.id === selectedEmp.value)
            );

            const employeesToAssign = newEmployees.map(emp => ({
                employee_id: emp.value,
                start_date: task.createdAt ? new Date(task.createdAt) : new Date(),
                end_date: task.endDate ? new Date(task.endDate) : null,
                remarks: emp.label,
            }));

            if (task && task.project) {
                await axios.post(`/service_requests/${id}/create_request_assignments`, {
                    request_assignments: JSON.stringify(employeesToAssign),
                });
                toast.success('Team members assigned successfully to the service request');
            } else if (task && task.task_name) {
                await handleUpdateProjectTaskEmployees(employeesToAssign.map(emp => emp.employee_id));
            }

            fetchTask();
        } catch (error) {
            toast.error('Error assigning team members');
            console.error(error);
        }

        setSelectedEmployees([]);
        setShowEmployeeModal(false);
    };


    const handleUpdateProjectTaskEmployees = async (employeeIds) => {
        const normalizedEmployeeIds = employeeIds.map(emp =>
            typeof emp === 'object' && emp !== null && 'value' in emp ? emp.value : emp
        );

        try {
            await axios.post(`/projects/${task.project_id}/update_task_team`, {
                task_id: id,
                employee_ids: normalizedEmployeeIds,
            });
            toast.success('Project task team members updated successfully');
        } catch (error) {
            toast.error('Error updating project task team members');
            console.error(error);
        }
    };

    const handleRemoveEmployeeFromTask = async (employeeId) => {
        try {
            if (!task || !task.project_id) {
                throw new Error('Project ID is not available.');
            }

            await axios.post(`/projects/${task.project_id}/update_task_team`, {
                task_id: id,
                remove: true,
                employee_ids: [employeeId]
            });

            toast.success('Team member removed successfully from the project task');
            fetchTask();
        } catch (error) {
            toast.error('Error removing team member from the project task');
            console.error(error);
        }
    };


    const handleRemoveEmployeeFromServiceRequest = async (employeeId, requestAssignmentId) => {

        try {
            await axios.post(`/service_requests/${id}/remove_request_assignment`, {
                id: id,
                employee_id: employeeId,
                request_assignment_id: requestAssignmentId,
            });
            toast.success('Team member removed successfully from the service request');
            fetchTask();
        } catch (error) {
            toast.error('Error removing team member from the service request');
            console.error(error);
        }
    };


    const handleEditClick = () => {
        setNewNeedByDate(task.endDate);
        setShowEditModal(true);
    };

    const handleSaveNeedByDate = async () => {
        try {
            await axios.put(`/service_requests/${id}`, {ticket: {need_by_date: newNeedByDate}, task_view: true});
            toast.success('Need By Date updated successfully');
            setShowEditModal(false);
            fetchTask();
        } catch (error) {
            toast.error('Error updating Need By Date');
            console.error(error);
        }
    };

    const handleSubmitRemarks = async () => {
        try {
            await axios.post(`/service_requests/${id}/submit_remarks`, {
                remarks,
                rating
            });
            setShowRemarksModal(false);
            toast.success('Remarks submitted successfully');
        } catch (error) {
            toast.error('Error submitting remarks');
            console.error(error);
        }
    };

    if (!task) {
        return (
            <div className="d-flex justify-content-center align-items-center" style={{height: '100vh'}}>
                <ClipLoader size={50} color={"#123abc"}/>
            </div>
        );
    }


    const handleCloseConfirmModal = () => {
        setNewStatus('');
        setShowConfirmModal(false);
    };

    const handleCloseAttachmentConfirmModal = () => {
        setNewAttachments([]);
        setShowAttachmentConfirmModal(false);
    };

    const handleCloseEmployeeModal = () => {
        setSelectedEmployees([]);
        setShowEmployeeModal(false);
    };

    const handleCloseEditModal = () => {
        setNewNeedByDate('');
        setShowEditModal(false);
    };

    const handleCloseEmailModal = () => {
        setShowEmailModal(false);
        setIncludePreviousThread(false);
    };

    const handleCloseRemarksModal = () => {
        setRemarks('');
        setRating(0);
        setShowRemarksModal(false);
    };

    const handleCloseRemoveConfirmModal = () => {
        setEmployeeToRemove(null);
        setShowRemoveConfirmModal(false);
    };


    const sanitizeContent = (htmlContent) => {
        return DOMPurify.sanitize(htmlContent, {FORBID_TAGS: ['img']});
    };
    return (
        <React.Fragment>
            {!(fromEmail || (currentUser && currentUser.target)) && (
                <Breadcrumb>
                    <Breadcrumb.Item onClick={() => navigate(-1)} style={{ cursor: 'pointer' }}>
                        <FontAwesomeIcon icon={faArrowLeft}/> Back
                    </Breadcrumb.Item>
                    <Breadcrumb.Item active>Task Details</Breadcrumb.Item>
                </Breadcrumb>
            )}

            <Container fluid className="mt-5 p-4 bg-gradient rounded shadow">
                <Toaster position="top-center" reverseOrder={false}/>

                <h5 className="text-center" style={{borderBottom: '1px solid #4973AB', paddingBottom: '10px'}}>
                    {task.ticketNumber}: &nbsp;&nbsp; {task.project ? task.project : task.task_name}
                </h5>
                {hasRequestAssignment && !fromEmail && !fromNotification && (

                    <Card.Body>
                        <Row className="mb-3">
                            <Col md={4} className="d-flex">
                                <button className="btn btn-primary btn-sm mb-1 mr-2"
                                        onClick={() => setShowSubtaskModal(true)}>
                                    <FontAwesomeIcon icon={faPlus} size="xs" style={{marginRight: '5px'}}/> <small>Create
                                    Subtask</small>
                                </button>

                                <button className="btn btn-primary btn-sm mb-1">
                                    <FontAwesomeIcon icon={faLink} size="xs" style={{marginRight: '5px'}}/> <small>Link
                                    Task</small>
                                </button>
                            </Col>
                            <Col md={2} className="d-flex justify-content-center">
                                <button className="btn btn-outline-secondary btn-sm mr-2" style={{height: '30px'}}
                                        onClick={handleEditClick}>
                                    <FontAwesomeIcon icon={faEdit} size="xs"/>
                                </button>
                                <button className="btn btn-outline-secondary btn-sm mr-2" style={{height: '30px'}}>
                                    <FontAwesomeIcon icon={faSyncAlt} size="xs"/>
                                </button>
                                <button className="btn btn-outline-danger btn-sm" style={{height: '30px'}}>
                                    <FontAwesomeIcon icon={faTimesCircle} size="xs"/>
                                </button>
                            </Col>
                        </Row>
                        <Row className="mb-3">
                            <Col md={4}>
                                <Select
                                    isMulti
                                    value={activeTeamMembers}
                                    options={teamMembers.map(member => ({value: member.id, label: member.name}))}
                                    placeholder="Select Active Members"
                                    onChange={setActiveTeamMembers}
                                    isClearable={true}
                                    closeMenuOnSelect={false}
                                />

                            </Col>

                            <Col md={2}>
                                <button
                                    className="btn btn-success btn-sm w-100 d-flex align-items-center justify-content-center"
                                    onClick={handleUpdateActiveMember}
                                >
                                    <FontAwesomeIcon icon={faSyncAlt} size="xs" style={{marginRight: '5px'}}/> <small>Update
                                    Active Member</small>
                                </button>
                            </Col>
                        </Row>
                        <Row className="mb-3">
                            <Col md={4}>
                                <ProgressBar now={progress} label={`${progress}% Done`} style={{height: '35px'}}/>
                            </Col>
                            <Col md={2}>
                                <Form.Group>
                                    <Form.Control
                                        style={{height: '35px', fontSize: '13px'}}
                                        as="select"
                                        value={status}
                                        onChange={handleStatusChange}
                                    >
                                        <option value="Not Started">Not Started</option>
                                        <option value="In-Process">In-Process</option>
                                        <option value="Waiting for Purchase">Waiting for Purchase</option>
                                        <option value="Waiting for Approval">Waiting for Approval</option>
                                        <option value="Waiting for Quotation">Waiting for Quotation</option>
                                        <option value="Waiting for Budget">Waiting for Budget</option>
                                        <option value="Waiting for Acknowledgement">Waiting for Acknowledgement</option>
                                        <option value="Waiting for GRN">Waiting for GRN</option>
                                        <option value="Waiting for PR">Waiting for PR</option>
                                        <option value="Completed">Completed</option>
                                        <option value="Closed">Closed</option>
                                        <option value="On Hold">On Hold</option>
                                        <option value="Cancelled">Cancelled</option>
                                    </Form.Control>
                                </Form.Group>
                            </Col>
                        </Row>
                        <TaskProgress
                            taskCreatedAt={task && task.createdAt}
                            taskStatusChanges={statusChanges}
                            assignmentDate={task && task.assignment_date}
                        />
                    </Card.Body>
                )}
                <Row>
                    <Col md={8}>
                        <Card className="mb-3">
                            <Card.Header className="custom-card-header">Service Request Info</Card.Header>
                            <Card.Body>
                                <table className="table table-borderless" style={{width: '100%'}}>
                                    <tbody>
                                    <tr>
                                        <td><strong>Sr #</strong></td>
                                        <td>{task.ticketNumber}</td>
                                        <td><strong>Created At:</strong></td>
                                        <td>{formatDate(task.createdAt)}</td>
                                    </tr>
                                    <tr>
                                        <td><strong>Need By Date:</strong></td>
                                        <td>{task.endDate ? formatDateNeed(task.endDate) : 'N/A'}</td>
                                        <td><strong>Created By:</strong></td>
                                        <td>{task.createdBy}</td>

                                    </tr>
                                    {/*<tr>*/}
                                    {/*    <td><strong>Created By:</strong></td>*/}
                                    {/*    <td>{task.createdBy}</td>*/}
                                    {/*</tr>*/}
                                    <tr>
                                        <td><strong>CC Employee:</strong></td>
                                        <td>{task.ccEmployee}</td>
                                        <td><strong>On Behalf Of:</strong></td>
                                        <td>{task.on_behalf_of ? task.reporter_email : '-'}</td>
                                    </tr>
                                    </tbody>
                                </table>
                            </Card.Body>
                        </Card>
                        {!fromEmail &&  (
                            <React.Fragment>
                                <Card className="mb-3">
                                    <Card.Header className="custom-card-header">Description</Card.Header>
                                    <Card.Body>
                                        <div className="form-group">
                                            <EditorProvider>
                                                <Editor
                                                    value={sanitizeContent(description)}
                                                    onChange={(value) => setDescription(value)}
                                                    disabled
                                                    style={{
                                                        height: '350px',
                                                        width: '100%',
                                                        overflowX: 'auto',
                                                        overflowY: 'auto',
                                                        whiteSpace: 'pre-wrap',
                                                        wordWrap: 'break-word'
                                                    }}
                                                />
                                            </EditorProvider>

                                        </div>
                                    </Card.Body>
                                </Card>
                                {!fromNotification && (

                                <Card className="mb-3">
                                    <Card.Header className="custom-card-header">Discussion</Card.Header>
                                    <Card.Body>
                                        <EditorProvider>
                                            <Editor
                                                value={newComment}
                                                onChange={(e) => setNewComment(e.target.value)}
                                                placeholder="Add a comment..."
                                                disabled={!hasRequestAssignment || status === "Completed"}
                                            />
                                        </EditorProvider>
                                        <Button className="btn btn-primary btn-sm mt-2" onClick={handleCommentSubmit}>
                                            <i className="ri-chat-2-line"></i> Post
                                        </Button>
                                        <Button className="btn btn-primary btn-sm mt-2" onClick={handleEmailClick}
                                                style={{marginLeft: '10px'}}>
                                            <i className="ri-mail-add-line"></i> Email
                                        </Button>
                                    </Card.Body>
                                </Card>
                                )}

                            </React.Fragment>
                        )}
                        <div className="iq-card">
                            <Card.Header className="custom-card-header">Work Log</Card.Header>
                            <div className="iq-card-body"
                                 style={{maxHeight: '500px', overflowY: 'auto', overflowX: 'auto'}}>
                                <ul className="iq-timeline">
                                    {comments.map(comment => (
                                        <li key={comment.id}>
                                            <div className="timeline-dots"></div>
                                            <h6 className="float-left mb-1">
                                                {comment.from_email && <i className="ri-mail-add-line"></i>}
                                                {comment.sender ? comment.sender : comment.employee.name}
                                            </h6>
                                            <small className="float-right mt-1">
                                                {new Date(comment.created_at).toLocaleString()}
                                            </small>
                                            <div className="d-inline-block w-100">
                                                <EditorProvider>
                                                    <div style={{
                                                        overflowX: 'auto',
                                                        maxWidth: '100%',
                                                        whiteSpace: 'nowrap'
                                                    }}>
                                                        <div style={{display: 'inline-block', minWidth: '100%'}}>
                                                            <Editor
                                                                value={comment.content}
                                                                disabled
                                                            />
                                                        </div>
                                                    </div>
                                                </EditorProvider>


                                                <div className="attachments">
                                                    {comment.attachments && comment.attachments.map(attachment => (
                                                        <a
                                                            key={attachment.id}
                                                            href={attachment.file_url}
                                                            target="_blank"
                                                            rel="noopener noreferrer"
                                                            style={{display: 'inline-block', margin: '5px'}}
                                                        >
                                                            <img
                                                                src={attachment.thumbnail_url || attachment.file_url}
                                                                alt={attachment.file_file_name}
                                                                style={{
                                                                    width: '100px',
                                                                    height: '100px',
                                                                    objectFit: 'cover',
                                                                    borderRadius: '4px',
                                                                    cursor: 'pointer',
                                                                }}
                                                            />
                                                        </a>
                                                    ))}
                                                </div>
                                            </div>
                                        </li>
                                    ))}
                                </ul>


                            </div>
                        </div>
                    </Col>
                    <Col md={4}>
                        <Card className="mb-3">
                            <Card.Header className="custom-card-header">Rating & Remarks</Card.Header>
                            <Card.Body className="text-center" style={{borderBottom: '1px solid #4973AB'}}>
                                <StarRatings
                                    rating={task.rating || 0}
                                    starRatedColor="navy"
                                    numberOfStars={5}
                                    name='rating'
                                />
                            </Card.Body>
                            <Card.Body className="text-center">
                                <p>{task.remarks}</p>
                            </Card.Body>
                        </Card>
                        <Card className="mb-3">
                            <Card.Header className="custom-card-header">Team</Card.Header>
                            <ListGroup variant="flush">
                                {teamMembers.map(member => (
                                    <ListGroup.Item key={member.id}
                                                    className="d-flex justify-content-between align-items-center">
                                        <div className="d-flex align-items-center">
                                            <span
                                                className="dot"
                                                style={{
                                                    height: '10px',
                                                    width: '10px',
                                                    backgroundColor: member.active_member ? 'green' : 'gray',
                                                    borderRadius: '50%',
                                                    display: 'inline-block',
                                                    marginRight: '10px'
                                                }}
                                            />
                                            {member.name}
                                        </div>
                                        {(!fromEmail && !fromNotification && (currentUserType === "Administration" || currentUserType === "SR Manager") && (hasRequestAssignment || (task && task.task_name))) && (
                                            <button
                                                className="btn btn-outline-danger btn-sm"
                                                style={{height: '30px'}}
                                                onClick={() => {
                                                    setEmployeeToRemove(member);
                                                    setShowRemoveConfirmModal(true);
                                                }}
                                            >
                                                <FontAwesomeIcon icon={faTimesCircle} size="xs"/>
                                            </button>
                                        )}
                                    </ListGroup.Item>
                                ))}
                            </ListGroup>

                            {(!fromEmail && !fromNotification &&  (currentUserType === "Administration" || currentUserType === "SR Manager") && (hasRequestAssignment || (task && task.task_name))) && (
                                <Card.Body>
                                    <button className="btn btn-primary btn-sm" style={{height: '30px'}}
                                            onClick={() => setShowEmployeeModal(true)}>Manage
                                    </button>
                                </Card.Body>
                            )}

                        </Card>

                        <Card className="mb-3">
                            <Card.Header className="custom-card-header">Attachments</Card.Header>
                            <Card.Body>
                                {hasRequestAssignment && !fromEmail && !fromNotification &&(
                                    <OverlayTrigger
                                        placement="top"
                                        overlay={<Tooltip id={`tooltip-view-task`}>Upload New Attachments</Tooltip>}
                                    >
                                        <Button variant="success" size="sm"
                                                onClick={() => document.getElementById('fileInput').click()}
                                                className="custom-height d-flex align-items-center">
                                            <FontAwesomeIcon icon={faPlus}/>
                                        </Button>
                                    </OverlayTrigger>
                                )}
                                <input
                                    type="file"
                                    id="fileInput"
                                    style={{display: 'none'}}
                                    multiple
                                    onChange={(e) => {
                                        setNewAttachments(e.target.files);
                                        setShowAttachmentConfirmModal(true);
                                    }}
                                />
                                {task.attachments && task.attachments.length > 0 && (
                                    <ListGroup variant="flush" className="mt-3">
                                        {task.attachments.map((attachment, index) => (
                                            <ListGroup.Item key={index}
                                                            className="d-flex justify-content-between align-items-center">
                                                <span>{attachment.file_file_name}</span>
                                                <FontAwesomeIcon
                                                    icon={faEye}
                                                    style={{cursor: 'pointer'}}
                                                    onClick={() => handleViewAttachment(attachment.id)}
                                                />
                                            </ListGroup.Item>
                                        ))}
                                    </ListGroup>
                                )}

                            </Card.Body>
                        </Card>


                        {/* Sub Tasks Card */}
                        <Card className="mb-3">
                            <Card.Header className="custom-card-header">Sub Tasks</Card.Header>
                            <Card.Body>
                                <div className="table-responsive">
                                    {subtasks.length > 0 ? (
                                        <table className="table mb-0 table-borderless">
                                            <thead>
                                            <tr>
                                                <th scope="col" className={"font-size-12 font-weight-bold"}>SR Number
                                                </th>
                                                <th scope="col" className={"font-size-12 font-weight-bold"}>Status</th>
                                                <th scope="col" className={"font-size-12 font-weight-bold"}>Created At
                                                </th>
                                                <th scope="col" className={"font-size-12 font-weight-bold"}>Comment</th>
                                            </tr>
                                            </thead>
                                            <tbody style={{ fontSize: '11px' }}>
                                            {subtasks.map((subtask) => (
                                                <tr key={subtask.id}>
                                                    <td>{subtask.ticket_number}</td>
                                                    <td>
                                                        {subtask.request_assignments && subtask.request_assignments.length > 0
                                                            ? subtask.request_assignments[0].status
                                                            : 'Unassigned'}
                                                    </td>
                                                    <td>{new Date(subtask.created_at).toLocaleDateString()}</td>
                                                    <td>
                                                        <React.Fragment>
                                                            <FontAwesomeIcon
                                                                icon={faEye}
                                                                style={{ cursor: 'pointer', color: 'green' }}
                                                                onClick={() => setShowModal(true)}
                                                            />
                                                            <Modal show={showModal} onHide={() => setShowModal(false)}>
                                                                <Modal.Header>
                                                                    <Modal.Title>Last Comment</Modal.Title>
                                                                    <Button variant="link" onClick={() => setShowModal(false)}
                                                                            style={{color: 'black', padding: '0'}}>
                                                                        <FontAwesomeIcon icon={faTimes}/>
                                                                    </Button>
                                                                </Modal.Header>
                                                                <Modal.Body>
                                                                    <div className="form-group">
                                                                        <EditorProvider>
                                                                            <Editor
                                                                                value={
                                                                                    subtask.lastComment && subtask.lastComment.content
                                                                                        ? subtask.lastComment.content
                                                                                        : 'No comment'
                                                                                }
                                                                                disabled={true}
                                                                                style={{
                                                                                    height: '200px',
                                                                                    width: '100%',
                                                                                    overflowX: 'auto',
                                                                                    overflowY: 'auto',
                                                                                    whiteSpace: 'pre-wrap',
                                                                                    wordWrap: 'break-word',
                                                                                }}
                                                                            />
                                                                        </EditorProvider>
                                                                    </div>
                                                                </Modal.Body>
                                                            </Modal>
                                                        </React.Fragment>
                                                    </td>
                                                </tr>
                                            ))}
                                            </tbody>
                                        </table>
                                    ) : (
                                        <p>No subtasks available.</p>
                                    )}
                                </div>
                            </Card.Body>
                        </Card>

                        <Card className="mb-3">
                            <Card.Header className="custom-card-header">Approvals</Card.Header>
                            <div>
                                <div className="table-responsive mb-3">
                                    {approvals.length > 0 ? (
                                        <table className="table mb-0 table-borderless">
                                            <thead>
                                            <tr>
                                                <th scope="col" className="font-size-12 font-weight-bold">To Email</th>
                                                <th scope="col" className="font-size-12 font-weight-bold">Comment</th>
                                                <th scope="col" className="font-size-12 font-weight-bold">Status</th>
                                                <th scope="col" className="font-size-12 font-weight-bold">Action</th>
                                            </tr>
                                            </thead>
                                            <tbody style={{ fontSize: '11px' }}>
                                            {approvals.map((approval) => (
                                                <tr key={approval.id}>
                                                    <td>{approval.to_email}</td>
                                                    <td>{approval.comment}</td>
                                                    <td>
                                                        {approval.status === 'Approved' ? (
                                                            <span className="text-success">
                      <FontAwesomeIcon icon={faCheckCircle} /> Approved
                    </span>
                                                        ) : approval.status === 'Rejected' ? (
                                                            <span className="text-danger">
                      <FontAwesomeIcon icon={faTimesCircle} /> Rejected
                    </span>
                                                        ) : (
                                                            <span className="text-info">Pending Approval</span>
                                                        )}
                                                    </td>
                                                    <td>
                                                        {approval.status === 'Pending Approval' && currentUser.email === approval.to_email ? (
                                                            <FontAwesomeIcon
                                                                icon={faEdit}
                                                                style={{ cursor: 'pointer', color: 'blue' }}
                                                                onClick={() => {
                                                                    setSelectedApproval(approval);
                                                                    setApprovalAction(''); // Initialize to empty
                                                                    setApprovalFeedback(''); // Clear any previous feedback
                                                                    setModalMode('edit');
                                                                    setShowApprovalActionModal(true);
                                                                }}
                                                            />
                                                        ) : (approval.status === 'Approved' || approval.status === 'Rejected') ? (
                                                            <FontAwesomeIcon
                                                                icon={faEye}
                                                                style={{ cursor: 'pointer', color: 'green' }}
                                                                onClick={() => {
                                                                    setSelectedApproval(approval);
                                                                    setApprovalAction(approval.status);
                                                                    setApprovalFeedback(approval.feedback || '');
                                                                    setModalMode('view');
                                                                    setShowApprovalActionModal(true);
                                                                }}
                                                            />
                                                        ) : null}
                                                    </td>

                                                </tr>
                                            ))}
                                            </tbody>
                                        </table>
                                    ) : (
                                        <p className="mt-3 text-center">No approvals found for this ticket.</p>
                                    )}
                                </div>
                                <Button className={'ml-3'} variant="primary" size="sm" onClick={() => setShowApprovalModal(true)}>
                                    <FontAwesomeIcon icon={faPlus} /> Add
                                </Button>
                            </div>
                        </Card>
                        {/* Approval Action Modal */}
                        {/* Approval Action Modal */}
                        <Modal show={showApprovalActionModal} onHide={handleCloseApprovalActionModal}>
                            <Modal.Header>
                                <Modal.Title>{modalMode === 'view' ? 'Approval Details' : 'Approval Action'}</Modal.Title>
                                <Button variant="link" onClick={handleCloseApprovalActionModal} style={{ color: 'black', padding: '0' }}>
                                    <FontAwesomeIcon icon={faTimes} />
                                </Button>
                            </Modal.Header>
                            <Modal.Body>
                                <Form>
                                    <Form.Group controlId="approvalActionSelect">
                                        <Form.Label>Action</Form.Label>
                                        <Form.Control
                                            as="select"
                                            value={approvalAction}
                                            onChange={(e) => setApprovalAction(e.target.value)}
                                            disabled={modalMode === 'view'}
                                        >
                                            <option value="">Select Action</option>
                                            <option value="Approved">Approve</option>
                                            <option value="Rejected">Reject</option>
                                        </Form.Control>
                                    </Form.Group>
                                    <Form.Group controlId="approvalFeedback">
                                        <Form.Label>Feedback</Form.Label>
                                        <Form.Control
                                            as="textarea"
                                            rows={3}
                                            value={approvalFeedback}
                                            onChange={(e) => setApprovalFeedback(e.target.value)}
                                            placeholder="Enter your feedback here..."
                                            readOnly={modalMode === 'view'}
                                        />
                                    </Form.Group>
                                </Form>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="secondary" onClick={handleCloseApprovalActionModal}>
                                    {modalMode === 'view' ? 'Close' : 'Cancel'}
                                </Button>
                                {modalMode === 'edit' && (
                                    <Button variant="primary" onClick={handleApprovalActionSubmit}>
                                        Submit
                                    </Button>
                                )}
                            </Modal.Footer>
                        </Modal>


                    </Col>
                </Row>

                <Modal show={showConfirmModal} onHide={handleCloseConfirmModal}>
                    <Modal.Header style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                        <Modal.Title>Confirmation</Modal.Title>
                        <Button variant="link" onClick={() => setShowConfirmModal(false)}
                                style={{color: 'black', padding: '0'}}>
                            <FontAwesomeIcon icon={faTimes}/>
                        </Button>
                    </Modal.Header>
                    <Modal.Body>Are you sure you want to update the status?</Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => setShowConfirmModal(false)}>
                            No
                        </Button>
                        <Button variant="primary" onClick={confirmStatusChange}>
                            Yes
                        </Button>
                    </Modal.Footer>
                </Modal>

                <Modal show={showAttachmentConfirmModal} onHide={handleCloseAttachmentConfirmModal}>
                    <Modal.Header style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                        <Modal.Title>Upload Attachment</Modal.Title>
                        <Button variant="link" onClick={handleCloseAttachmentConfirmModal}
                                style={{color: 'black', padding: '0'}}>
                            <FontAwesomeIcon icon={faTimes}/>
                        </Button>
                    </Modal.Header>
                    <Modal.Body>Are you sure you want to upload the selected files?</Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleCloseAttachmentConfirmModal}>
                            No
                        </Button>
                        <Button variant="primary" onClick={handleUploadAttachment}>
                            Yes
                        </Button>
                    </Modal.Footer>
                </Modal>

                <Modal show={showEmployeeModal} onHide={handleCloseEmployeeModal}>
                    <Modal.Header>
                        <Modal.Title>Select Team Member</Modal.Title>
                        <Button variant="link" onClick={handleCloseEmployeeModal}
                                style={{color: 'black', padding: '0'}}>
                            <FontAwesomeIcon icon={faTimes}/>
                        </Button>
                    </Modal.Header>
                    <Modal.Body>
                        <div style={styles.employeeList}>
                            {employees.map((employee) => (
                                <div
                                    key={employee.id}
                                    className={`employee-item ${selectedEmployees.some(sel => sel.value === employee.id) ? 'selected' : ''}`}
                                    onClick={() => {
                                        if (teamMembers.some(member => member.id === employee.id)) {
                                            return; // Disable click if already a team member
                                        }
                                        setSelectedEmployees(prevSelected =>
                                            prevSelected.some(sel => sel.value === employee.id)
                                                ? prevSelected.filter(sel => sel.value !== employee.id)
                                                : [...prevSelected, {value: employee.id, label: employee.name}]
                                        );
                                    }}
                                    style={{
                                        ...styles.employeeItem,
                                        boxShadow: selectedEmployees.some(sel => sel.value === employee.id) ? '0 0 15px rgba(0, 255, 0, 0.5)' : '0 0 10px rgba(0, 0, 0, 0.1)',
                                        cursor: teamMembers.some(member => member.id === employee.id) ? 'not-allowed' : 'pointer',
                                        opacity: teamMembers.some(member => member.id === employee.id) ? 0.5 : 1,
                                    }}
                                >
                                    <img src={employee.profile_image_url} alt={employee.name}
                                         style={styles.employeeAvatar}/>
                                    <div style={{marginTop: '10px'}}>{employee.name}</div>
                                    <input
                                        type="checkbox"
                                        style={styles.employeeCheckbox}
                                        checked={selectedEmployees.some(sel => sel.value === employee.id)}
                                        readOnly
                                        disabled={teamMembers.some(member => member.id === employee.id)}
                                    />
                                </div>
                            ))}
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleCloseEmployeeModal}>Close</Button>
                        <Button variant="primary" onClick={handleAddTeamMembers}>Add Team Members</Button>
                    </Modal.Footer>
                </Modal>

                <Modal show={showEditModal} onHide={handleCloseEditModal}>
                    <Modal.Header style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                        <Modal.Title>Edit Need By Date</Modal.Title>
                        <Button variant="link" onClick={handleCloseEditModal}
                                style={{color: 'black', padding: '0'}}>
                            <FontAwesomeIcon icon={faTimes}/>
                        </Button>
                    </Modal.Header>
                    <Modal.Body>
                        <Form.Group controlId="needByDate">
                            <Form.Label>Need By Date</Form.Label>
                            <Form.Control
                                type="date"
                                value={newNeedByDate}
                                min={new Date().toISOString().split('T')[0]}
                                onChange={(e) => setNewNeedByDate(e.target.value)}
                            />
                        </Form.Group>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleCloseEditModal}>
                            Cancel
                        </Button>
                        <Button variant="primary" onClick={handleSaveNeedByDate}>
                            Save
                        </Button>
                    </Modal.Footer>
                </Modal>

                <Modal show={showEmailModal} onHide={handleCloseEmailModal} size="lg">
                    <Modal.Header style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                        <Modal.Title>Send Email</Modal.Title>
                        <Button variant="link" onClick={handleCloseEmailModal} style={{color: 'black', padding: '0'}}>
                            <FontAwesomeIcon icon={faTimes}/>
                        </Button>
                    </Modal.Header>
                    <Modal.Body>
                        <Form.Group controlId="toEmail">
                            <Form.Label>To Email</Form.Label>
                            <Select
                                value={toEmail}
                                onChange={setToEmail}
                                options={searchValue ? employeeOptions : toEmail.length > 0 ? toEmail : []}
                                onInputChange={handleInputChange}
                                isLoading={loadingEmployees}
                                placeholder="Search recipient email by typing"
                                isClearable
                                isMulti
                                noOptionsMessage={() => "Type to search employees"}
                            />
                        </Form.Group>

                        <Form.Group controlId="ccEmail">
                            <Form.Label>CC Email</Form.Label>
                            <CreatableSelect
                                value={ccEmails}
                                onChange={setCcEmails}
                                options={searchValue ? employeeOptions : ccEmails.length > 0 ? ccEmails : []}
                                onInputChange={handleInputChange}
                                isMulti
                                isLoading={loadingEmployees}
                                placeholder="Search and select CC emails"
                                noOptionsMessage={() => "Type to search employees or add new email"}
                            />
                        </Form.Group>
                        <Form.Group controlId="includePreviousThreadCheckbox" className={'mt-3'}>
                            <Form.Check
                                type="checkbox"
                                label="Want Previous Thread ?"
                                checked={includePreviousThread}
                                onChange={(e) => setIncludePreviousThread(e.target.checked)}
                            />
                        </Form.Group>

                    </Modal.Body>


                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleCloseEmailModal}>
                            No
                        </Button>
                        <Button variant="primary" onClick={confirmSendEmail}>
                            Yes
                        </Button>
                    </Modal.Footer>
                </Modal>
                <Modal show={showRemoveConfirmModal} onHide={handleCloseRemoveConfirmModal}>
                    <Modal.Header>
                        <Modal.Title>Confirm Removal</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        Are you sure you want to remove {employeeToRemove && employeeToRemove.name} from this task?
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleCloseRemoveConfirmModal}>
                            Cancel
                        </Button>
                        <Button variant="danger" onClick={async () => {
                            if (task && task.task_name) {
                                await handleRemoveEmployeeFromTask(employeeToRemove.id);
                            } else {
                                await handleRemoveEmployeeFromServiceRequest(employeeToRemove.id, employeeToRemove.request_assignment_id);
                            }
                            handleCloseRemoveConfirmModal();
                        }}>
                            Remove
                        </Button>

                    </Modal.Footer>
                </Modal>

                <Modal show={showRemarksModal} onHide={handleCloseRemarksModal}>
                    <Modal.Header style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                        <Modal.Title>Submit Remarks and Rating</Modal.Title>
                        <Button variant="link" onClick={handleCloseRemarksModal}
                                style={{color: 'black', padding: '0'}}>
                            <FontAwesomeIcon icon={faTimes}/>
                        </Button>
                    </Modal.Header>
                    <Modal.Body>
                        <Form.Group controlId="remarks">
                            <Form.Label>Remarks</Form.Label>
                            <Form.Control
                                as="textarea"
                                rows={3}
                                value={remarks}
                                onChange={(e) => setRemarks(e.target.value)}
                            />
                        </Form.Group>
                        <Form.Group controlId="rating">
                            <Form.Label>Rating</Form.Label>
                            <StarRatings
                                rating={rating}
                                starRatedColor="blue"
                                changeRating={(newRating) => setRating(newRating)}
                                numberOfStars={5}
                                name='rating'
                            />
                        </Form.Group>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => setShowRemarksModal(false)}>
                            Cancel
                        </Button>
                        <Button variant="primary" onClick={handleSubmitRemarks}>
                            Submit
                        </Button>
                    </Modal.Footer>
                </Modal>
                <CreateSubtaskModal
                    show={showSubtaskModal}
                    onHide={() => setShowSubtaskModal(false)}
                    taskId={id} // Pass the parent ticket ID
                    currentUser={currentUser}
                    fetchTask={fetchTask}
                />
                <ApprovalModal
                    show={showApprovalModal}
                    onHide={handleHideApprovalModal}
                    approvalEmail={approvalEmail}
                    setApprovalEmail={setApprovalEmail}
                    approvalComment={approvalComment}
                    setApprovalComment={setApprovalComment}
                    handleAddApproval={handleAddApproval}
                />
            </Container>
        </React.Fragment>
    );
};

const styles = {
    employeeList: {
        display: 'grid',
        gridTemplateColumns: 'repeat(auto-fill, minmax(150px, 1fr))',
        gap: '20px',
    },
    employeeItem: {
        backgroundColor: '#f8f9fa',
        padding: '15px',
        borderRadius: '8px',
        boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
        transition: 'transform 0.2s',
        position: 'relative',
        textAlign: 'center',
    },
    employeeAvatar: {
        display: 'block',
        margin: '0 auto',
        borderRadius: '50%',
        width: '100px',
        height: '100px',
    },
    employeeCheckbox: {
        position: 'absolute',
        left: '50%',
        transform: 'translateX(-50%)',
        cursor: 'pointer',
    },
};

export default TaskDetail;
