import React, { useEffect, useState, useRef } from 'react';
import { Container, Row, Col, Card, Button, ProgressBar, Modal, Form, Dropdown, Accordion, useAccordionButton } from 'react-bootstrap';
import { FaStar, FaEllipsisV, FaPlus, FaMinus } from 'react-icons/fa';
import axios from 'axios';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { Badge } from "reactstrap";
import toast, { Toaster } from 'react-hot-toast';
import {useNavigate} from "react-router-dom";
import ReactQuill from "react-quill";

const formConfig = [
    { name: 'name', label: '', type: 'text', required: true, placeholder: 'Enter Project Name' },
    { name: 'priority', label: '', type: 'select', options: ['Select Priority', 'Low', 'Medium', 'High Priority'], required: true },
    { name: 'est_start_date', label: 'Planned Start Date *', type: 'date', required: true },
    { name: 'est_end_date', label: 'Planned End Date *', type: 'date', required: true },
    { name: 'no_of_days_planned', label: 'No of Days (Planned)', type: 'text', required: true },
    { name: 'act_start_date', label: 'Actual Start Date', type: 'date', required: false },
    { name: 'act_end_date', label: 'Actual End Date', type: 'date', required: false },
    { name: 'no_of_days_actual', label: 'No of Days (Actual)', type: 'text', required: false },
    { name: 'description', label: 'Description', type: 'textarea', required: false },
    { name: 'client', label: 'Client', type: 'text', required: false },
];

const CustomToggle = ({ children, eventKey, onClick }) => {
    const decoratedOnClick = useAccordionButton(eventKey, onClick);

    return (
        <button
            type="button"
            onClick={decoratedOnClick}
            className="btn btn-primary"
        >
            {children}
        </button>
    );
};

const EmployeeCard = ({ employee, selectedEmployees, setSelectedEmployees }) => {
    const isSelected = selectedEmployees.includes(employee.id);

    const handleCheckboxChange = () => {
        if (isSelected) {
            setSelectedEmployees(prev => prev.filter(id => id !== employee.id));
        } else {
            setSelectedEmployees(prev => [...prev, employee.id]);
        }
    };

    return (
        <Col md={4} lg={3} className="mb-3 text-center">
            <div onClick={handleCheckboxChange} style={{ cursor: 'pointer' }}>
                <img
                    src={employee.profile_image_url}
                    alt={employee.name}
                    className={`rounded-circle ${isSelected ? 'border border-primary' : ''}`}
                    style={{ width: '60px', height: '60px' }}
                />
                <p className="mt-2">{employee.name}</p>
                <input
                    type="checkbox"
                    checked={isSelected}
                    onChange={handleCheckboxChange}
                    style={{ display: 'block', margin: '0 auto' }}
                />
            </div>
        </Col>
    );
};

const ProjectCard = ({ project, onDelete, onEdit }) => {
    const [isStarred, setIsStarred] = useState(false);
    const navigate = useNavigate();

    const toggleStar = () => setIsStarred(!isStarred);

    const handleSelect = (action) => {
        if (action === 'edit') {
            onEdit(project);
        } else if (action === 'delete') {
            onDelete(project.name);
        }
    };

    const employees = project.employees || [];
    const displayedMembers = employees.slice(0, 4);
    const remainingCount = employees.length > 4 ? employees.length - 4 : 0;

    return (
        <Card className="mb-3" style={{ padding: '10px', borderRadius: '8px', boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)', zIndex: 1 }}>
            <Card.Header>
                <div className="d-flex justify-content-between align-items-center position-relative">
                    <div className="d-flex align-items-center" style={{ position: 'relative', width: '100%' }}>
                        <h6 className="font-weight-bold">{project.company_name}</h6>
                    </div>
                    <FaStar onClick={toggleStar} className="position-absolute" style={{ right: '40px', cursor: 'pointer', color: isStarred ? 'gold' : 'gray', fontSize: '20px' }} />
                    <div className="iq-card-header-toolbar d-flex align-items-center">
                        <div className="dropdown">
                            <span className="dropdown-toggle" id="dropdownMenuButton1" data-toggle="dropdown">
                                <i className="ri-more-fill"></i>
                            </span>
                            <div className="dropdown-menu dropdown-menu-right cursor-pointer" aria-labelledby="dropdownMenuButton1">
                                <a className="dropdown-item"  onClick={() => handleSelect('edit')}>
                                    <i className="ri-pencil-fill mr-2"></i>Edit
                                </a>
                                <a className="dropdown-item"  onClick={() => handleSelect('delete')}>
                                    <i className="ri-delete-bin-6-fill mr-2"></i>Delete
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </Card.Header>
            <p
                className="mb-2 background"
                style={{ fontSize: '14px', color: '#fff', fontWeight: "bold", borderRadius: '8px', padding: '5px', marginTop: '10px', textAlign: 'center', cursor: 'pointer' }}
                onClick={() => navigate(`/projects/${project.id}/individual_dashboard`)}
            >
                {project.name}
            </p>
            <div className="d-flex justify-content-between align-items-center mb-2" style={{ width: '100%' }}>
                <Badge
                    className={
                        project.status === 'New'
                            ? 'badge-new'
                            : project.status === 'In-Process'
                                ? 'badge-in-progress'
                                : 'badge-default'
                    }
                >
                    {project.status}
                </Badge>

                <Badge
                    className={
                        project.priority === 'High Priority'
                            ? 'badge-priority-high'
                            : project.priority === 'Medium'
                                ? 'badge-priority-medium'
                                : 'badge-priority-low'
                    }
                >
                    {project.priority}
                </Badge>
            </div>
            <div className="d-flex justify-content-start w-100 mb-2 font-weight-bold">
                <p className="mb-0" style={{ fontSize: '12px', color: '#6c757d' }}>Milestones:</p>
                <p className="mb-0 ml-1" style={{ fontSize: '12px', color: '#000' }}>{project.milestones ? project.milestones.length : 0}</p>
            </div>
            <div className="position-relative mb-1">
                <ProgressBar now={project.progress} style={{ height: '18px', borderRadius: '8px' }}>
                    <span className="position-absolute w-100 text-center font-weight-bold" style={{ color: '#000', height: '11%' }}>{project.progress}%</span>
                </ProgressBar>
            </div>
            <div className="d-flex flex-column align-items-start">
                <div className="d-flex align-items-center justify-content-between w-100">
                    <span className="font-size-12 font-weight-bold">
                        Members: {employees.length}
                    </span>
                    <div className="d-flex align-items-center">
                        {displayedMembers.map((member, idx) => (
                            <div key={idx} className="text-center" style={{ marginRight: '5px' }}>
                                <img src={member.profile_image_url} alt={member.name} className="rounded-circle" style={{ width: '30px', height: '30px' }} />
                                <div style={{ fontSize: '10px', marginTop: '2px' }}>
                                    {member.name.split(' ')[0]}
                                </div>
                            </div>
                        ))}
                        {remainingCount > 0 && (
                            <div className="d-flex align-items-center justify-content-center rounded-circle bg-light" style={{ width: '30px', height: '30px', fontSize: '12px', color: '#666', marginLeft: '3px' }}>
                                +{remainingCount}
                            </div>
                        )}
                    </div>
                </div>
                <div className="d-flex align-items-center mt-2">
                    <span className="font-size-12 font-weight-bold">Last activity: {project.last_activity || 'Undefined'}</span>
                </div>
            </div>
            <div className="d-flex flex-column align-items-start mb-2" style={{ borderTop: '1px solid #ddd', paddingTop: '10px', marginTop: '10px' }}>
                <div className="d-flex justify-content-between w-100">
                    <p className="mb-0" style={{ fontSize: '12px', fontWeight: 'bold' }}>Planned Dates:</p>
                    <div className="d-flex justify-content-start" style={{ fontSize: '12px' }}>
                        <span>Start: {project.est_start_date}</span>
                        <span style={{ color: 'red', marginLeft: '10px' }}>Due: {project.est_end_date}</span>
                    </div>
                </div>
                <div className="d-flex justify-content-between w-100 mt-2">
                    <p className="mb-0" style={{ fontSize: '12px', fontWeight: 'bold' }}>Actual Dates:</p>
                    <div className="d-flex justify-content-start" style={{ fontSize: '12px' }}>
                        <span>Start: {project.act_start_date}</span>
                        <span style={{ color: 'red', marginLeft: '10px' }}>Due: {project.act_end_date}</span>
                    </div>
                </div>
            </div>
        </Card>
    );
};

const ProjectsDashboard = ({ currentUser }) => {
    const [projects, setProjects] = useState([]);
    const [employees, setEmployees] = useState([]);
    const [selectedEmployees, setSelectedEmployees] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [editMode, setEditMode] = useState(false);
    const [editProject, setEditProject] = useState(null);
    const [description, setDescription] = useState('');
    const [newProject, setNewProject] = useState(() => {
        const initialValues = {};
        formConfig.forEach(field => {
            initialValues[field.name] = '';
        });
        return initialValues;
    });

    const handleChange = (e, isQuill = false) => {
        const { name, value } = e.target;
        if (isQuill) {
            setDescription(value);
        } else {
            setNewProject(prevState => ({
                ...prevState,
                [name]: value
            }));
        }
    };



    const fetchEmployeeId = async () => {
        try {
            const currentUserEmail = currentUser.email;
            const response = await axios.get('/employees/fetch_employee', {
                params: { official_email: currentUserEmail }
            });
            const employee = response.data[0];

            if (employee) {
                return {
                    companyId: employee.location.company.id,
                    departmentId: employee.department_id,
                };
            } else {
                toast.error("Current Employee record not found.");
                return null;
            }
        } catch (error) {
            console.error('Error fetching employee details:', error);
            toast.error("Error fetching employee details.");
            return null;
        }
    };

    const fetchEmployees = async (departmentId) => {
        try {
            const response = await axios.get('/employees/fetch_employee', {
                params: { department_id: departmentId }
            });
            setEmployees(response.data);
        } catch (error) {
            console.error('Error fetching employees:', error);
            toast.error("Error fetching employees.");
        }
    };

    const handleShow = () => {
        setEditMode(false);
        setShowModal(true);
    };

    const handleEdit = async (project) => {
        setEditMode(true);
        setEditProject(project);
        setNewProject({
            ...project,
            est_start_date: project.est_start_date ? new Date(project.est_start_date).toISOString().substr(0, 10) : '',
            est_end_date: project.est_end_date ? new Date(project.est_end_date).toISOString().substr(0, 10) : '',
            act_start_date: project.act_start_date ? new Date(project.act_start_date).toISOString().substr(0, 10) : '',
            act_end_date: project.act_end_date ? new Date(project.act_end_date).toISOString().substr(0, 10) : '',
        });
        setDescription(project.description || '');
        setSelectedEmployees(project.employee_ids || []);

        const employeeDetails = await fetchEmployeeId();
        if (employeeDetails) {
            await fetchEmployees(employeeDetails.departmentId);
        }

        setShowModal(true);
    };




    const handleClose = () => {
        resetForm(); // Call the reset function when closing the modal
        setShowModal(false); // Hide the modal
        setEditMode(false); // Reset edit mode
    };

    const resetForm = () => {
        // Reset the newProject to its initial state
        setNewProject(() => {
            const initialValues = {};
            formConfig.forEach(field => {
                initialValues[field.name] = ''; // Reset all form fields to empty
            });
            return initialValues;
        });

        setDescription(''); // Reset description
        setSelectedEmployees([]); // Reset selected employees
        setEditProject(null); // Reset the project being edited
    };


    const handleSave = async () => {
        const employeeDetails = await fetchEmployeeId();
        if (!employeeDetails) return;

        const isValid = formConfig.every(field => !field.required || newProject[field.name]);

        if (isValid) {
            const projectData = {
                ...newProject,
                description,
                company_id: employeeDetails.companyId,
                employee_ids: selectedEmployees,
                employees: selectedEmployees.map(id => employees.find(emp => emp.id === id))
            };

            if (editMode) {
                axios.put(`/projects/${editProject.id}`, { project: projectData })
                    .then(response => {
                        const updatedProject = response.data.project;
                        setProjects(prevProjects =>
                            prevProjects.map(project =>
                                project.id === updatedProject.id ? { ...updatedProject, employees: projectData.employees } : project
                            )
                        );
                        setEditProject(updatedProject);
                        setShowModal(false);
                        toast.success('Project updated successfully!');
                    })
                    .catch(error => {
                        toast.error('Failed to update the project.');
                    });
            } else {
                axios.post('/projects', { project: projectData })
                    .then(response => {
                        const newProject = response.data.project;
                        setProjects(prevProjects => [...prevProjects, { ...newProject, employees: projectData.employees }]);
                        setShowModal(false);
                        toast.success('Project created successfully!');
                    })
                    .catch(error => {
                        toast.error('Failed to create the project.');
                    });
            }
        } else {
            toast.error('Please fill in all required fields.');
        }
    };

    useEffect(() => {
        axios.get('/projects/fetch_all_projects')
            .then(response => {
                setProjects(response.data.projects);
            })
            .catch(error => {
                console.error("There was an error fetching the projects!", error);
            });
    }, []);

    useEffect(() => {
        const calculateDays = (startDate, endDate) => {
            if (!startDate || !endDate) return '';
            const start = new Date(startDate);
            const end = new Date(endDate);
            const diffTime = Math.abs(end - start);
            return Math.ceil(diffTime / (1000 * 60 * 60 * 24));
        };

        const plannedDays = calculateDays(newProject.est_start_date, newProject.est_end_date);
        setNewProject(prevState => ({
            ...prevState,
            no_of_days_planned: plannedDays || ''
        }));

        const actualDays = calculateDays(newProject.act_start_date, newProject.act_end_date);
        setNewProject(prevState => ({
            ...prevState,
            no_of_days_actual: actualDays || ''
        }));
    }, [newProject.est_start_date, newProject.est_end_date, newProject.act_start_date, newProject.act_end_date]);


    const handleDelete = (projectName) => {
        setProjects(projects.filter(project => project.name !== projectName));
    };

    const renderField = (field) => {
        const inputStyle = { height: '30px', padding: '0.375rem 0.75rem', fontSize: '0.8rem', borderRadius: '8px' };

        if (field.type === 'select') {
            return (
                <Form.Control as="select" name={field.name} value={newProject[field.name] || ''} onChange={handleChange} required={field.required} placeholder={field.placeholder || ''} style={inputStyle}>
                    {field.options.map((option, idx) => (
                        <option key={idx} value={option}>{option}</option>
                    ))}
                </Form.Control>
            );
        } else if (field.type === 'textarea') {
            return (
                <Form.Control as="textarea" name={field.name} value={newProject[field.name] || ''} onChange={handleChange} style={{ ...inputStyle, height: '100px' }} />
            );
        } else {
            return (
                <Form.Control type={field.type} name={field.name} placeholder={field.placeholder || ''} value={newProject[field.name] || ''} onChange={handleChange} required={field.required} style={inputStyle} />
            );
        }
    };
    const [accordionOpen, setAccordionOpen] = useState(false);

    const handleAccordionToggle = async () => {
        setAccordionOpen(!accordionOpen);
        if (!accordionOpen) {
            const employeeDetails = await fetchEmployeeId();
            if (employeeDetails) {
                fetchEmployees(employeeDetails.departmentId);
            }
        }
    };

    return (
        <div>
        <Container fluid={true}>
            <Toaster />
            <div className="row">
                <div className="col-12">
                    <div className="iq-card">
                        <div className="row d-flex align-items-center mb-2">
                            <div className="col-md-9">
                                <div className="d-flex align-items-center">
                                    <h4 className="mb-0 p-3 ml-2">Project Dashboard</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Container>
        <Container fluid>
            <Row>
                {projects.map((project) => (
                    <Col md={3} key={project.id}>
                        <ProjectCard project={project} onDelete={handleDelete} onEdit={handleEdit} />
                    </Col>
                ))}
                <Col md={6} lg={3}>
                    <Card className="border-2 border-dashed text-center" style={{ borderRadius: '8px', borderColor: '#28a745', boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)', maxWidth: '100%', maxHeight: '100%', padding: '30%', zIndex: 0 }}>
                        <Card.Body className="d-flex justify-content-center align-items-center">
                            <Button
                                variant="outline-success"
                                style={{
                                    padding: '15px',
                                    borderRadius: '6px',
                                    border: 'dashed',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                }}
                                onClick={handleShow}
                            >
                                <FaPlus style={{ fontSize: '30px' }} />
                            </Button>
                        </Card.Body>
                        <div style={{ fontSize: '0.9rem', fontWeight: 'bold' }}>Add New Project</div>
                    </Card>
                </Col>
            </Row>

            <Modal show={showModal} onHide={handleClose} size="xl">
                <Modal.Header>
                    <Modal.Title>{editMode ? "Edit Project" : "Create New Project"}</Modal.Title>
                    <Button variant="link" onClick={handleClose} style={{ position: 'absolute', right: '15px', top: '15px' }}>
                        <FontAwesomeIcon icon={faTimes} size="lg" />
                    </Button>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Row className="mb-3" style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <Col xs={6}>
                                <div className={'background'} style={{ color: 'white', padding: '5px', borderRadius: '8px', textAlign: 'center' }}>
                                    Project Name
                                </div>
                            </Col>
                            <Col xs={4}>
                                <div className={'background'} style={{ color: 'white', padding: '5px', borderRadius: '8px', textAlign: 'center' }}>
                                    Priority
                                </div>
                            </Col>
                        </Row>
                        <Row className="mb-3" style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <Col xs={6}>
                                {renderField(formConfig.find(field => field.name === 'name'))}
                            </Col>
                            <Col xs={4}>
                                {renderField(formConfig.find(field => field.name === 'priority'))}
                            </Col>
                        </Row>
                        <Row className="mb-3">
                            <Col xs={12}>
                                <Form.Group controlId="formDescription">
                                    <ReactQuill
                                        value={description}
                                        onChange={setDescription}
                                        style={{ height: '150px' }}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row className="mb-3 mt-5">
                            <Col xs={8}>
                                <div className={'background'} style={{ color: 'white', padding: '5px', borderRadius: '8px', marginTop: '20px', textAlign: 'center' }}>
                                    Dates
                                </div>
                            </Col>
                        </Row>
                        <Row className="mb-3">
                            {formConfig.filter(field => ['est_start_date', 'est_end_date', 'no_of_days_planned', 'act_start_date', 'act_end_date', 'no_of_days_actual'].includes(field.name)).map((field, idx) => (
                                <Col xs={12} sm={6} md={4} key={idx} className="mb-3">
                                    <Form.Group controlId={`form${field.name}`}>
                                        <Form.Label>{field.label}</Form.Label>
                                        {renderField(field)}
                                    </Form.Group>
                                </Col>
                            ))}
                        </Row>

                        <Accordion>
                            <Card>
                                <Card.Header>
                                    <CustomToggle eventKey="0" onClick={handleAccordionToggle}>
                                        Team {accordionOpen ? <FaMinus /> : <FaPlus />}
                                    </CustomToggle>
                                </Card.Header>
                                <Accordion.Collapse eventKey="0">
                                    <Card.Body>
                                        <Row>
                                            {employees.length > 0 ? (
                                                employees.map(employee => (
                                                    <EmployeeCard
                                                        key={employee.id}
                                                        employee={employee}
                                                        selectedEmployees={selectedEmployees}
                                                        setSelectedEmployees={setSelectedEmployees}
                                                    />
                                                ))
                                            ) : (
                                                <p>No employees found.</p>
                                            )}
                                        </Row>
                                    </Card.Body>
                                </Accordion.Collapse>
                            </Card>
                        </Accordion>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>Close</Button>
                    <Button variant="primary" onClick={handleSave}>{editMode ? "Update" : "Save Changes"}</Button>
                </Modal.Footer>
            </Modal>
        </Container>
        </div>
    );
};

export default ProjectsDashboard;
