import React, {useState, useEffect, useRef, useCallback} from "react";
import {Form, Button, Tabs, Tab, InputGroup, FormControl, OverlayTrigger, Tooltip, Modal} from "react-bootstrap";
import axios from "axios";
import Select from 'react-select';
import DataTable from 'react-data-table-component';
import {Toaster, toast} from 'react-hot-toast';
import AssignmentFormModal from './AssignmentFormModal';
import {useNavigate} from 'react-router-dom';
import ReassignmentModal from './ReassignmentModal';
import cable from '../../../services/cable';
import Skeleton, {SkeletonTheme} from 'react-loading-skeleton';
import debounce from 'lodash.debounce';
import LinkedInSkeleton from "./LinkedInSkeleton";
import { getPendingColumns, getGeneratedColumns, getCompletedColumns } from './Columns'; // Import columns

const customStyles = {
    headCells: {
        style: {
            backgroundColor: 'hsl(253deg 61% 55%)',
            color: '#fff',
            fontSize: '13px',
            fontWeight: 'bold',
            display: 'flex',
            justifyContent: 'center'
        },
    },
    cells: {
        style: {
            display: 'flex',
            justifyContent: 'center',
            padding: '10px',
            textAlign: 'center',
        },
    },
};

const ServiceRequestList = ({ currentUser, avatarUrl }) => {
    const navigate = useNavigate();
    const assignmentFormRef = useRef();
    const [year, setYear] = useState(new Date().getFullYear());
    const [locations, setLocations] = useState([]);
    const [departments, setDepartments] = useState([]);
    const [subDepartments, setSubDepartments] = useState([]);
    const [ticketTypes, setTicketTypes] = useState([]);
    const [users, setUsers] = useState([]);

    const [pendingTickets, setPendingTickets] = useState([]);
    const [pendingTotalRows, setPendingTotalRows] = useState(0);
    const [pendingCurrentPage, setPendingCurrentPage] = useState(1);
    const [pendingFilterText, setPendingFilterText] = useState('');

    const [generatedTickets, setGeneratedTickets] = useState([]);
    const [generatedTotalRows, setGeneratedTotalRows] = useState(0);
    const [generatedCurrentPage, setGeneratedCurrentPage] = useState(1);
    const [generatedFilterText, setGeneratedFilterText] = useState('');

    const [completedTickets, setCompletedTickets] = useState([]);
    const [completedTotalRows, setCompletedTotalRows] = useState(0);
    const [completedCurrentPage, setCompletedCurrentPage] = useState(1);
    const [completedFilterText, setCompletedFilterText] = useState('');

    const [selectedLocation, setSelectedLocation] = useState(null);
    const [selectedDepartment, setSelectedDepartment] = useState(null);
    const [selectedSubDepartment, setSelectedSubDepartment] = useState(null);
    const [selectedTicketType, setSelectedTicketType] = useState(null);
    const [selectedUser, setSelectedUser] = useState(null);
    const [activeTab, setActiveTab] = useState('pending');
    const [showModal, setShowModal] = useState(false);
    const [selectedTicket, setSelectedTicket] = useState(null);
    const [showReassignModal, setShowReassignModal] = useState(false);
    const [selectedReassignTicket, setSelectedReassignTicket] = useState(null);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [ticketToDelete, setTicketToDelete] = useState(null);
    const [loading, setLoading] = useState(true);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [pendingRowsPerPage, setPendingRowsPerPage] = useState(10);
    const [generatedRowsPerPage, setGeneratedRowsPerPage] = useState(10);
    const [completedRowsPerPage, setCompletedRowsPerPage] = useState(10);

    const handleReassignClick = (ticket) => {
        setSelectedReassignTicket(ticket);
        setShowReassignModal(true);
    };

    useEffect(() => {
        const subscription = cable.subscriptions.create(
            { channel: 'ServiceRequestsChannel' },
            {
                received: (data) => {
                    if (activeTab === 'pending') {
                        fetchPendingTickets(pendingCurrentPage, pendingFilterText, pendingRowsPerPage);
                    }
                    toast.success(data, {
                        duration: 4000,
                        position: 'top-center',
                    });
                },
            }
        );

        return () => subscription.unsubscribe();
    }, [activeTab]);

    useEffect(() => {
        fetchLocations();
    }, [fetchLocations]);

    useEffect(() => {
        fetchTickets();
    }, [activeTab, pendingCurrentPage, generatedCurrentPage, completedCurrentPage]);

    const fetchTickets = useCallback(() => {
        if (activeTab === 'pending') {
            fetchPendingTickets(pendingCurrentPage, pendingFilterText, pendingRowsPerPage);
        } else if (activeTab === 'generated') {
            fetchGeneratedTickets(generatedCurrentPage, generatedFilterText, generatedRowsPerPage);
        } else if (activeTab === 'completed') {
            fetchCompletedTickets(completedCurrentPage, completedFilterText, completedRowsPerPage);
        }
    }, [activeTab, pendingCurrentPage, pendingRowsPerPage, pendingFilterText, generatedCurrentPage, generatedRowsPerPage, generatedFilterText, completedCurrentPage, completedRowsPerPage, completedFilterText]);


    useEffect(() => {
        if (selectedLocation) {
            fetchDepartments();
        }
    }, [selectedLocation]);

    useEffect(() => {
        if (selectedDepartment) {
            fetchSubDepartments();
        }
    }, [selectedDepartment]);

    useEffect(() => {
        if (selectedSubDepartment) {
            fetchTicketTypes();
        }
    }, [selectedSubDepartment]);

    const handleDeleteClick = (ticket) => {
        setTicketToDelete(ticket);
        setShowDeleteModal(true);
    };

    const debouncedFetchPendingTickets = useCallback(
        debounce(async (page, filterText) => {
            await fetchPendingTickets(page, filterText);
        }, 500),
        []
    );

    const debouncedFetchGeneratedTickets = useCallback(
        debounce(async (page, filterText) => {
            await fetchGeneratedTickets(page, filterText);
        }, 500),
        []
    );

    const debouncedFetchCompletedTickets = useCallback(
        debounce(async (page, filterText) => {
            await fetchCompletedTickets(page, filterText);
        }, 500),
        []
    );

    const fetchPendingTickets = useCallback(async (page = 1, filterText = '', perPage = 10) => {
        setLoading(true);
        try {
            const params = {
                page,
                per_page: perPage,
                year,
                location_id: selectedLocation ? selectedLocation.value : undefined,
                department_id: selectedDepartment ? selectedDepartment.value : undefined,
                sub_department_id: selectedSubDepartment ? selectedSubDepartment.value : undefined,
                ticket_type_id: selectedTicketType ? selectedTicketType.value : undefined,
                user_id: selectedUser ? selectedUser.value : undefined,
                search: filterText || undefined,
            };

            const response = await axios.get('/service_requests/fetch_pending_tickets', { params });
            setPendingTickets(response.data.tickets);
            setPendingTotalRows(response.data.total_records);
        } catch (error) {
            toast.error('Error fetching pending tickets');
        } finally {
            setLoading(false);
        }
    }, [year, selectedLocation, selectedDepartment, selectedSubDepartment, selectedTicketType, selectedUser]);




    const fetchGeneratedTickets = async (page = 1, filterText = '', perPage = 10) => {
        setLoading(true);
        try {
            const params = {
                page,
                per_page: perPage,
                year,
                location_id: selectedLocation ? selectedLocation.value : undefined,
                department_id: selectedDepartment ? selectedDepartment.value : undefined,
                sub_department_id: selectedSubDepartment ? selectedSubDepartment.value : undefined,
                ticket_type_id: selectedTicketType ? selectedTicketType.value : undefined,
                user_id: selectedUser ? selectedUser.value : undefined,
                search: filterText || undefined,
            };

            const [ticketsResponse, tasksResponse] = await Promise.all([
                axios.get('/service_requests/fetch_generated_tickets', { params }),
                axios.get('/projects/fetch_generated_tasks', { params })
            ]);

            const combinedGenerated = [...ticketsResponse.data.tickets, ...tasksResponse.data.tasks];
            setGeneratedTickets(combinedGenerated);
            setGeneratedTotalRows(ticketsResponse.data.total_records + tasksResponse.data.total_records);
        } catch (error) {
            toast.error('Error fetching generated tickets and tasks');
        } finally {
            setLoading(false);
        }
    };


    const fetchCompletedTickets = async (page = 1, filterText = '', perPage = 10) => {
        setLoading(true);
        try {
            const params = {
                page,
                per_page: perPage,
                year,
                location_id: selectedLocation ? selectedLocation.value : undefined,
                department_id: selectedDepartment ? selectedDepartment.value : undefined,
                sub_department_id: selectedSubDepartment ? selectedSubDepartment.value : undefined,
                ticket_type_id: selectedTicketType ? selectedTicketType.value : undefined,
                user_id: selectedUser ? selectedUser.value : undefined,
                search: filterText || undefined,
            };

            const [ticketsResponse, tasksResponse] = await Promise.all([
                axios.get('/service_requests/fetch_completed_tickets', { params }),
                axios.get('/projects/fetch_completed_tasks', { params })
            ]);

            const combinedCompleted = [...ticketsResponse.data.tickets, ...tasksResponse.data.tasks];
            setCompletedTickets(combinedCompleted);
            setCompletedTotalRows(ticketsResponse.data.total_records + tasksResponse.data.total_records);
        } catch (error) {
            toast.error('Error fetching completed tickets and tasks');
        } finally {
            setLoading(false);
        }
    };



    const handlePendingSearchChange = (e) => {
        const newValue = e.target.value;
        setPendingFilterText(newValue);
        debouncedFetchPendingTickets(pendingCurrentPage, newValue);
    };

    const handleGeneratedSearchChange = (e) => {
        const newValue = e.target.value;
        setGeneratedFilterText(newValue);
        debouncedFetchGeneratedTickets(generatedCurrentPage, newValue);
    };

    const handleCompletedSearchChange = (e) => {
        const newValue = e.target.value;
        setCompletedFilterText(newValue);
        debouncedFetchCompletedTickets(completedCurrentPage, newValue);
    };

    const fetchLocations = useCallback(async () => {
        setLoading(true);
        try {
            const response = await axios.get("/locations/fetch_location");
            setLocations(response.data);
        } catch (error) {
            console.error("Error fetching locations:", error);
        } finally {
            setLoading(false);
        }
    }, []);


    const fetchDepartments = async () => {
        if (!selectedLocation) return;

        const location = locations.find(loc => loc.id === selectedLocation.value);
        if (!location || !location.company_id) return;

        try {
            const response = await axios.get("/departments/fetch_dept", {params: {company_id: location.company_id}});
            setDepartments(response.data);
        } catch (error) {
            toast.error("Error fetching departments");
        }
    };

    const fetchSubDepartments = async () => {
        if (!selectedDepartment) return;

        try {
            const response = await axios.get("/main_types/fetch_main_types", {params: {department_id: selectedDepartment.value}});
            setSubDepartments(response.data);
        } catch (error) {
            toast.error("Error fetching sub-departments");
        }
    };

    const fetchTicketTypes = async () => {
        if (!selectedSubDepartment) return;

        try {
            const response = await axios.get("/ticket_types/fetch_ticket_types", {params: {main_type_id: selectedSubDepartment.value}});
            setTicketTypes(response.data);
        } catch (error) {
            toast.error("Error fetching ticket types");
        }
    };

    const confirmDelete = async () => {
        if (ticketToDelete) {
            try {
                await axios.post(`/service_requests/${ticketToDelete.id}/update_status`, {
                    status: 'Cancelled',
                });

                toast.success('Service request cancelled successfully');
                fetchTickets();
                setShowDeleteModal(false);
                setTicketToDelete(null);
            } catch (error) {
                console.error('Error cancelling service request', error);
                toast.error('Error cancelling service request');
                setShowDeleteModal(false);
            }
        }
    };

    const fetchUsers = async () => {
        try {
            const params = {};

            if (selectedLocation) {
                params.location_id = selectedLocation.value;
            }

            if (selectedDepartment) {
                params.department_id = selectedDepartment.value;
            }

            if (Object.keys(params).length > 0) {
                const response = await axios.get("/employees/fetch_employee", {params});
                setUsers(response.data);
            } else {
                setUsers([]);
            }
        } catch (error) {
            toast.error("Error fetching users");
        }
    };

    useEffect(() => {
        fetchUsers();
    }, [selectedLocation, selectedDepartment, selectedUser]);

    const handleSearch = () => {
        if (activeTab === 'pending') {
            setPendingCurrentPage(1);
            fetchPendingTickets(1, pendingFilterText, pendingRowsPerPage);
        } else if (activeTab === 'generated') {
            setGeneratedCurrentPage(1);
            fetchGeneratedTickets(1, generatedFilterText, generatedRowsPerPage);
        } else if (activeTab === 'completed') {
            setCompletedCurrentPage(1);
            fetchCompletedTickets(1, completedFilterText, completedRowsPerPage);
        }
    };

    const handleResetFilters = () => {
        setYear(new Date().getFullYear());
        setSelectedLocation(null);
        setSelectedDepartment(null);
        setSelectedSubDepartment(null);
        setSelectedTicketType(null);
        setSelectedUser(null);
        setPendingFilterText('');
        setGeneratedFilterText('');
        setCompletedFilterText('');
        setPendingCurrentPage(1);
        setGeneratedCurrentPage(1);
        setCompletedCurrentPage(1);
        handleSearch();
    };


    const handleIconClick = (row) => {
        if (currentUser && currentUser.target === true) {
            window.open(`/service_requests/${row.id}/details`, '_blank');
        } else {
            navigate(`/service_requests/${row.id}/details`);
        }
    };


    const handleCloseModal = () => {
        if (assignmentFormRef.current) {
            assignmentFormRef.current.reset();
        }
        setShowModal(false);
        setSelectedTicket(null);
    };

    const handleTaskUpdated = () => {
        setShowModal(false);
        fetchTickets();
    };


    const handleAssignTaskClick = (ticket) => {
        setSelectedTicket(ticket);
        setShowModal(true);
    };
    const handlePageChange = (page) => {
        if (activeTab === 'pending') {
            setPendingCurrentPage(page);
        } else if (activeTab === 'generated') {
            setGeneratedCurrentPage(page);
        } else if (activeTab === 'completed') {
            setCompletedCurrentPage(page);
        }
    };

// Modify the useEffect hooks to ensure they are isolated correctly
    useEffect(() => {
        if (activeTab === 'pending') {
            if (pendingFilterText !== '') {
                debouncedFetchPendingTickets(pendingCurrentPage, pendingFilterText);
            } else {
                fetchPendingTickets(pendingCurrentPage, '', pendingRowsPerPage);
            }
        }
    }, [pendingFilterText, pendingCurrentPage, pendingRowsPerPage, activeTab]); // Add `activeTab` to ensure it only runs when active

    useEffect(() => {
        if (activeTab === 'generated') {
            console.log(`Generated Tab: Page Change Triggered ${generatedCurrentPage}`); // Debug log to ensure correct context
            if (generatedFilterText !== '') {
                debouncedFetchGeneratedTickets(generatedCurrentPage, generatedFilterText);
            } else {
                fetchGeneratedTickets(generatedCurrentPage, '', generatedRowsPerPage);
            }
        }
    }, [generatedFilterText, generatedCurrentPage, generatedRowsPerPage, activeTab]); // Add `activeTab` to ensure it only runs when active

    useEffect(() => {
        if (activeTab === 'completed') {
            if (completedFilterText !== '') {
                debouncedFetchCompletedTickets(completedCurrentPage, completedFilterText);
            } else {
                fetchCompletedTickets(completedCurrentPage, '', completedRowsPerPage);
            }
        }
    }, [completedFilterText, completedCurrentPage, completedRowsPerPage, activeTab]); // Add `activeTab` to ensure it only runs when active

    const handleRowsPerPageChange = (newPerPage, page) => {
        if (activeTab === 'pending') {
            setPendingRowsPerPage(newPerPage);
            setPendingCurrentPage(page);
        } else if (activeTab === 'generated') {
            setGeneratedRowsPerPage(newPerPage);
            setGeneratedCurrentPage(page);
        } else if (activeTab === 'completed') {
            setCompletedRowsPerPage(newPerPage);
            setCompletedCurrentPage(page);
        }
    };

    const pendingColumns = getPendingColumns(handleAssignTaskClick, handleDeleteClick, handleReassignClick);
    const generatedColumns = getGeneratedColumns(handleIconClick);
    const completedColumns = getCompletedColumns(handleIconClick);

    return (
        <div>
            <Toaster position="top-center" reverseOrder={false}/>
            <div className="card mb-4">
                <div className="card-body">
                    <Form>
                        <div className="row">
                            <div className="col-md-3">
                                <Form.Group>
                                    <Form.Label>Year</Form.Label>
                                    <Form.Control
                                        as="select"
                                        value={year}
                                        onChange={(e) => setYear(e.target.value)}
                                    >
                                        {[...Array(2).keys()].map(i => {
                                            const yearOption = new Date().getFullYear() + i;
                                            return <option key={yearOption} value={yearOption}>{yearOption}</option>
                                        })}
                                    </Form.Control>
                                </Form.Group>
                            </div>
                            <div className="col-md-3">
                                <Form.Group>
                                    <Form.Label>Location</Form.Label>
                                    <Select
                                        options={locations.map(location => ({
                                            value: location.id,
                                            label: location.name
                                        }))}
                                        value={selectedLocation}
                                        onChange={setSelectedLocation}
                                        placeholder="Select Location"
                                        styles={{
                                            menuPortal: base => ({...base, zIndex: 9999}),
                                            control: base => ({...base, minHeight: '38px'})
                                        }}
                                        menuPortalTarget={document.body}
                                    />
                                </Form.Group>
                            </div>
                            <div className="col-md-3">
                                <Form.Group>
                                    <Form.Label>Department</Form.Label>
                                    <Select
                                        options={departments.map(department => ({
                                            value: department.id,
                                            label: department.name
                                        }))}
                                        value={selectedDepartment}
                                        onChange={setSelectedDepartment}
                                        placeholder="Select Department"
                                        isDisabled={!selectedLocation}
                                        styles={{
                                            menuPortal: base => ({...base, zIndex: 9999}),
                                            control: base => ({...base, minHeight: '38px'})
                                        }}
                                        menuPortalTarget={document.body}
                                    />
                                </Form.Group>
                            </div>
                        </div>
                        <div className="row mt-2">
                            <div className="col-md-3">
                                <Form.Group>
                                    <Form.Label>Sub Dept</Form.Label>
                                    <Select
                                        options={subDepartments.map(subDept => ({
                                            value: subDept.id,
                                            label: subDept.name
                                        }))}
                                        value={selectedSubDepartment}
                                        onChange={setSelectedSubDepartment}
                                        placeholder="Select Sub Dept"
                                        isDisabled={!selectedDepartment}
                                        styles={{
                                            menuPortal: base => ({...base, zIndex: 9999}),
                                            control: base => ({...base, minHeight: '38px'})
                                        }}
                                        menuPortalTarget={document.body}
                                    />
                                </Form.Group>
                            </div>
                            <div className="col-md-3">
                                <Form.Group>
                                    <Form.Label>SR Type</Form.Label>
                                    <Select
                                        options={ticketTypes.map(ticketType => ({
                                            value: ticketType.id,
                                            label: ticketType.name
                                        }))}
                                        value={selectedTicketType}
                                        onChange={setSelectedTicketType}
                                        placeholder="Select SR Type"
                                        isDisabled={!selectedSubDepartment}
                                        styles={{
                                            menuPortal: base => ({...base, zIndex: 9999}),
                                            control: base => ({...base, minHeight: '38px'})
                                        }}
                                        menuPortalTarget={document.body}
                                    />
                                </Form.Group>
                            </div>
                            <div className="col-md-3">
                                <Form.Group>
                                    <Form.Label>User</Form.Label>
                                    <Select
                                        options={users.map(user => ({value: user.id, label: user.name}))}
                                        value={selectedUser}
                                        onChange={setSelectedUser}
                                        placeholder="Select User"
                                        isClearable
                                        styles={{
                                            menuPortal: base => ({...base, zIndex: 9999}),
                                            control: base => ({...base, minHeight: '38px'})
                                        }}
                                        menuPortalTarget={document.body}
                                    />
                                </Form.Group>
                            </div>
                        </div>
                        <div className="row mt-3 d-flex justify-content-center">
                            <div className="col-sm-1">
                                <Button variant="primary" onClick={handleSearch}>
                                    <i className="ri-search-2-line"></i>Search
                                </Button>
                            </div>
                            <div className="col-md-2">
                                <Button variant="secondary" onClick={handleResetFilters}>
                                    <i className="ri-refresh-line"></i>Reset Filters
                                </Button>
                            </div>
                        </div>
                    </Form>
                </div>
            </div>

            <Tabs
                id="request-tabs"
                activeKey={activeTab}
                onSelect={(k) => {
                    setActiveTab(k);
                    setPendingTickets([]);
                    setGeneratedTickets([]);
                    setCompletedTickets([]);
                    if (k === 'pending') {
                        setPendingCurrentPage(1);
                    } else if (k === 'generated') {
                        setGeneratedCurrentPage(1);
                    } else if (k === 'completed') {
                        setCompletedCurrentPage(1);
                    }
                }}
            >


            <Tab eventKey="pending" title="Pending Request">
                    <div className="card">
                        <div className="card-body">
                            <InputGroup className="mb-3" style={{maxWidth: '300px', marginBottom: '20px'}}>
                                <FormControl
                                    className="custom-height"
                                    placeholder="Search pending..."
                                    aria-label="Search"
                                    value={pendingFilterText}
                                    onChange={handlePendingSearchChange}
                                />
                            </InputGroup>
                            {loading ? (
                                <LinkedInSkeleton />

                            ) : pendingTickets.length === 0 ? (
                                <div style={{
                                    backgroundColor: '#eaf6ea',
                                    borderLeft: '3px solid #5cb85c',
                                    padding: '10px 15px',
                                    color: 'rgb(22, 160, 133)',
                                    fontWeight: 'bold',
                                    textAlign: 'center',
                                    borderRadius: '0',
                                }}>
                                    No Record Found!
                                </div>
                            ) : (
                                <DataTable
                                    columns={pendingColumns}
                                    data={pendingTickets}
                                    pagination
                                    paginationServer
                                    paginationTotalRows={pendingTotalRows}
                                    onChangePage={handlePageChange}
                                    paginationDefaultPage={pendingCurrentPage}
                                    paginationPerPage={pendingRowsPerPage}
                                    paginationRowsPerPageOptions={[10, 25, 50, 100, 200]}
                                    onChangeRowsPerPage={handleRowsPerPageChange}
                                    highlightOnHover
                                    responsive
                                    striped
                                    customStyles={customStyles}
                                    noHeader
                                />



                            )}

                        </div>
                    </div>
                </Tab>
                <Tab eventKey="generated" title="Task Generated">
                    <div className="card">
                        <div className="card-body">
                            <InputGroup className="mb-3" style={{maxWidth: '300px', marginBottom: '20px'}}>
                                <FormControl
                                    className="custom-height"
                                    placeholder="Search generated..."
                                    aria-label="Search"
                                    value={generatedFilterText}
                                    onChange={handleGeneratedSearchChange}
                                />
                            </InputGroup>
                            {loading ? (
                                <LinkedInSkeleton />

                            ) : generatedTickets.length === 0 ? (
                                <div style={{
                                    backgroundColor: '#eaf6ea',
                                    borderLeft: '3px solid #5cb85c',
                                    padding: '10px 15px',
                                    color: 'rgb(22, 160, 133)',
                                    fontWeight: 'bold',
                                    textAlign: 'center',
                                    borderRadius: '0',
                                }}>
                                    No Record Found!
                                </div>
                            ) : (
                                <DataTable
                                    columns={generatedColumns}
                                    data={generatedTickets}
                                    pagination
                                    paginationServer
                                    paginationTotalRows={generatedTotalRows}
                                    onChangePage={handlePageChange}
                                    paginationDefaultPage={generatedCurrentPage}
                                    paginationPerPage={generatedRowsPerPage}
                                    paginationRowsPerPageOptions={[10, 25, 50, 100, 200]}
                                    onChangeRowsPerPage={handleRowsPerPageChange}
                                    highlightOnHover
                                    responsive
                                    striped
                                    customStyles={customStyles}
                                    noHeader
                                />

                            )}

                        </div>
                    </div>
                </Tab>
                <Tab eventKey="completed" title="Task Completed">
                    <div className="card">
                        <div className="card-body">
                            <InputGroup className="mb-3" style={{maxWidth: '300px', marginBottom: '20px'}}>
                                <FormControl
                                    className="custom-height"
                                    placeholder="Search completed..."
                                    aria-label="Search"
                                    value={completedFilterText}
                                    onChange={handleCompletedSearchChange}
                                />
                            </InputGroup>
                            {loading ? (
                                <LinkedInSkeleton />
                            ) : completedTickets.length === 0 ? (
                                <div style={{
                                    backgroundColor: '#eaf6ea',
                                    borderLeft: '3px solid #5cb85c',
                                    padding: '10px 15px',
                                    color: 'rgb(22, 160, 133)',
                                    fontWeight: 'bold',
                                    textAlign: 'center',
                                    borderRadius: '0',
                                }}>
                                    No Record Found!
                                </div>
                            ) : (
                                <DataTable
                                    columns={completedColumns}
                                    data={completedTickets}
                                    pagination
                                    paginationServer
                                    paginationTotalRows={completedTotalRows}
                                    onChangePage={handlePageChange}
                                    paginationDefaultPage={completedCurrentPage}
                                    paginationPerPage={completedRowsPerPage}
                                    paginationRowsPerPageOptions={[10, 25, 50, 100, 200]}
                                    onChangeRowsPerPage={handleRowsPerPageChange}
                                    highlightOnHover
                                    responsive
                                    striped
                                    customStyles={customStyles}
                                    noHeader
                                />

                            )}
                        </div>
                    </div>
                </Tab>
            </Tabs>

            <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)} centered>
                <Modal.Header>
                    <Modal.Title>Confirm Deletion</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>Are you sure you want to cancel this service request? This action cannot be undone.</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowDeleteModal(false)}>Cancel</Button>
                    <Button variant="danger" onClick={confirmDelete}>Cancel SR</Button>
                </Modal.Footer>
            </Modal>


            <AssignmentFormModal
                ref={assignmentFormRef}
                show={showModal}
                handleClose={handleCloseModal}
                ticket={selectedTicket}
                avatar={avatarUrl}
                onTaskUpdated={handleTaskUpdated}
            />
            <ReassignmentModal
                show={showReassignModal}
                handleClose={() => setShowReassignModal(false)}
                ticket={selectedReassignTicket}
                onTaskUpdated={fetchTickets}
            />
        </div>
    );
};

export default ServiceRequestList;